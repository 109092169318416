import React from 'react';

import epsilonLogo from '../assets/vendor_logos/epsilon.png';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorEpsilon: Vendor = {
  logo: epsilonLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://www.epsilon.com/',
  infoPagesEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms, trigger a welcome message, and sync contacts to Epsilon.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Epsilon so that you can start sending marketing emails immediately. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A Epsilon account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description: 'Trigger subscriber welcome email from Epsilon.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/4413707182100-Epsilon',
      label: 'Epsilon integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_EPSILON}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'username',
            type: IntegrationFieldType.TEXT,
            label: 'Username',
            required: true,
          }),
          IntegrationField.Config({
            name: 'password',
            type: IntegrationFieldType.PASSWORD,
            label: 'Password',
            required: true,
          }),
          IntegrationField.Config({
            name: 'xOuid',
            type: IntegrationFieldType.TEXT,
            label: 'X-OUID',
            subtext: 'The Business Unit (BU) ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'messageId',
            type: IntegrationFieldType.TEXT,
            label: 'Message ID',
            subtext: 'The Message ID you wish to send',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_EPSILON}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'username',
            type: IntegrationFieldType.TEXT,
            label: 'Username',
            required: true,
          }),
          IntegrationField.Config({
            name: 'password',
            type: IntegrationFieldType.PASSWORD,
            label: 'Password',
            required: true,
          }),
          IntegrationField.Config({
            name: 'xOuid',
            type: IntegrationFieldType.TEXT,
            label: 'X-OUID',
            subtext: 'The Business Unit (BU) ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'messageId',
            type: IntegrationFieldType.TEXT,
            label: 'Message ID',
            subtext: 'The Message ID you wish to send',
            required: true,
          }),
        ]}
        features={[]}
      />
    );
  },
};
