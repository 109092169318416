import React from 'react';

import { FacebookAdAccount } from '@attentive/data/types';
import { Banner, Link, Text } from '@attentive/picnic';

const TermsOfServiceError = (adAccount: FacebookAdAccount) => {
  const strippedAdAccountId = adAccount.id.substring('act_'.length);
  return (
    <Banner variant="warning" css={{ marginBottom: '$space4' }}>
      <Text>
        The Ad Account selected ({`${adAccount.name} - ${adAccount.id}`}) requires Facebook's Terms
        of Service to be accepted in order to sync Segments to Audiences.{' '}
        <Link
          target="_blank"
          href={`https://business.facebook.com/ads/manage/customaudiences/tos.php?act=${strippedAdAccountId}`}
        >
          Please accept the Terms of Service
        </Link>
        .
      </Text>
    </Banner>
  );
};

export default TermsOfServiceError;
