import React from 'react';

import { LoadingIndicator, Stack, StandardDialog, Text } from '@attentive/picnic';

import { IntegrationGenerateAllTokensResponse } from '../../types';

import { GenerateTokenValue } from './GenerateTokenValue';

type GenerateTokenDialogProps = {
  showDialog: boolean;
  handleClose: () => void;
  heading: string;
  tokens: IntegrationGenerateAllTokensResponse;
};

export const GenerateTokenDialog: React.FC<GenerateTokenDialogProps> = ({
  showDialog,
  handleClose,
  heading,
  tokens,
}) => {
  const tokenKeys = Object.keys(tokens);

  return (
    <StandardDialog open={showDialog} onOpenChange={handleClose}>
      <StandardDialog.Content>
        <StandardDialog.Header>
          <StandardDialog.Heading>{heading}</StandardDialog.Heading>
        </StandardDialog.Header>
        <StandardDialog.Body>
          <Text variant="body" css={{ mb: '$space4' }}>
            This is your attentive token. Copy and store it in a safe place.
          </Text>
          <Stack
            direction="vertical"
            css={{
              border: '$borderWidths$borderWidth1 solid $borderDefault',
              borderRadius: '$radius2',
              justifyContent: 'space-between',
              padding: '$space4',
            }}
          >
            {tokenKeys.length ? (
              tokenKeys.map((tokenKey) => (
                <GenerateTokenValue
                  token={tokens[tokenKey].end_value}
                  subtitle={tokenKey}
                  key={tokenKey}
                />
              ))
            ) : (
              <LoadingIndicator />
            )}
          </Stack>
        </StandardDialog.Body>
        <StandardDialog.Footer>
          <StandardDialog.Close variant="secondary" onClick={handleClose}>
            Close
          </StandardDialog.Close>
        </StandardDialog.Footer>
      </StandardDialog.Content>
    </StandardDialog>
  );
};
