import React from 'react';

import { FormField as PicnicFormField, styled } from '@attentive/picnic';

export type IntegrationFeatureGroupProps = {
  label: React.ReactNode;
  children: React.ReactNode;
  direction?: 'horizontal' | 'vertical';
};

const IntegrationFeatureGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridGap: '$space4',
});

/**
 * Used for grouping childless IntegrationFeature checkboxes
 */
export const IntegrationFeatureGroup = ({
  label,
  children,
  direction = 'horizontal',
}: IntegrationFeatureGroupProps) => {
  return (
    <PicnicFormField css={{ width: '100%', marginBottom: '$space2' }}>
      <PicnicFormField.Label>{label}</PicnicFormField.Label>
      <IntegrationFeatureGrid
        css={direction === 'vertical' ? { gridTemplateColumns: '1fr' } : undefined}
      >
        {children}
      </IntegrationFeatureGrid>
    </PicnicFormField>
  );
};
