import { useField } from 'formik';
import React, { useState, useEffect } from 'react';

import { API, ToastType, useToast } from '@attentive/acore-utils';
import { useMutation, UseMutationResult } from '@attentive/data/react-query';
import { Button, FormField, StandardDialog, TextInput } from '@attentive/picnic';

import { getErrorMessage } from '../../../../../api';
import { AD_ACCOUNT_ID_FIELD } from '../../constants';

import { useSegmentAudienceOptions } from './SegmentAudienceOptionsProvider';

type CreateAudienceParams = {
  adAccountId: string;
  audienceName: string;
};

type ResponseType = {
  id: string;
  name: string;
} | null;

const createCustomAudience = async ({ adAccountId, audienceName }: CreateAudienceParams) => {
  const response = await API.post<ResponseType>('/integrations/facebook/customaudiences', {
    ad_account_id: adAccountId,
    audience_to_create: { name: audienceName },
  });

  if (response.status !== 200) {
    throw new Error(getErrorMessage(response));
  }

  return response.body;
};

const useCreateCustomAudience: () => UseMutationResult<
  ResponseType,
  Error,
  CreateAudienceParams
> = () => useMutation(createCustomAudience);

type CreateCustomAudienceDialogProps = {
  setDialogOpen: (isOpen: boolean) => void;
};

const CreateCustomAudienceDialog = ({ setDialogOpen }: CreateCustomAudienceDialogProps) => {
  const { setCreatedAudiences } = useSegmentAudienceOptions();
  const [{ value: adAccountId }] = useField(AD_ACCOUNT_ID_FIELD);
  const [audienceName, setAudienceName] = useState('');
  const [audienceNameError, setAudienceNameError] = useState<string | null>(null);
  const [createToast] = useToast();
  const { mutateAsync, isError, isSuccess, data, isLoading, error } = useCreateCustomAudience();

  const onFormSubmit = () => {
    if (!audienceName) {
      setAudienceNameError('An audience name is required.');
      return;
    }

    if (isLoading) return;

    mutateAsync({ adAccountId, audienceName });
  };

  useEffect(() => {
    if (isSuccess && data) {
      createToast({
        type: ToastType.Success,
        title: 'Custom audience created',
        text: 'Your custom audience is created and will be pushed to Facebook',
      });

      setCreatedAudiences((audiences) => [
        {
          name: data.name || '',
          id: data.id || '',
        },
        ...audiences,
      ]);

      setAudienceName('');
      setDialogOpen(false);
    } else if (isError) {
      setAudienceNameError(
        error ? error.message : 'An error occurred when creating your custom audience.'
      );
    }
  }, [data, isSuccess, setCreatedAudiences, isError, error, createToast, setDialogOpen]);

  return (
    <StandardDialog.Content css={{ width: '100%', maxWidth: '580px' }}>
      <StandardDialog.Header>
        <StandardDialog.Heading>Create a custom audience</StandardDialog.Heading>
      </StandardDialog.Header>
      <StandardDialog.Body>
        <FormField>
          <FormField.Label requirement="required">Custom audience name</FormField.Label>
          <FormField.HelperText>
            Give your custom audience a unique name for easy identification.
          </FormField.HelperText>
          <TextInput
            value={audienceName}
            state={audienceNameError ? 'error' : 'normal'}
            onChange={(ev) => setAudienceName(ev.currentTarget.value)}
            placeholder="Enter custom audience name"
          />
          {audienceNameError && <FormField.ErrorText>{audienceNameError}</FormField.ErrorText>}
        </FormField>
      </StandardDialog.Body>
      <StandardDialog.Footer>
        <StandardDialog.Close variant="secondary">Cancel</StandardDialog.Close>
        <Button onClick={onFormSubmit} disabled={!audienceName.length} loading={isLoading}>
          Create
        </Button>
      </StandardDialog.Footer>
    </StandardDialog.Content>
  );
};

export default CreateCustomAudienceDialog;
