/**
 * @generated SignedSource<<4d10b2f5fff73044f5c345ffabe03e57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StandardErrorStatus = "STANDARD_ERROR_STATUS_ABORTED" | "STANDARD_ERROR_STATUS_ALREADY_EXISTS" | "STANDARD_ERROR_STATUS_CANCELLED" | "STANDARD_ERROR_STATUS_DATA_LOSS" | "STANDARD_ERROR_STATUS_DEADLINE_EXCEEDED" | "STANDARD_ERROR_STATUS_FAILED_PRECONDITION" | "STANDARD_ERROR_STATUS_INTERNAL" | "STANDARD_ERROR_STATUS_INVALID_ARGUMENT" | "STANDARD_ERROR_STATUS_NOT_FOUND" | "STANDARD_ERROR_STATUS_NOT_SET" | "STANDARD_ERROR_STATUS_OUT_OF_RANGE" | "STANDARD_ERROR_STATUS_PERMISSION_DENIED" | "STANDARD_ERROR_STATUS_RESOURCE_EXHAUSTED" | "STANDARD_ERROR_STATUS_UNAUTHENTICATED" | "STANDARD_ERROR_STATUS_UNAVAILABLE" | "STANDARD_ERROR_STATUS_UNIMPLEMENTED" | "STANDARD_ERROR_STATUS_UNKNOWN" | "%future added value";
export type VerifyServerSideTagDnsRecordsInput = {
  companyId: string;
};
export type vendorBlotoutWhitelabelVerifyMutation$variables = {
  input: VerifyServerSideTagDnsRecordsInput;
};
export type vendorBlotoutWhitelabelVerifyMutation$data = {
  readonly verifyServerSideTagDnsRecords: {
    readonly __typename: "DefaultErrorFailure";
    readonly message: string;
    readonly status: StandardErrorStatus;
    readonly title: string;
    readonly traceId: string;
  } | {
    readonly __typename: "VerifyServerSideTagDnsRecordsSuccess";
    readonly dnsRecords: ReadonlyArray<{
      readonly __typename: "ServerSideTagDnsRecord";
      readonly name: string;
      readonly type: string;
      readonly value: string;
    }>;
    readonly valid: boolean;
  } | {
    // This will never be '%other', but we need some
    // value in case none of the concrete values match.
    readonly __typename: "%other";
  } | null;
};
export type vendorBlotoutWhitelabelVerifyMutation = {
  response: vendorBlotoutWhitelabelVerifyMutation$data;
  variables: vendorBlotoutWhitelabelVerifyMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": null,
    "kind": "LinkedField",
    "name": "verifyServerSideTagDnsRecords",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ServerSideTagDnsRecord",
            "kind": "LinkedField",
            "name": "dnsRecords",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "valid",
            "storageKey": null
          }
        ],
        "type": "VerifyServerSideTagDnsRecordsSuccess",
        "abstractKey": null
      },
      {
        "kind": "InlineFragment",
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "traceId",
            "storageKey": null
          }
        ],
        "type": "DefaultErrorFailure",
        "abstractKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "vendorBlotoutWhitelabelVerifyMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "vendorBlotoutWhitelabelVerifyMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "53a1e11e587da5ec4ff23d0c6e921205",
    "id": null,
    "metadata": {},
    "name": "vendorBlotoutWhitelabelVerifyMutation",
    "operationKind": "mutation",
    "text": "mutation vendorBlotoutWhitelabelVerifyMutation(\n  $input: VerifyServerSideTagDnsRecordsInput!\n) {\n  verifyServerSideTagDnsRecords(input: $input) {\n    __typename\n    ... on VerifyServerSideTagDnsRecordsSuccess {\n      dnsRecords {\n        __typename\n        name\n        type\n        value\n      }\n      valid\n    }\n    ... on DefaultErrorFailure {\n      message\n      status\n      title\n      traceId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "232e6d09f4a87df8a92b3328d6f8d398";

export default node;
