import React from 'react';

import placeholderLogo from '../assets/vendor_logos/placeholder.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

export const vendorPasskit: Vendor = {
  category: VendorCategory.OTHER,
  logo: placeholderLogo,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_PASSKIT}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.TEXT,
            label: 'API KEY',
            required: true,
          }),
          IntegrationField.Config({
            name: 'apiSecret',
            type: IntegrationFieldType.TEXT,
            label: 'Api Secret',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_PASSKIT}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.TEXT,
            label: 'API KEY',
            required: true,
          }),
          IntegrationField.Config({
            name: 'apiSecret',
            type: IntegrationFieldType.TEXT,
            label: 'Api Secret',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'FEATURE_UNKNOWN',
            label: 'Enable Feature',
            subtext: '',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
        ]}
      />
    );
  },
};
