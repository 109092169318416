import React from 'react';

import whatcountsLogo from '../assets/vendor_logos/whatcounts.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const whatcounts: Vendor = {
  logo: whatcountsLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  infoPagesEnabled: true,
  companyWebsite: 'https://www.whatcounts.com/',
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contacts to WhatCounts.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to WhatCounts so that you can start sending marketing emails immediately. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A WhatCounts account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        "Automatically sync subscriber's emails with WhatCounts so that you can start sending marketing emails immediately.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360041853471',
      label: 'WhatCounts integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.WHATCOUNTS}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'accountName',
            type: IntegrationFieldType.TEXT,
            label: 'Account Name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API password',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listId',
            type: IntegrationFieldType.TEXT,
            label: 'List Id',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.WHATCOUNTS}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'accountName',
            type: IntegrationFieldType.TEXT,
            label: 'Account Name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API password',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listId',
            type: IntegrationFieldType.TEXT,
            label: 'List Id',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to WhatCounts.',
          }),
        ]}
      />
    );
  },
};
