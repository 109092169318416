export const MAX_IMG_GIF_FILE_SIZE = 512000; // 500KB

export function validateFileUpload(files: FileList | null, maxSize: number): FormData {
  if (files && files.length > 0) {
    const file = files[0];
    if (file.size > maxSize) {
      throw new Error('File is too large');
    }
    const form = new FormData();
    form.append('file', file, file.name);
    return form;
  }
  throw new Error('Could not upload brand image');
}
