import pick from 'lodash/pick';
import React from 'react';
import { useLazyLoadQuery } from 'react-relay';

import { useCurrentCompanyId } from '@attentive/acore-utils';
import { get } from '@attentive/nodash';
import { Banner, Box, Link, Text } from '@attentive/picnic';

import { IntegrationVisibilityType, useUpdateIntegrationSettingsMutation } from '../../../..';
import { useIntegrationContext } from '../../../../hooks/useIntegrationContext';
import { IntegrationFeature } from '../../../IntegrationFeature';
import { IntegrationForm } from '../../../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../../../SetupConnectAndConfigure/SubmitButton';
import {
  SEGMENT_FEATURE_TYPE,
  SEGMENT_MAPPING_KEY,
  SEGMENT_MAPPING_FIELD,
  AD_ACCOUNT_ID_KEY,
  AD_ACCOUNT_NAME_KEY,
  AD_ACCOUNT_ID_FIELD,
} from '../constants';

import ConnectedAdAccountBanner from './ConnectedAdAccountBanner';
import MapSegmentToFBAudience from './MapSegmentToFBAudience';

import FacebookAdsFeatureSettingsFormQuery, {
  FacebookAdsFeatureSettingsFormQuery as FacebookAdsFeatureSettingsFormQueryType,
} from './__generated__/FacebookAdsFeatureSettingsFormQuery.graphql';

interface Props {
  onComplete: () => void;
  superUserEnabled: boolean;
}

export const FacebookAdsFeatureSettingsForm = ({ onComplete }: Props) => {
  const companyId = useCurrentCompanyId();
  const { integrationDetailValues = {} } = useIntegrationContext();
  const queryData = useLazyLoadQuery<FacebookAdsFeatureSettingsFormQueryType>(
    FacebookAdsFeatureSettingsFormQuery,
    {
      companyId,
      adAccountId: get(integrationDetailValues, AD_ACCOUNT_ID_FIELD, '') as string,
    }
  );
  const fragmentRef =
    queryData?.company?.companyVendorIntegrations &&
    queryData.company.companyVendorIntegrations[0].externalVendorData?.data;
  const hasSegments = !!queryData.company.segments?.edges?.length;

  const validationConfig = {
    features: {
      [SEGMENT_FEATURE_TYPE]: {
        [SEGMENT_MAPPING_KEY]: { required: true },
        [AD_ACCOUNT_ID_KEY]: { required: true },
        [AD_ACCOUNT_NAME_KEY]: { required: true },
      },
    },
  };

  const initialValues = pick(integrationDetailValues, [SEGMENT_FEATURE_TYPE]);

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useUpdateIntegrationSettingsMutation}
      onComplete={onComplete}
    >
      <Box css={{ paddingTop: '$space2', width: '100%' }}>
        <ConnectedAdAccountBanner />
      </Box>
      <IntegrationFeature
        type={SEGMENT_FEATURE_TYPE}
        label="Map Attentive segments to Facebook custom audiences"
        subtext="Attentive will map your segments to any Facebook custom audience you choose. You can also create a new custom audience and utilize it here."
        visibility={IntegrationVisibilityType.SUPER_USER}
        disabled={!hasSegments}
      >
        {fragmentRef && hasSegments ? (
          <MapSegmentToFBAudience fieldKey={SEGMENT_MAPPING_FIELD} queryRef={fragmentRef} />
        ) : null}
        {!hasSegments && (
          <Banner variant="warning" css={{ marginBottom: '$space4' }}>
            <Text>
              You must create a segment within Attentive to map the data to a Facebook custom
              audience. Create a segment <Link href="/segments">here.</Link>
            </Text>
          </Banner>
        )}
      </IntegrationFeature>
      <SubmitButton text="Save changes" />
    </IntegrationForm>
  );
};
