import React from 'react';

import placeholderLogo from '../assets/vendor_logos/placeholder.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

export const vendorSendinblue: Vendor = {
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  logo: placeholderLogo,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_SENDINBLUE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API KEY',
            required: true,
          }),
          IntegrationField.Config({
            name: 'customAttributes',
            type: IntegrationFieldType.TEXT,
            label: 'Custom Attributes',
            required: false,
          }),
          IntegrationField.Config({
            name: 'listids',
            type: IntegrationFieldType.TEXT,
            label: 'List Ids',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_SENDINBLUE}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API KEY',
            required: true,
          }),
          IntegrationField.Config({
            name: 'customAttributes',
            type: IntegrationFieldType.TEXT,
            label: 'Custom Attributes',
            required: false,
          }),
          IntegrationField.Config({
            name: 'listids',
            type: IntegrationFieldType.TEXT,
            label: 'List Ids',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward emails to SENDINBLUE',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward phone',
            subtext: 'Forward phone numbers to SENDINBLUE',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
        ]}
      />
    );
  },
};
