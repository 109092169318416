import React, { FC } from 'react';

import { Box, PicnicCss } from '@attentive/picnic';

export const TwoColumnForm: FC<{ css?: PicnicCss; left?: JSX.Element; right?: JSX.Element }> = ({
  css,
  left,
  right,
}) => (
  <Box
    css={{
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      width: '100%',
      '@bp4': {
        alignItems: 'start',
        flexDirection: 'row',
      },
      ...css,
    }}
  >
    <Box
      css={{ flex: 1, ...(left && { mb: '$space8', pr: '$space0', '@bp4': { pr: '$space2' } }) }}
    >
      {left}
    </Box>
    <Box
      css={{ flex: 1, ...(right && { mb: '$space8', pl: '$space0', '@bp4': { pl: '$space2' } }) }}
    >
      {right}
    </Box>
  </Box>
);
