import React, { FC } from 'react';

import { LoadingIndicator, PicnicCss, styled } from '@attentive/picnic';

const BrandImageContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
});

const BrandImageStyled = styled('img', {
  flex: 1,
  display: 'block',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
});

interface Props {
  src: string | null;
  altText: string;
  css?: PicnicCss;
}

export const Logo: FC<Props> = ({ src, altText, css }) => {
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (src === null) {
      setLoading(true);
      return;
    }

    setLoading(true);
    const img = new Image();
    img.src = src;
    img.addEventListener('load', () => {
      setLoading(false);
    });

    if (img.complete) {
      setLoading(false);
    }
  }, [src]);

  return (
    <BrandImageContainer css={css}>
      {loading && <LoadingIndicator />}
      {!loading && src && <BrandImageStyled alt={altText} src={src} />}
    </BrandImageContainer>
  );
};
