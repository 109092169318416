import React, { FC } from 'react';

import { MilestoneKey } from '@attentive/data/types';
import { Heading, Stack, Text, Icon } from '@attentive/picnic';

import {
  IntegrationContextProvider,
  IntegrationContextBag,
} from '../../hooks/useIntegrationContext';
import { VendorKey } from '../../types';
import { InstallationCard } from '../InstallationCard';
import { ErrorBanner } from '../SetupConnectAndConfigure/ErrorBanner';
import { LoadingScreen } from '../SetupConnectAndConfigure/LoadingScreen';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';
import { SuccessStep } from '../SetupConnectAndConfigure/SuccessStep';
import { SetupLogo } from '../SetupLogo';
import { TimeEstimation } from '../TimeEstimation';

import { ConfigureStep } from './ConfigureStep';
import { ConnectForm } from './ConnectForm';
import { SetupSuperuserFeatures } from './SetupSuperuserFeatures';
import { ShopifyTerms } from './ShopifyTerms';

const shopifyMilestones = [
  MilestoneKey.MilestoneKeyInstalledTag,
  MilestoneKey.MilestoneKeyEcommercePlatformCompleted,
];

export const SetupShopify: FC<{ superUserEnabled?: boolean }> = ({ superUserEnabled }) => {
  return (
    <IntegrationContextProvider vendorKey={VendorKey.SHOPIFY}>
      {({
        vendorConfig,
        vendorDetails,
        isFetching,
        isFetchingError,
        isConnectStep,
        isConfigureStep,
        isSuccessStep,
      }: IntegrationContextBag) => {
        return (
          <InstallationCard>
            <InstallationCard.Header
              leftSlot={
                <Stack spacing="$space2" direction="horizontal" css={{ alignItems: 'center' }}>
                  <SetupLogo src={vendorConfig.logo} />
                  <Heading variant="sm">Connect {vendorDetails?.displayName}</Heading>
                </Stack>
              }
              rightSlot={
                isSuccessStep ? (
                  <Stack
                    direction="horizontal"
                    spacing="$space1"
                    css={{ alignItems: 'center', color: '$green700' }}
                  >
                    <Icon name="CircleCheckmark" />
                    <Text variant="caption">Completed</Text>
                  </Stack>
                ) : (
                  <TimeEstimation>2 mins</TimeEstimation>
                )
              }
            />
            <InstallationCard.Body>
              {isFetching && <LoadingScreen />}
              {isFetchingError && <ErrorBanner text="Unable to load setup module." />}
              {isConnectStep && (
                <ConnectForm>
                  {superUserEnabled && <SetupSuperuserFeatures />}
                  <ShopifyTerms />
                  <SubmitButton text="Set up integration" />
                </ConnectForm>
              )}
              {isConfigureStep && <ConfigureStep />}
              {isSuccessStep && <SuccessStep milestonesCompleted={shopifyMilestones} />}
            </InstallationCard.Body>
          </InstallationCard>
        );
      }}
    </IntegrationContextProvider>
  );
};
