import React, { FC } from 'react';

import { Box, Heading, LoadingIndicator } from '@attentive/picnic';

interface Props {
  text?: string;
}

export const LoadingScreen: FC<Props> = ({ text }) => (
  <Box
    css={{
      height: '100%',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      my: '$space16',
    }}
  >
    {text && (
      <Heading variant="sm" css={{ mb: '$space4' }}>
        {text}
      </Heading>
    )}
    <LoadingIndicator />
  </Box>
);
