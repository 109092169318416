import React from 'react';

import bazaarvoiceLogo from '../assets/vendor_logos/bazaarvoice.png';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  DistributionStatus,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

const fields = [
  IntegrationField.Config({
    name: 'environment',
    type: IntegrationFieldType.SELECT,
    label: 'Environment',
    defaultValue: 'api.bazaarvoice.com',
    options: [
      { name: 'api.bazaarvoice.com', value: 'api.bazaarvoice.com' },
      { name: 'stg.api.bazaarvoice.com', value: 'stg.api.bazaarvoice.com' },
    ],
    required: true,
  }),
  IntegrationField.Config({
    name: 'apiKey',
    type: IntegrationFieldType.PASSWORD,
    label: 'API Key',
    required: true,
  }),
  IntegrationField.Config({
    name: 'secretKey',
    type: IntegrationFieldType.PASSWORD,
    label: 'Email Shared Secret Key',
    required: true,
  }),
  IntegrationField.Config({
    name: 'encodingKey',
    type: IntegrationFieldType.PASSWORD,
    label: 'Shared Encoding Key',
    required: true,
  }),
  IntegrationField.Config({
    name: 'workbenchName',
    type: IntegrationFieldType.TEXT,
    label: 'Workbench Name',
    required: true,
  }),
  IntegrationField.Config({
    name: 'deploymentZone',
    type: IntegrationFieldType.TEXT,
    label: 'Deployment Zone',
    defaultValue: 'main_site',
    required: true,
  }),
];

export const vendorBazaarvoice: Vendor = {
  //TODO: help desk url, texts, and double check company website. Double check associated features
  logo: bazaarvoiceLogo,
  category: VendorCategory.REVIEWS_UGC,
  distributionStatus: DistributionStatus.PUBLIC,
  companyWebsite: 'https://www.bazaarvoice.com/',
  salesEmail: 'support@bazaarvoice.com',
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext:
    'Bazaarvoice gives you total control to collect, display, and distribute UGC at a global scale.',
  jointValueProp:
    'Merchants can leverage both Bazaarvoice and Attentive to intelligently deliver personalized review requests directly to customers via SMS to generate more product feedback and user generated content throughout the customer journey.',
  whatYoullNeedItems: [
    'Bazaarvoice API Key with approval to retrieve emails',
    'Bazaarvoice Email Shared Secret key',
    'Bazaarvoice workbench name',
    'Bazaarvoice Shared Encoding Key',
  ],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Build a journey based on when customers are eligible to receive review requests and/or when customers have submitted a review.',
    },
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description:
        'Curate segments of subscribers by using Bazaarvoice collected zero-party review data and events to create highly targeted campaigns such as product recommendations, customer referrals, coupon reminders, and more.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/20677704040468-Bazaarvoice',
      label: 'Bazaarvoice integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_BAZAARVOICE}
        onComplete={onComplete}
        fields={fields}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_BAZAARVOICE}
        onComplete={onComplete}
        fields={fields}
        features={[]}
      />
    );
  },
};
