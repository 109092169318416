import React from 'react';

import segmentAnalyticsLogo from '../assets/vendor_logos/segmentanalyticslogo.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

export const segmentAnalytics: Vendor = {
  logo: segmentAnalyticsLogo,
  category: VendorCategory.CUSTOMER_DATA_PLATFORM,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.SEGMENT_ANALYTICS}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'emailKey',
            type: IntegrationFieldType.TEXT,
            label: 'Email Property Name',
            required: false,
          }),
          IntegrationField.Config({
            name: 'phoneKey',
            type: IntegrationFieldType.TEXT,
            label: 'Phone Property Name',
            required: false,
          }),
          IntegrationField.Config({
            name: 'writeKey',
            type: IntegrationFieldType.TEXT,
            label: 'Write Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'customEvent',
            type: IntegrationFieldType.TEXT,
            label: 'Custom Event',
            required: false,
          }),
          IntegrationField.Config({
            name: 'customSource',
            type: IntegrationFieldType.TEXT,
            label: 'Custom Source',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.SEGMENT_ANALYTICS}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'emailKey',
            type: IntegrationFieldType.TEXT,
            label: 'Email Property Name',
            required: false,
          }),
          IntegrationField.Config({
            name: 'phoneKey',
            type: IntegrationFieldType.TEXT,
            label: 'Phone Property Name',
            required: false,
          }),
          IntegrationField.Config({
            name: 'writeKey',
            type: IntegrationFieldType.TEXT,
            label: 'Write Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'customEvent',
            type: IntegrationFieldType.TEXT,
            label: 'Custom Event',
            required: false,
          }),
          IntegrationField.Config({
            name: 'customSource',
            type: IntegrationFieldType.TEXT,
            label: 'Custom Source',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Customer Data Forwarding',
            subtext: 'Forward event data collected by Attentive to Segment Analytics.',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
        ]}
      />
    );
  },
};
