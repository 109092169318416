import isFunction from 'lodash/isFunction';
import React, { ReactNode, createContext, useContext } from 'react';

import { useToast, ToastType } from '@attentive/acore-utils';
import { QueryObserverResult } from '@attentive/data/react-query';

import {
  useIntegrationQuery,
  useVendorsQuery,
  useDisconnectIntegrationMutation,
  NO_INTEGRATION_RESULTS,
} from '../api';
import { vendors } from '../constants/vendors';
import {
  Integration,
  IntegrationDetailsResponse,
  IntegrationStatusType,
  IntegrationFormValues,
  Vendor,
  VendorKey,
} from '../types';
import { isCXIntegration } from '../utils';

export interface IntegrationContextBag {
  vendorKey: VendorKey;
  vendorConfig: Vendor;
  vendorDetails: Integration | undefined;
  integrationDetails: IntegrationDetailsResponse | undefined;
  integrationDetailValues: IntegrationFormValues | undefined;
  isFetching: boolean;
  isFetchingError: boolean;
  isConnectStep: boolean;
  isConfigureStep: boolean;
  isSuccessStep: boolean;
  refetchIntegrationDetails: () => Promise<
    QueryObserverResult<IntegrationDetailsResponse | typeof NO_INTEGRATION_RESULTS, unknown>
  >;
  disconnectIntegration: () => void;
}

const IntegrationContext = createContext<IntegrationContextBag>({} as IntegrationContextBag);

export const useIntegrationContext = () => useContext(IntegrationContext);

export const IntegrationContextProvider: React.VFC<{
  vendorKey: VendorKey;
  children: ((props: IntegrationContextBag) => ReactNode) | ReactNode;
}> = ({ vendorKey, children }) => {
  const vendorConfig = vendors[vendorKey];
  const {
    isFetching: isFetchingVendors,
    isError: isErrorVendors,
    data: integrationVendors,
  } = useVendorsQuery();

  const {
    isFetching: isFetchingIntegration,
    isError: isErrorIntegration,
    data: integrationDetails,
    refetch: refetchIntegrationDetails,
  } = useIntegrationQuery(vendorKey);
  const { mutate: disconnectMutate, isLoading: isLoadingDisconnect } =
    useDisconnectIntegrationMutation();

  const isFetching = isFetchingVendors || isFetchingIntegration || isLoadingDisconnect;
  const isFetchingError = isErrorVendors || isErrorIntegration;

  const isLoaded = !isFetching && !isFetchingError;
  const isConfigureStep =
    isLoaded && integrationDetails?.status === IntegrationStatusType.CONNECTED;
  const isSuccessStep = isLoaded && integrationDetails?.status === IntegrationStatusType.ENABLED;
  const isConnectStep =
    isLoaded &&
    (!integrationDetails || integrationDetails?.status === IntegrationStatusType.DISABLED);

  const vendorDetails = integrationVendors?.externalVendorApis?.find(
    (iv) => iv.vendor === vendorKey
  );

  const [createToast] = useToast();
  const disconnectIntegration = () => {
    disconnectMutate(
      {
        vendor: vendorKey,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        shouldCheckJourneys: isCXIntegration(integrationDetails!),
      },
      {
        onSuccess: () => {
          refetchIntegrationDetails();
        },
        onError: (error) => {
          createToast({ type: ToastType.Error, title: 'Error', text: (error as Error).message });
        },
      }
    );
  };

  const integrationDetailsFields = integrationDetails?.configuredParameters
    ? Object.entries(integrationDetails.configuredParameters).map(([name, value]) => [
        name,
        value || '',
      ])
    : [];

  const integrationDetailsFeatureFields = integrationDetails?.features
    ? integrationDetails.features.map((feature) => [
        feature.externalVendorFeatureType,
        {
          enabled: feature.enabled,
          ...Object.fromEntries(
            Object.entries(feature.configuredParameters).map(([name, value]) => [name, value || ''])
          ),
        },
      ])
    : [];

  const integrationDetailValues = integrationDetails
    ? Object.fromEntries([...integrationDetailsFields, ...integrationDetailsFeatureFields])
    : undefined;

  const ctxBag: IntegrationContextBag = {
    vendorKey,
    vendorConfig,
    vendorDetails,
    integrationDetails,
    integrationDetailValues,
    isFetching,
    isFetchingError,
    isConnectStep,
    isConfigureStep,
    isSuccessStep,
    refetchIntegrationDetails,
    disconnectIntegration,
  };

  return (
    <IntegrationContext.Provider value={ctxBag}>
      {isFunction(children) ? children(ctxBag) : children}
    </IntegrationContext.Provider>
  );
};
