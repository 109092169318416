import React, { FC, ReactElement } from 'react';

import { NO_INTEGRATION_RESULTS } from '../../api';
import {
  IntegrationContextProvider,
  IntegrationContextBag,
} from '../../hooks/useIntegrationContext';
import { IntegrationDetailsResponse, VendorKey } from '../../types';
import { IntegrationFeatureConfig } from '../IntegrationFeature';
import { IntegrationFieldConfig } from '../IntegrationField';
import { ErrorBanner } from '../SetupConnectAndConfigure/ErrorBanner';
import { LoadingScreen } from '../SetupConnectAndConfigure/LoadingScreen';

import { StandardSettingsForm } from './StandardSettingsForm';

interface FormParams {
  onFormComplete: () => void;
}

interface Props {
  vendorKey: VendorKey;
  form?: (params: FormParams) => ReactElement;
  fields?: IntegrationFieldConfig[];
  features?: IntegrationFeatureConfig[];
  onComplete?: (integrationDetails: IntegrationDetailsResponse) => void;
  superUserEnabled?: boolean;
}

export const SettingsStandard: FC<Props> = ({
  vendorKey,
  form,
  fields,
  features,
  onComplete,
  superUserEnabled,
}) => {
  if (!!form && !!fields) {
    throw new Error(
      `👋 Attentive Engineer: You cannot provide both a form function
      as well a fields array / features array. Either provide a form
      function that returns a custom settings form component or provide
      an array of IntegrationField.Config objects and optionally an
      array of IntegrationFeature.Config objects to render a standard
      settings form.`.replace(/\s+/g, ' ')
    );
  }

  return (
    <IntegrationContextProvider vendorKey={vendorKey}>
      {({ isFetching, isFetchingError, refetchIntegrationDetails }: IntegrationContextBag) => {
        const onFormComplete = async () => {
          const { data } = await refetchIntegrationDetails();
          if (onComplete) {
            const onCompleteData = data === NO_INTEGRATION_RESULTS ? undefined : data;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onComplete(onCompleteData!);
          }
        };

        return (
          <>
            {isFetching && <LoadingScreen />}
            {isFetchingError && <ErrorBanner text="Unable to load settings." />}
            {!isFetching &&
              !isFetchingError &&
              (form ? (
                form({ onFormComplete })
              ) : (
                <StandardSettingsForm
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  fields={fields!}
                  features={features}
                  onComplete={onFormComplete}
                  superUserEnabled={superUserEnabled}
                />
              ))}
          </>
        );
      }}
    </IntegrationContextProvider>
  );
};
