import pick from 'lodash/pick';
import React, { FC } from 'react';

import { useUpdateIntegrationSettingsMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFormFeatureValues, IntegrationFieldType } from '../../types';
import { IntegrationField } from '../IntegrationField';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

import { ChatterCreateSurveysFeature } from './ChatterCreateSurveysFeature';

interface Props {
  onComplete: () => void;
}

export const ChatterSettingsForm: FC<Props> = ({ onComplete }) => {
  const { integrationDetailValues } = useIntegrationContext();

  const validationConfig = {
    fields: {
      apiKey: { required: true },
    },
    features: {
      FEATURE_SURVEY_MESSAGE: {},
    },
  };

  // pick out only the fields/features that this form actually shows from
  // the current state of what's in the backend. This ensures we only submit
  // the values that the form actually shows inputs for.
  const initialValues = pick(integrationDetailValues || {}, [
    'apiKey',
    'FEATURE_SURVEY_MESSAGE',
  ]) as { [type: string]: IntegrationFormFeatureValues };

  initialValues.FEATURE_SURVEY_MESSAGE = pick(initialValues.FEATURE_SURVEY_MESSAGE, [
    'enabled',
    'surveyList',
  ]);

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useUpdateIntegrationSettingsMutation}
      onComplete={onComplete}
    >
      <IntegrationField
        name="apiKey"
        type={IntegrationFieldType.PASSWORD}
        label="API Key"
        placeholder="Enter API Key"
        required={true}
        disabled={true}
        css={{ width: '100%' }}
      />

      <ChatterCreateSurveysFeature />

      <SubmitButton text="Save changes" />
    </IntegrationForm>
  );
};
