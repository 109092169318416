import React from 'react';

import sailthruLogo from '../assets/vendor_logos/sailthru.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  CreativeSpecificDataKeyNames,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const sailthru: Vendor = {
  logo: sailthruLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://www.sailthru.com/',
  infoPagesEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contacts to Sailthru.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Sailthru so that you can start sending marketing emails immediately. You can also sync subscriber coupon codes to Sailthru. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A Sailthru account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        "Automatically sync subscriber's emails with Sailthru so that you can start sending marketing emails immediately.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360041853511',
      label: 'Sailthru integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.SAILTHRU}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'secret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listName',
            type: IntegrationFieldType.TEXT,
            label: 'List Name',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.SAILTHRU}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'secret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listName',
            type: IntegrationFieldType.TEXT,
            label: 'List Name',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Sailthru.',
          }),
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward phone numbers',
            subtext:
              'Forward any phone numbers collected by Attentive to Sailthru. Please ensure the {profile.keys.sms} field has been enabled in Sailthru before turning on the phone number postback.',
          }),
        ]}
      />
    );
  },
  creativeSpecificSources: { type: 'verbose', key: CreativeSpecificDataKeyNames.SOURCES },
};
