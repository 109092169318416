import React from 'react';

import rechargeLogo from '../assets/vendor_logos/recharge.png';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorRecharge: Vendor = {
  logo: rechargeLogo,
  category: VendorCategory.ECOMMERCE,
  companyWebsite: 'https://rechargepayments.com/',
  salesEmail: 'support@rechargepayments.com',
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext:
    'Send updates about subscription orders and invite customers to reply to buy with SMS.',
  jointValueProp:
    'With the Attentive and ReCharge integration, Shopify clients can install a transactional opt-in checkbox on your ReCharge checkout page and set up transactional journeys to send messages (such as subscription confirmed, upcoming charge, charge failed, and subscription skipped) to their subscribers.',
  whatYoullNeedItems: ['A Recharge account.', 'A Shopify account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Set up transactional journeys to send messages (such as subscription confirmed, upcoming charge, charge failed, and subscription skipped)',
    },
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        'Install a transactional opt-in checkbox on your ReCharge checkout page, which can help grow your transactional subscriber list.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360056612472',
      label: 'Recharge integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_RECHARGE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiToken',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_RECHARGE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiToken',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
        ]}
        features={[]}
      />
    );
  },
};
