import React from 'react';

import {
  FormField as PicnicFormField,
  FormFieldProps as PicnicFormFieldProps,
} from '@attentive/picnic';

interface FormFieldProps extends PicnicFormFieldProps {
  required: boolean;
  hideOptionalLabel?: boolean;
  name?: string;
  label: string;
  subtext?: string;
  error?: string;
  children: React.ReactNode;
}

export const FormField: React.FC<FormFieldProps> = ({
  required,
  name,
  label,
  subtext,
  error,
  children,
  hideOptionalLabel,
  ...rest
}) => {
  const optionalFlag = hideOptionalLabel ? 'none' : 'optional';

  return (
    <PicnicFormField {...rest}>
      <PicnicFormField.Label requirement={required ? 'required' : optionalFlag} htmlFor={name}>
        {label}
      </PicnicFormField.Label>
      {subtext && <PicnicFormField.HelperText>{subtext}</PicnicFormField.HelperText>}
      {error && <PicnicFormField.ErrorText>{error}</PicnicFormField.ErrorText>}
      {children}
    </PicnicFormField>
  );
};
