import React from 'react';

import oloLogo from '../assets/vendor_logos/olo.png';
import { IntegrationField } from '../components/IntegrationField';
import { OloInstructions } from '../components/OloInstructions';
import { SettingsStandard } from '../components/SettingsStandard';
import { OloSettingsForm } from '../components/SettingsStandard/OloSettingsForm';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorOlo: Vendor = {
  logo: oloLogo,
  category: VendorCategory.ECOMMERCE,
  infoPagesEnabled: true,
  learnSubtext: 'Send personalized SMS messages using Olo data.',
  jointValueProp:
    'Our Olo integration enables you to trigger order status SMS messages as well as send more personalized and targeted messages to your customers.  Customers who make purchases are automatically sent to Attentive as SMS transactional subscribers so that they can immediately start receiving transactional messages. Pre-built journeys allow you to trigger order status SMS messages such as “Order confirmed”, “Order out for delivery” and “Order delivered”. Send highly targeted messages by segmenting your customers using order data such as product name, category, or order value. Track revenue attributable to your SMS marketing campaigns in the Analytics Dashboard tab.',
  whatYoullNeedItems: ['An Olo account.'],
  companyWebsite: 'https://www.olo.com/',
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Use pre-built journeys to trigger transaction messages such as “Order confirmed”, “Order out for delivery” and “Order delivered”. Send automated marketing messages such as post-purchase and cart abandonment messages.',
    },
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description:
        'Segment customers based on purchasing and browsing activity using product data such as name, category, tag, price, and other attributes.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/4407734343060',
      label: 'Olo integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_OLO}
        onComplete={onComplete}
        instructions={<OloInstructions alreadyConfigured={false} />}
        fields={[
          IntegrationField.Config({
            name: 'sharedSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Shared Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.TEXT,
            label: 'Client ID',
            required: false,
          }),
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Secret',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_OLO}
        onComplete={onComplete}
        form={({ onFormComplete }) => <OloSettingsForm onComplete={onFormComplete} />}
      />
    );
  },
};
