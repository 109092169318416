import React, { useState } from 'react';

import { StandardDialog, Text, Link } from '@attentive/picnic';

const KLAVIYO_ID_DIALOG_ID = 'attn__klv-id-dialog';

export const setShowKlaviyoDialogId = () => {
  if (typeof localStorage !== 'undefined') localStorage.setItem(KLAVIYO_ID_DIALOG_ID, 'show');
};

const shouldKlaviyoDialogIdShow = () =>
  typeof localStorage !== 'undefined' ? Boolean(localStorage.getItem(KLAVIYO_ID_DIALOG_ID)) : false;

export const KlaviyoIdDialog = () => {
  const [showDialog, setShowDialog] = useState(shouldKlaviyoDialogIdShow());
  const onOpenChange = (isOpen: boolean) => {
    if (!isOpen && typeof localStorage !== 'undefined') {
      localStorage.removeItem(KLAVIYO_ID_DIALOG_ID);
    }
    setShowDialog(isOpen);
  };

  return (
    <StandardDialog open={showDialog} onOpenChange={onOpenChange}>
      <StandardDialog.Content css={{ maxWidth: '460px' }}>
        <StandardDialog.HeroImage
          src="https://attentive-files-multi-region.s3.amazonaws.com/d4c42da2.png"
          alt="Identify 30% more subscribers on-site"
        />
        <StandardDialog.Header>
          <StandardDialog.Heading>Add Klaviyo ID to your Email Tracking</StandardDialog.Heading>
        </StandardDialog.Header>
        <StandardDialog.Body>
          <Text>
            Attentive will automatically bridge the email site visitor to their SMS profile on the
            Klaviyo ID value
          </Text>
        </StandardDialog.Body>
        <StandardDialog.Footer>
          <Link
            href="https://help.attentivemobile.com/hc/en-us/articles/8527478318100"
            target="_blank"
            css={{ textDecoration: 'none' }}
          >
            <StandardDialog.Close css={{ width: '100%' }}>
              2-Minute Setup Guide
            </StandardDialog.Close>
          </Link>
        </StandardDialog.Footer>
      </StandardDialog.Content>
    </StandardDialog>
  );
};
