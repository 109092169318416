import { styled } from '@attentive/picnic';

export const SetupLogo = styled('img', {
  width: '$size8',
  height: '$size8',
  'object-fit': 'contain',
  borderRadius: '$radius1',
  border: '$borderWidths$borderWidth1 solid $grayscale200',
  background: '$grayscale0',
  alt: 'Vendor Logo',
});
