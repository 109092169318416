import React, { FC, ReactNode } from 'react';

import { useMutation } from '@attentive/data/react-query';
import { Box, Button } from '@attentive/picnic';

import { IntegrationConnectionRequest } from '../../types';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';

const nothing = async ({
  vendor,
  payload,
}: {
  vendor: string;
  payload: IntegrationConnectionRequest;
}) => {
  const _vendor = vendor;
  const _payload = payload;
};
const DoNothing = () => useMutation(nothing);

interface Props {
  instructions?: ReactNode;
}

export const MagentoRedirectForm: FC<Props> = ({ instructions }) => {
  function handleClick() {
    window.location.replace(`https://marketplace.magento.com/attentivemobile-magento2.html`);
  }

  return (
    <IntegrationForm
      loadingText="Redirecting"
      initialValues={{}}
      validationConfig={{}}
      mutation={DoNothing}
      onComplete={() => {}}
    >
      {instructions && <Box>{instructions}</Box>}
      <Button onClick={handleClick}>Go to Extension Marketplace</Button>
    </IntegrationForm>
  );
};
