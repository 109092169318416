import pick from 'lodash/pick';
import React, { FC } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';

import { useUpdateIntegrationSettingsMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFormFeatureValues, IntegrationFieldType, VendorKey } from '../../types';
import { IntegrationField } from '../IntegrationField';
import { ListField } from '../Klaviyo';
import { KlaviyoIdDialog } from '../Klaviyo/KlaviyoIdDialog';
import { SyncEmailListsFeature } from '../Klaviyo/SyncEmailListsFeature';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

import { CreateSegmentsFeature } from './CreateSegmentsFeature';
import { CreateUserAttributesFeature } from './CreateUserAttributesFeature';

interface Props {
  onComplete: () => void;
  superUserEnabled?: boolean;
}

export const KlaviyoSettingsForm: FC<Props> = ({ onComplete, superUserEnabled }) => {
  const { integrationDetailValues } = useIntegrationContext();
  const enableTriggeredEmail = useCompanyFeatureFlag('ENABLE_TRIGGERED_EMAIL');

  const validationConfig = {
    fields: {
      apiKey: { required: true },
      listId: { required: true },
    },
  };

  // pick out only the fields/features that this form actually shows from
  // the current state of what's in the backend. This ensures we only submit
  // the values that the form actually shows inputs for.
  const initialValues = pick(integrationDetailValues || {}, [
    'apiKey',
    'listId',
    'FEATURE_EMAIL_OPT_OUT_SYNC',
    'FEATURE_POSTBACK_USER_ATTRIBUTES',
    'SYNC_THIRD_PARTY_LISTS',
    'FEATURE_SYNC_LIST_IDENTIFIERS',
    'POSTBACK_EMAILS',
  ]) as { [type: string]: IntegrationFormFeatureValues };

  // the SYNC_THIRD_PARTY_LISTS feature is strange in that by default it has
  // a name, url and description fields that aren't real because the data is really
  // transmitted via the lists field which is serialized JSON, so only pick out
  // the lists field this feature.
  initialValues.SYNC_THIRD_PARTY_LISTS = pick(initialValues.SYNC_THIRD_PARTY_LISTS, [
    'enabled',
    'lists',
  ]);

  return (
    <>
      <IntegrationForm
        loadingText="Saving"
        initialValues={initialValues}
        validationConfig={validationConfig}
        mutation={useUpdateIntegrationSettingsMutation}
        onComplete={onComplete}
      >
        <IntegrationField
          name="apiKey"
          type={IntegrationFieldType.PASSWORD}
          label="API Key"
          placeholder="Enter API Key"
          required={true}
          disabled={true}
          css={{ width: '100%' }}
        />
        <ListField label="Select the Klaviyo list where you want to push new subscribers to" />
        {superUserEnabled && enableTriggeredEmail && <SyncEmailListsFeature />}
        <CreateUserAttributesFeature vendor={VendorKey.KLAVIYO} />
        <CreateSegmentsFeature />
        <SubmitButton text="Save changes" />
      </IntegrationForm>
      <KlaviyoIdDialog />
    </>
  );
};
