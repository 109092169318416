import React, { useContext, useMemo, useState } from 'react';
import { useFragment } from 'react-relay';

import useSegmentsList from '../../../../../hooks/useSegmentsList';

import { FormMappingsById } from './MapSegmentToFBAudience';

import MapSegmentToFBAudienceFragment, {
  MapSegmentToFBAudienceFragment_facebookAdsExternalVendorData$key,
  MapSegmentToFBAudienceFragment_facebookAdsExternalVendorData$data,
} from './__generated__/MapSegmentToFBAudienceFragment_facebookAdsExternalVendorData.graphql';

const getOptionById = (id: string, list: SelectOption[]): SelectOption | undefined =>
  list.find((option) => option.id === id);

type SelectOption = {
  id: string;
  name: string;
  isSelected: boolean;
};

type CustomAudience =
  MapSegmentToFBAudienceFragment_facebookAdsExternalVendorData$data['customAudiences'][number];

type SegmentAudienceOptionsContextValue = {
  segmentOptions: SelectOption[];
  audienceOptions: SelectOption[];
  getOptionById: typeof getOptionById;
  setCreatedAudiences: React.Dispatch<React.SetStateAction<CustomAudience[]>>;
};

const defaultProviderValue: SegmentAudienceOptionsContextValue = {
  segmentOptions: [],
  audienceOptions: [],
  getOptionById,
  setCreatedAudiences: () => {},
};

const SegmentAudienceOptionsContext =
  React.createContext<SegmentAudienceOptionsContextValue>(defaultProviderValue);

export const useSegmentAudienceOptions = (): SegmentAudienceOptionsContextValue =>
  useContext(SegmentAudienceOptionsContext);

const sortAudienceByName = (a: CustomAudience, b: CustomAudience) => {
  const aName = a.name.toLowerCase();
  const bName = b.name.toLowerCase();

  if (aName < bName) {
    return -1;
  }
  if (aName > bName) {
    return 1;
  }
  return 0;
};

type SegmentAudienceOptionsProviderProps = {
  children: React.ReactNode;
  formMap: FormMappingsById;
  queryRef: MapSegmentToFBAudienceFragment_facebookAdsExternalVendorData$key;
};

const SegmentAudienceOptionsProvider = ({
  children,
  formMap,
  queryRef,
}: SegmentAudienceOptionsProviderProps) => {
  const { customAudiences } =
    useFragment<MapSegmentToFBAudienceFragment_facebookAdsExternalVendorData$key>(
      MapSegmentToFBAudienceFragment,
      queryRef
    );

  const segmentsList = useSegmentsList();
  const [createdAudiences, setCreatedAudiences] = useState<CustomAudience[]>([]);

  const segmentOptions: SelectOption[] = useMemo(() => {
    const selectedSegmentIds = Object.values(formMap)
      .map((val) => val.attentive_segment?.id)
      .filter(Boolean);

    return segmentsList.map(({ node }) => ({
      id: node.internalId.toString(),
      name: node.name,
      isSelected: selectedSegmentIds.includes(node.internalId.toString()),
    }));
  }, [segmentsList, formMap]);

  const audienceOptions: SelectOption[] = useMemo(() => {
    const selectedAudienceIds = Object.values(formMap)
      .map((val) => val.facebook_audience?.id)
      .filter(Boolean);

    const sortedCustomAudiences = [...customAudiences].sort(sortAudienceByName);

    return [...createdAudiences, ...sortedCustomAudiences].map((audience) => ({
      id: audience.id,
      name: audience.name,
      isSelected: selectedAudienceIds.includes(audience.id),
    }));
  }, [customAudiences, formMap, createdAudiences]);

  return (
    <SegmentAudienceOptionsContext.Provider
      value={{
        segmentOptions,
        audienceOptions,
        getOptionById,
        setCreatedAudiences,
      }}
    >
      {children}
    </SegmentAudienceOptionsContext.Provider>
  );
};

export default SegmentAudienceOptionsProvider;
