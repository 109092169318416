import React, { FC } from 'react';

import { Config, API, useCompanyFeatureFlag } from '@attentive/acore-utils';
import { useMutation } from '@attentive/data/react-query';

import { getErrorMessage } from '../../api/utils';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationSettingsRequest, Feature } from '../../types';
import { IntegrationFeature } from '../IntegrationFeature';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

class SalesforceAPI extends API {
  public static getAPIUrl() {
    return Config.get('salesForceApiUrl');
  }
}

export const ConnectForm: FC = () => {
  const { vendorDetails } = useIntegrationContext();
  const isSFMCEnhancementsEnabled = useCompanyFeatureFlag('ENABLE_SFMC_POSTBACK_ENHANCEMENTS');

  const featuresByType =
    vendorDetails?.features.reduce((obj, feature) => {
      obj[feature.type] = feature;
      return obj;
    }, {} as { [type: string]: Feature }) ?? {};

  const fetchOAuthLinkMutation = useMutation(
    async ({ payload }: { vendor: string; payload: IntegrationSettingsRequest }) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const featureData = Object.entries(payload.features!).map(([type, feature]) => ({
        externalVendorFeatureId: featuresByType[type].externalVendorFeatureId,
        enabled: feature.enabled,
        configuredParameters: {},
      }));

      const querystring = `featureData=${encodeURIComponent(JSON.stringify(featureData))}`;
      // GMRU: GET /auth-link
      const response = await SalesforceAPI.get<{ link: string }>(`/auth-link?${querystring}`);

      if (response.status >= 300) {
        throw new Error(getErrorMessage(response));
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      window.location.assign(response.body!.link!);
    }
  );

  const initialValues = {
    POSTBACK_EMAILS: { enabled: true },
    POST_WITH_PHONE: { enabled: false },
    FEATURE_POSTBACK_USER_DATA: { enabled: false },
    FEATURE_POSTBACK_USER_ATTRIBUTES: { enabled: false },
  };

  return (
    <IntegrationForm
      loadingText="Connecting"
      initialValues={initialValues}
      mutation={() => fetchOAuthLinkMutation}
      onComplete={() => {}}
    >
      <IntegrationFeature
        type="POSTBACK_EMAILS"
        label="Email Forwarding"
        subtext="Forward any emails collected by Attentive to Salesforce."
      />

      <IntegrationFeature
        type="POST_WITH_PHONE"
        label="Phone Forwarding"
        subtext="Forward phone numbers to Salesforce"
      />

      <IntegrationFeature
        type="FEATURE_POSTBACK_USER_DATA"
        label="Message Link Click Forwarding"
        subtext="Forward message link click events to Salesforce"
      />
      {isSFMCEnhancementsEnabled && (
        <IntegrationFeature
          type="FEATURE_POSTBACK_USER_ATTRIBUTES"
          label="User Attribute Forwarding"
          subtext="Forward user attributes to Salesforce"
        />
      )}

      <SubmitButton text="Connect" />
    </IntegrationForm>
  );
};
