import React from 'react';

import { Text, Link } from '@attentive/picnic';

export const ShopifyTerms: React.FC = () => {
  return (
    <Text variant="body" color="subdued" css={{ pt: '$space4' }}>
      Enabling this integration authorizes bidirectional data sharing between Attentive and Shopify,
      including subscriber data (phone numbers and opt-in information). Visit the{' '}
      <Link
        href="https://help.shopify.com/en/manual/promoting-marketing/create-marketing/customer-contact-information"
        target="_blank"
        rel="noopener noreferrer"
      >
        Shopify Help Center
      </Link>{' '}
      and these{' '}
      <Link
        href="https://docs.attentive.com/pages/shopify-subscriber-collection/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Attentive Terms
      </Link>{' '}
      for more details.
    </Text>
  );
};
