import React from 'react';

import placeholderLogo from '../assets/vendor_logos/placeholder.png';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

export const sprouts: Vendor = {
  category: VendorCategory.OTHER,
  logo: placeholderLogo,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.SPROUTS}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apikey',
            type: IntegrationFieldType.TEXT,
            label: 'API key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'environment',
            type: IntegrationFieldType.TEXT,
            label: 'Environment (dev=dev,prod leave blank)',
            required: false,
          }),
          IntegrationField.Config({
            name: 'keywords',
            type: IntegrationFieldType.TEXT,
            label: 'Keywords (Comma Separated)',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.SPROUTS}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apikey',
            type: IntegrationFieldType.TEXT,
            label: 'API key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'environment',
            type: IntegrationFieldType.TEXT,
            label: 'Environment (dev=dev,prod leave blank)',
            required: false,
          }),
          IntegrationField.Config({
            name: 'keywords',
            type: IntegrationFieldType.TEXT,
            label: 'Keywords (Comma Separated)',
            required: true,
          }),
        ]}
        features={[]}
      />
    );
  },
};
