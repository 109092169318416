import React from 'react';

import { API } from '@attentive/acore-utils';
import { useQuery } from '@attentive/data/react-query';
import { Text, Link } from '@attentive/picnic';

import { GoogleTagManagerContainer } from '../../types';

const GTM_BASE_ENDPOINT = '/integrations/gtm';

type GtmParameter = {
  type: string;
  key: string;
  value: string;
  list: GtmParameter[];
  map: GtmParameter[];
};

type GetTagResponse = {
  provenance: 'auto' | 'existing' | undefined;
  version?: GtmContainerVersion;
  tag?: GtmTag;
};

type GtmContainerVersion = {
  path: string;
  accountId: string;
  containerId: string;
  containerVersionId: string;
  name: string;
  deleted: boolean;
  description: string;
  container: GoogleTagManagerContainer;
  tag: GtmTag[];
  trigger: unknown[];
  variable: unknown[];
  folder: unknown[];
  builtInVariable: unknown[];
  fingerprint: string;
  tagManagerUrl: string;
  zone: unknown[];
  customeTemplate: unknown[];
  client: unknown[];
};

type GtmTag = {
  path: string;
  accountId: string;
  containerId: string;
  workspaceId: string;
  tagId: string;
  name: string;
  type: string;
  firingRuleId: string[];
  blockingRuleId: string[];
  liveOnly: boolean;
  priority: GtmParameter;
  notes: string;
  scheduleStartMs: number;
  scheduleEndMs: number;
  parameter: GtmParameter[];
  fingerprint: string;
  firingTriggerId: string[];
  blockingTriggerId: string[];
  setupTag: Array<{
    tagName: string;
    stopOnSetupFailure: boolean;
  }>;
  teardownTag: [
    {
      tagName: string;
      stopTeardownOnFailure: boolean;
    }
  ];
  parentFolderId: string;
  tagFiringOption: string;
  tagManagerUrl: string;
  paused: boolean;
  monitoringMetadata: GtmParameter;
  monitoringMetadataTagNameKey: string;
  consentSettings: {
    consentStatus: string;
    consentType: GtmParameter[];
  };
};

async function apiGet<T>(path: string) {
  try {
    const response = await API.get<T>(path);
    if (response.status >= 200 && response.status < 300) {
      return response.body ? response.body : undefined;
    }
  } catch (err) {
    console.error(err.message);
  }
}

async function fetchTag() {
  // GMRU: GET /integrations/gtm/tag
  return await apiGet<GetTagResponse>(`${GTM_BASE_ENDPOINT}/tag`);
}

export const GoogleTagManagerSettingsForm: React.FC = () => {
  const { data: tagResponse } = useQuery(['tag'], fetchTag);

  if (tagResponse?.provenance === 'auto') {
    return (
      <Text>
        We already installed the Attentive Tag in your Google Tag Manager account! You don't need to
        do anything else.
      </Text>
    );
  }
  return (
    <Text>
      We detected the Attentive Tag (named{' '}
      <Link href={tagResponse?.tag?.tagManagerUrl || tagResponse?.version?.tagManagerUrl}>
        {tagResponse?.tag?.name}
      </Link>
      ) in your Google Tag Manager account. You may have already installed it, so there's nothing
      more to do.
    </Text>
  );
};
