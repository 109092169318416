import React from 'react';

import placeholderLogo from '../assets/vendor_logos/placeholder.png';
import { IntegrationField, SettingsStandard } from '../components';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { IntegrationFieldType, Vendor, VendorCategory, VendorKey } from '../types';

export const vendorTalonOne: Vendor = {
  logo: placeholderLogo,
  category: VendorCategory.OTHER,

  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_TALON_ONE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'email',
            type: IntegrationFieldType.TEXT,
            label: 'Email',
            required: true,
          }),
          IntegrationField.Config({
            name: 'password',
            type: IntegrationFieldType.PASSWORD,
            label: 'Password',
            required: true,
          }),
        ]}
      />
    );
  },

  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_TALON_ONE}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'email',
            type: IntegrationFieldType.TEXT,
            label: 'Email',
            required: true,
          }),
          IntegrationField.Config({
            name: 'password',
            type: IntegrationFieldType.PASSWORD,
            label: 'Password',
            required: true,
          }),
        ]}
        features={[]}
      />
    );
  },
};
