import { API } from '@attentive/acore-utils';

import {
  IntegrationDetailsResponse,
  IntegrationFormErrorResponse,
  IntegrationConnectionRequest,
  IntegrationConfigurationRequest,
  IntegrationSettingsRequest,
  IntegrationVendorsResponse,
  VendorKey,
} from '../types';

import { getErrorMessage } from './utils';

type JourneysAutoResponse = Array<{
  journeyId: string;
  name: string;
  journeyHasCxnode: boolean;
}>;

export class IntegrationFormError extends Error {
  baseError: IntegrationFormErrorResponse['baseError'];
  fieldErrors: IntegrationFormErrorResponse['fieldErrors'];
  featureFieldErrors: IntegrationFormErrorResponse['featureFieldErrors'];

  constructor({ baseError, fieldErrors, featureFieldErrors }: IntegrationFormErrorResponse) {
    super('');
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = 'IntegrationFormError';
    this.baseError = baseError;
    this.fieldErrors = fieldErrors;
    this.featureFieldErrors = featureFieldErrors;
  }
}

export const NO_VENDOR_RESULTS = Symbol('FETCH_VENDOR_RESULTS');
export const fetchVendors = async () => {
  // GMRU: GET /integrations
  const response = await API.get<IntegrationVendorsResponse>(`/integrations`);
  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }

  return response.body || NO_VENDOR_RESULTS;
};

export const NO_INTEGRATION_RESULTS = Symbol('NO_INTEGRATION_RESULTS');
export const fetchIntegration = async (vendor: VendorKey) => {
  // GMRU: GET /integrations/vendors/{vendor}/integration
  const response = await API.get<IntegrationDetailsResponse>(
    `/integrations/vendors/${vendor}/integration`
  );
  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }

  return response.body || NO_INTEGRATION_RESULTS;
};

export const fetchOAuthLink = async ({
  vendorOAuthKey,
  oAuthState,
  fields,
}: {
  vendorOAuthKey: string;
  oAuthState?: string;
  fields?: { [name: string]: string | undefined };
}) => {
  const oAuthStateParam = oAuthState ? { state: oAuthState } : undefined;
  const params =
    oAuthStateParam || fields ? `?${new URLSearchParams({ ...oAuthStateParam, ...fields })}` : '';
  const path = `/integrations/oauth/${vendorOAuthKey}/auth-link${params}`;
  // GMRU: GET /integrations/oauth/{vendorOAuthKey}/auth-link
  const response = await API.get<{ link: string }>(path);

  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return response.body!.link;
};

export const connectIntegration = async ({
  vendor,
  payload,
}: {
  vendor: string;
  payload: IntegrationConnectionRequest;
}) => {
  // GMRU: POST /integrations/vendors/{vendor}/connections
  const response = await API.post<IntegrationDetailsResponse | IntegrationFormErrorResponse>(
    `/integrations/vendors/${vendor}/connections`,
    payload
  );

  if (response.status === 422) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    throw new IntegrationFormError(response.body! as IntegrationFormErrorResponse);
  }

  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return response.body!;
};

export const configureIntegration = async ({
  vendor,
  payload,
}: {
  vendor: string;
  payload: IntegrationConfigurationRequest;
}) => {
  // GMRU: POST /integrations/vendors/{vendor}/configurations
  const response = await API.post<IntegrationDetailsResponse | IntegrationFormErrorResponse>(
    `/integrations/vendors/${vendor}/configurations`,
    payload
  );

  if (response.status === 422) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    throw new IntegrationFormError(response.body! as IntegrationFormErrorResponse);
  }

  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return response.body!;
};

export const updateIntegrationSettings = async ({
  vendor,
  payload,
}: {
  vendor: string;
  payload: IntegrationSettingsRequest;
}) => {
  // GMRU: PUT /integrations/vendors/{vendor}/settings
  const response = await API.put<IntegrationDetailsResponse | IntegrationFormErrorResponse>(
    `/integrations/vendors/${vendor}/settings`,
    payload
  );

  if (response.status === 422) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    throw new IntegrationFormError(response.body! as IntegrationFormErrorResponse);
  }

  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return response.body!;
};

export const disconnectIntegration = async ({
  vendor,
  shouldCheckJourneys,
}: {
  vendor: string;
  shouldCheckJourneys: boolean;
}) => {
  if (shouldCheckJourneys) {
    // First we will check the journeys-api if there are any existing CX
    // nodes. If there are, we will show an error. The backend is supposed
    // to validate this but its a lot of work to make that happen so this
    // is the temporary workaround. The reason why we don't want to do this
    // on the frontend is that it's possible that due to a bug or some
    // misunderstanding, the DELETE API may be inadverdantly called without
    // this check happening, and the CX integration would be deleted.
    // Remove this check once the backend returns an error response.
    // GMRU: GET /journeys/autoresponse
    const checkJourneyResponse = await API.fetch<JourneysAutoResponse>(
      `/journeys/autoresponse?isActive=true&isPublished=true`
    );

    if (checkJourneyResponse.status >= 300) {
      throw new Error(getErrorMessage(checkJourneyResponse));
    }

    if (checkJourneyResponse.body?.find((item) => item.journeyHasCxnode)) {
      throw new Error(
        `Couldn't disconnect the CX integration because you have a live Journey with a "Create CX Ticket" step. Pause that journey or remove the CX Ticket step.`
      );
    }
  }

  // GMRU: POST /integrations/vendors/{vendor}/disconnections
  const response = await API.post(`/integrations/vendors/${vendor}/disconnections`);

  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }
};
