import React from 'react';

import helpscoutLogo from '../assets/vendor_logos/helpscout.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupConnectAndConfigure } from '../components/SetupConnectAndConfigure';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorHelpscout: Vendor = {
  logo: helpscoutLogo,
  category: VendorCategory.CUSTOMER_EXPERIENCE,
  companyWebsite: 'https://www.helpscout.com/',
  infoPagesEnabled: true,
  learnSubtext: 'Deliver great customer service and offer support via text messaging.',
  jointValueProp:
    'Ensure your customers have a great experience by supporting customers via text messaging. Our integration with Help Scout, an all-in-one support platform, enables your customer service team to directly respond to text messages via text within the Help Scout platform.',
  whatYoullNeedItems: ['A Help Scout account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.CUSTOMER_SUPPORT,
      description:
        'All messages received from a customer through SMS are forwarded to your customer support team queue in Help Scout.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360050482391',
      label: 'Help Scout integration guide',
    },
  ],
  setupComponent(onComplete, superUserEnabled) {
    return (
      <SetupConnectAndConfigure
        vendorKey={VendorKey.VENDOR_HELPSCOUT}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        connectFields={[
          IntegrationField.Config({
            name: 'user',
            type: IntegrationFieldType.TEXT,
            label: 'User Email',
            required: true,
          }),
          IntegrationField.Config({
            name: 'token',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Code',
            required: true,
          }),
          IntegrationField.Config({
            name: 'applicationId',
            type: IntegrationFieldType.PASSWORD,
            label: 'Application Id',
            required: true,
          }),
          IntegrationField.Config({
            name: 'applicationSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Application Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'mailboxId',
            type: IntegrationFieldType.PASSWORD,
            label: 'Mailbox Id',
            required: true,
          }),
        ]}
        configureFeatures={[
          IntegrationFeature.Config({
            type: 'CUSTOMER_SERVICE_OPT_IN',
            label: 'Support Confirmation',
            visibility: IntegrationVisibilityType.SUPER_USER,
            subtext:
              'Turn on Support Confirmation to allow customers to escalate their text message conversation to your customer support team.',
            initiallyEnabled: true,
            fields: [
              IntegrationField.Config({
                name: 'serviceVerificationKeyword',
                type: IntegrationFieldType.TEXT,
                label: 'Support Confirmation Keyword',
                subtext:
                  'Choose a keyword your customers can use to confirm they need assistance. Your default autoresponder must include this keyword.',
                placeholder: 'Support',
                required: true,
              }),
              IntegrationField.Config({
                name: 'serviceBeginMessage',
                type: IntegrationFieldType.MULTILINE,
                label: 'Acknowledgement Message',
                subtext:
                  'Your customers will receive this message after replying with the Support Confirmation keyword.',
                placeholder:
                  'Your request has been forwarded to our support team. We will get back to you as soon as possible during our business hours.',
                required: true,
              }),
              IntegrationField.Config({
                name: 'serviceEndMessage',
                type: IntegrationFieldType.MULTILINE,
                label: 'Resolution Message',
                subtext:
                  'Your customers will receive this message once the support ticket has been resolved.',
                placeholder:
                  'Your request has been resolved. If you need anything else, do not hesitate to reach out.',
                required: false,
              }),
            ],
          }),
          IntegrationFeature.Config({
            type: 'FORWARD_MESSAGE_CUSTOMER_SERVICE',
            label: 'Forward Subscriber Messages through Customer Service',
            subtext: 'Turn on Customer Service Message Forwarding.',
            initiallyEnabled: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_HELPSCOUT}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'user',
            type: IntegrationFieldType.TEXT,
            label: 'User Email',
            required: true,
          }),
          IntegrationField.Config({
            name: 'token',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Code',
            required: true,
          }),
          IntegrationField.Config({
            name: 'applicationId',
            type: IntegrationFieldType.PASSWORD,
            label: 'Application Id',
            required: true,
          }),
          IntegrationField.Config({
            name: 'applicationSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Application Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'mailboxId',
            type: IntegrationFieldType.PASSWORD,
            label: 'Mailbox Id',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'CUSTOMER_SERVICE_OPT_IN',
            label: 'Support Confirmation',
            visibility: IntegrationVisibilityType.SUPER_USER,
            subtext:
              'Turn on Support Confirmation to allow customers to escalate their text message conversation to your customer support team.',
            fields: [
              IntegrationField.Config({
                name: 'serviceVerificationKeyword',
                type: IntegrationFieldType.TEXT,
                label: 'Support Confirmation Keyword',
                subtext:
                  'Choose a keyword your customers can use to confirm they need assistance. Your default autoresponder must include this keyword.',
                placeholder: 'Support',
                required: true,
              }),
              IntegrationField.Config({
                name: 'serviceBeginMessage',
                type: IntegrationFieldType.MULTILINE,
                label: 'Acknowledgement Message',
                subtext:
                  'Your customers will receive this message after replying with the Support Confirmation keyword.',
                placeholder:
                  'Your request has been forwarded to our support team. We will get back to you as soon as possible during our business hours.',
                required: true,
              }),
              IntegrationField.Config({
                name: 'serviceEndMessage',
                type: IntegrationFieldType.MULTILINE,
                label: 'Resolution Message',
                subtext:
                  'Your customers will receive this message once the support ticket has been resolved.',
                placeholder:
                  'Your request has been resolved. If you need anything else, do not hesitate to reach out.',
                required: false,
              }),
            ],
          }),
          IntegrationFeature.Config({
            type: 'FORWARD_MESSAGE_CUSTOMER_SERVICE',
            label: 'Forward Subscriber Messages through Customer Service',
            subtext: 'Turn on Customer Service Message Forwarding.',
          }),
        ]}
      />
    );
  },
};
