import _useSegmentsListCompanyFragment_company from "./__generated__/useSegmentsListCompanyFragment_company.graphql";
import _useSegmentsListQuery from "./__generated__/useSegmentsListQuery.graphql";
import { useState, useEffect } from 'react';
import { useLazyLoadQuery, useRefetchableFragment } from 'react-relay';
import { useCurrentCompanyId } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import useSegmentsListCompanyFragment from './__generated__/useSegmentsListCompanyFragment_company.graphql';
import useSegmentsListQuery from './__generated__/useSegmentsListQuery.graphql';
const PAGE_LIMIT = 30;
_useSegmentsListQuery;
_useSegmentsListCompanyFragment_company;

const useSegmentsList = () => {
  const companyId = useCurrentCompanyId();
  const queryData = useLazyLoadQuery(useSegmentsListQuery, {
    companyId,
    first: PAGE_LIMIT,
    after: null
  }, {
    fetchPolicy: 'store-and-network'
  });
  const [{
    segments
  }, refetch] = useRefetchableFragment(useSegmentsListCompanyFragment, queryData.company);
  const [segmentList, setSegmentList] = useState((segments === null || segments === void 0 ? void 0 : segments.edges) || []);
  useEffect(() => {
    if (segments !== null && segments !== void 0 && segments.pageInfo.hasNextPage) {
      refetch({
        after: segments === null || segments === void 0 ? void 0 : segments.pageInfo.endCursor
      });
    }
  }, [segments, refetch]);
  useEffect(() => {
    if (segments === null || !segments.edges.length) return;
    const activeSegments = segments.edges.filter(segment => segment.node.status === 'SEGMENT_STATUS_ACTIVE'); // If no new active segments, or if first new value is already in the segmentList, do nothing

    if (!activeSegments.length || activeSegments.length && segmentList.find(segment => segment.node.id === activeSegments[0].node.id)) {
      return;
    }

    setSegmentList(list => [...list, ...activeSegments]);
  }, [segments, segmentList]);
  return segmentList;
};

export default useSegmentsList;