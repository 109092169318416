import copyToClipboard from 'copy-to-clipboard';
import React from 'react';

import { TextInput, InputGroup, Box, Heading } from '@attentive/picnic';

type GenerateTokenValueProps = {
  token: string;
  subtitle: string;
};

export const GenerateTokenValue: React.FC<GenerateTokenValueProps> = ({ token, subtitle }) => {
  const [buttonText, setButtonText] = React.useState<string>('Copy');

  const copyToken = (val: string) => {
    copyToClipboard(val);
    setButtonText('Copied!');
  };

  return (
    <Box css={{ width: '100%' }}>
      <Heading variant="subheading">{subtitle ? subtitle : ''}</Heading>
      <InputGroup css={{ width: '100%' }}>
        <TextInput value={token} />
        <InputGroup.RightElement>
          <InputGroup.InlineButton type="button" onClick={() => copyToken(token)}>
            {buttonText}
          </InputGroup.InlineButton>
        </InputGroup.RightElement>
      </InputGroup>
    </Box>
  );
};
