import React, { FC, ReactNode, ReactElement } from 'react';

import { Heading, Stack, Text, Icon } from '@attentive/picnic';

import { NO_INTEGRATION_RESULTS } from '../../api';
import {
  IntegrationContextProvider,
  IntegrationContextBag,
} from '../../hooks/useIntegrationContext';
import { IntegrationDetailsResponse, VendorKey } from '../../types';
import { InstallationCard } from '../InstallationCard';
import { IntegrationFeatureConfig } from '../IntegrationFeature';
import { IntegrationFieldConfig } from '../IntegrationField';
import { ErrorBanner } from '../SetupConnectAndConfigure/ErrorBanner';
import { LoadingScreen } from '../SetupConnectAndConfigure/LoadingScreen';
import { StandardConfigureForm } from '../SetupConnectAndConfigure/StandardConfigureForm';
import { SuccessStep } from '../SetupConnectAndConfigure/SuccessStep';
import { SetupLogo } from '../SetupLogo';
import { TimeEstimation } from '../TimeEstimation';

import { StandardOauthConnectForm } from './StandardOauthConnectForm';

interface ConnectFormParams {
  oAuthState?: string;
}

interface ConfigureFormParams {
  onConfigureComplete: () => void;
}

interface Props {
  vendorKey: VendorKey;
  oAuthKey?: string;
  oAuthState?: string;

  connectInstructions?: ReactNode;
  connectFields?: IntegrationFieldConfig[];
  connectForm?: (params: ConnectFormParams) => ReactElement;

  configureInstructions?: ReactNode;
  configureFields?: IntegrationFieldConfig[];
  configureFeatures?: IntegrationFeatureConfig[];
  configureForm?: (params: ConfigureFormParams) => ReactElement;

  onComplete?: (integrationDetails: IntegrationDetailsResponse) => void;
  superUserEnabled?: boolean;
}

export const SetupOauthConnectAndConfigure: FC<Props> = ({
  vendorKey,
  oAuthKey,
  oAuthState,

  connectInstructions,
  connectFields,
  connectForm,

  configureInstructions,
  configureFields,
  configureForm,
  configureFeatures,

  onComplete,
  superUserEnabled,
}) => {
  if (!!configureForm && (!!configureFields || !!configureFeatures)) {
    throw new Error(
      `👋 Attentive Engineer: You cannot provide both a configureForm function
      as well a configureFields / configureFeatures arrays. Provide either A) a configureForm
      function that returns a custom configure form component or B) an array of
      IntegrationField.Config objects and/or and array of IntegrationFeature.Config objects
      to render a standard configure form.`.replace(/\s+/g, ' ')
    );
  }

  if (!!connectForm && !!connectFields) {
    throw new Error(
      `👋 Attentive Engineer: You cannot provide both a connectForm function
      as well a connectFields arrays. Provide either A) a connectForm
      function that returns a custom connect form component or B) an array of
      IntegrationField.Config objects to render a standard oauth connect 
      form.`.replace(/\s+/g, ' ')
    );
  }

  const hasConfigureConfig = configureFields || configureFeatures || configureForm;
  if (!hasConfigureConfig) {
    throw new Error(
      `👋 Attentive Engineer: You must provide configuration for the
      configure step. You can provide either A) configureFields and/or
      configureFeatures; or B) configureForm`.replace(/\s+/g, ' ')
    );
  }

  return (
    <IntegrationContextProvider vendorKey={vendorKey}>
      {({
        vendorConfig,
        vendorDetails,
        isFetching,
        isFetchingError,
        isConnectStep,
        isConfigureStep,
        isSuccessStep,
        refetchIntegrationDetails,
      }: IntegrationContextBag) => {
        const onConfigureComplete = async () => {
          const { data } = await refetchIntegrationDetails();
          if (onComplete) {
            const onCompleteData = data === NO_INTEGRATION_RESULTS ? undefined : data;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onComplete(onCompleteData!);
          }
        };

        return (
          <InstallationCard>
            <InstallationCard.Header
              leftSlot={
                <Stack spacing="$space2" direction="horizontal" css={{ alignItems: 'center' }}>
                  <SetupLogo src={vendorConfig.logo} />
                  <Heading variant="sm">Connect {vendorDetails?.displayName}</Heading>
                </Stack>
              }
              rightSlot={
                isSuccessStep ? (
                  <Stack
                    direction="horizontal"
                    spacing="$space1"
                    css={{ alignItems: 'center', color: '$green700' }}
                  >
                    <Icon name="CircleCheckmark" />
                    <Text variant="caption">Completed</Text>
                  </Stack>
                ) : (
                  <TimeEstimation>2 mins</TimeEstimation>
                )
              }
            />
            <InstallationCard.Body>
              {isFetching && <LoadingScreen />}
              {isFetchingError && <ErrorBanner text="Unable to load setup module." />}

              {isConnectStep &&
                (connectForm ? (
                  connectForm({ oAuthState })
                ) : (
                  <StandardOauthConnectForm
                    oAuthKey={oAuthKey}
                    oAuthState={oAuthState}
                    fields={connectFields}
                    instructions={connectInstructions}
                  />
                ))}

              {isConfigureStep &&
                (configureForm ? (
                  configureForm({ onConfigureComplete })
                ) : (
                  <StandardConfigureForm
                    instructions={configureInstructions}
                    fields={configureFields}
                    features={configureFeatures}
                    onComplete={onConfigureComplete}
                    superUserEnabled={superUserEnabled}
                  />
                ))}

              {isSuccessStep && <SuccessStep />}
            </InstallationCard.Body>
          </InstallationCard>
        );
      }}
    </IntegrationContextProvider>
  );
};
