import { Formik, FormikHelpers, useField } from 'formik';
import isEmpty from 'lodash/isEmpty';
import React, { FC, useCallback, useState, useMemo } from 'react';
import * as Yup from 'yup';

import {
  Box,
  FormField,
  Button,
  Icon,
  Text,
  StandardDialog,
  Heading,
  Stack,
  Card,
  IconButton,
} from '@attentive/picnic';

import { IntegrationFieldType } from '../../types';
import { IntegrationField } from '../IntegrationField';

type Values = {
  name: string;
  surveyId: string;
  triggerOverride: string;
  associatedPhone: string;
  startKeyword: string;
  cancelKeyword: string;
  index: number;
};

const PU_DIALOG_WIDTH = 544;
const PU_SEGMENT_CARD_WIDTH = 463;
const ADDING = -1;
const FEATURE_TYPE = 'FEATURE_SURVEY_MESSAGE';

export const ChatterCreateSurveysFeature: FC = () => {
  const [{ value: surveyListsValue }, , { setValue: setSurveyListsValue }] = useField(
    `${FEATURE_TYPE}.surveyList`
  );
  const [, , { setValue: setEnabledValue }] = useField(`${FEATURE_TYPE}.enabled`);

  const surveyLists = useMemo<Values[]>(
    () => (surveyListsValue ? JSON.parse(surveyListsValue) : []),
    [surveyListsValue]
  );

  const [open, setOpen] = useState(false);

  const saveSurvey = useCallback(
    (values: Values, _formikHelpers: FormikHelpers<Values>) => {
      const listsCopy = surveyLists.slice();
      if (values.index === ADDING) {
        listsCopy.push(values);
      } else {
        listsCopy.splice(values.index, 1, values);
      }
      setSurveyListsValue(JSON.stringify(listsCopy));

      setEnabledValue(true);
      setOpen(false);
    },
    [surveyLists, setEnabledValue, setSurveyListsValue, setOpen]
  );

  const isUniqueStartKeyword = useCallback(
    (startKeyword: string | undefined, isAdding: boolean) => {
      const numElements = surveyLists.filter(
        (value) => value.startKeyword.toUpperCase().trim() === startKeyword?.toUpperCase().trim()
      ).length;
      return isAdding ? numElements === 0 : numElements <= 1;
    },
    [surveyLists]
  );

  const isUniqueName = useCallback(
    (name: string | undefined, isAdding: boolean) => {
      const numElements = surveyLists.filter(
        (value) => value.name.toUpperCase().trim() === name?.toUpperCase().trim()
      ).length;
      return isAdding ? numElements === 0 : numElements <= 1;
    },
    [surveyLists]
  );

  const deleteSurvey = useCallback(
    (index: number) => {
      surveyLists.splice(index, 1);
      setEnabledValue(!isEmpty(surveyLists));
    },
    [surveyLists, setEnabledValue]
  );

  return (
    <Formik
      initialValues={
        {
          surveyId: '',
          triggerOverride: '',
          associatedPhone: '',
          startKeyword: '',
          cancelKeyword: '',
          index: ADDING,
        } as Values
      }
      validationSchema={Yup.object({
        index: Yup.number().required(''),
        name: Yup.string()
          .when('index', {
            is: ADDING,
            then: Yup.string()
              .required('This field is required')
              .test('must-be-unique', 'A survey exists with this Name', (value) =>
                isUniqueName(value, true)
              ),
          })
          .when('index', {
            is: (index: number) => index > ADDING,
            then: Yup.string()
              .required('This field is required')
              .test('must-be-unique', 'A survey exists with this Name', (value) =>
                isUniqueName(value, false)
              ),
          }),
        surveyId: Yup.string().required('This field is required'),
        triggerOverride: Yup.string().required('This field is required.'),
        associatedPhone: Yup.string().required('This field is required.'),
        startKeyword: Yup.string()
          .when('index', {
            is: ADDING,
            then: Yup.string()
              .required('This field is required')
              .test('must-be-unique', 'A survey exists with this Start Keyword', (value) =>
                isUniqueStartKeyword(value, true)
              ),
          })
          .when('index', {
            is: (index: number) => index > ADDING,
            then: Yup.string()
              .required('This field is required')
              .test('must-be-unique', 'A survey exists with this Start Keyword', (value) =>
                isUniqueStartKeyword(value, false)
              ),
          }),
        cancelKeyword: Yup.string().required('This field is required.'),
      })}
      onSubmit={saveSurvey}
    >
      {({ submitForm, resetForm, setValues, values }) => {
        const isAdding = values.index === ADDING;

        return (
          <>
            <Box css={{ pl: '$space14' }}>
              <FormField.Label htmlFor="Add Surveys">Add Surveys</FormField.Label>
              <FormField.HelperText>
                Add different Surveys with your Chatter Information.
              </FormField.HelperText>

              {surveyLists.map(
                (
                  { name, surveyId, triggerOverride, associatedPhone, startKeyword, cancelKeyword },
                  index
                ) => (
                  <Card
                    key={surveyId}
                    css={{
                      mt: '%',
                      p: '%',
                      width: PU_SEGMENT_CARD_WIDTH,
                      display: 'flex',
                      justifyContent: 'space-between',
                      boxShadow: 'none',
                    }}
                    aria-label="Survey Id"
                  >
                    <Box css={{ display: 'flex', alignItems: 'center' }}>
                      <Heading variant="sm">{name}</Heading>
                    </Box>

                    <Box css={{ display: 'flex' }}>
                      <IconButton
                        variant="subdued"
                        size="small"
                        iconName="Pencil"
                        description="Edit Survey"
                        onClick={() => {
                          resetForm();
                          setValues({
                            name,
                            surveyId,
                            triggerOverride,
                            associatedPhone,
                            startKeyword,
                            cancelKeyword,
                            index,
                          });
                          setOpen(true);
                        }}
                      />
                      <IconButton
                        variant="subdued"
                        size="small"
                        iconName="Delete"
                        description="Delete Survey"
                        onClick={() => deleteSurvey(index)}
                      />
                    </Box>
                  </Card>
                )
              )}

              <Button
                size="small"
                variant="subdued"
                css={{ mt: '$space4' }}
                onClick={() => {
                  resetForm();
                  setOpen(true);
                }}
              >
                <Icon size="small" name="PlusSign" />
                <Text css={{ pl: '$space2' }}>Add Survey</Text>
              </Button>
            </Box>

            <StandardDialog open={open} onOpenChange={(isOpen) => setOpen(isOpen)}>
              <StandardDialog.Content
                css={{ width: PU_DIALOG_WIDTH }}
                aria-label={isAdding ? 'Add Survey Dialog' : 'Edit Survey Dialog'}
              >
                <StandardDialog.Header>
                  <StandardDialog.Heading>
                    {isAdding ? 'Add' : 'Edit'} Survey
                  </StandardDialog.Heading>
                </StandardDialog.Header>
                <StandardDialog.Body>
                  <Stack direction="vertical" spacing="$space8">
                    <Box css={{ width: '100%' }}>
                      <IntegrationField
                        type={IntegrationFieldType.TEXT}
                        name="name"
                        label="Name"
                        required={true}
                        placeholder="My Survey"
                      />
                    </Box>

                    <Box css={{ width: '100%' }}>
                      <IntegrationField
                        type={IntegrationFieldType.TEXT}
                        name="surveyId"
                        label="Survey ID"
                        required={true}
                        placeholder="1"
                      />
                    </Box>

                    <Box css={{ width: '100%' }}>
                      <IntegrationField
                        type={IntegrationFieldType.TEXT}
                        name="triggerOverride"
                        label="Trigger Override"
                        required={true}
                        placeholder="data."
                      />
                    </Box>

                    <Box css={{ width: '100%' }}>
                      <IntegrationField
                        type={IntegrationFieldType.TEXT}
                        name="associatedPhone"
                        label="Associated Phone"
                        required={true}
                        placeholder="1010"
                      />
                    </Box>
                    <Box css={{ width: '100%' }}>
                      <IntegrationField
                        type={IntegrationFieldType.TEXT}
                        name="startKeyword"
                        label="Start Keyword"
                        required={true}
                        placeholder="SurveyStart"
                      />
                    </Box>
                    <Box css={{ width: '100%' }}>
                      <IntegrationField
                        type={IntegrationFieldType.TEXT}
                        name="cancelKeyword"
                        label="Cancel Keyword"
                        required={true}
                        placeholder="SurveyCancel"
                      />
                    </Box>
                  </Stack>
                </StandardDialog.Body>
                <StandardDialog.Footer>
                  <StandardDialog.Close onClick={() => setOpen(false)} variant="secondary">
                    Cancel
                  </StandardDialog.Close>

                  <Button onClick={submitForm}>{isAdding ? 'Add' : 'Update'} Survey</Button>
                </StandardDialog.Footer>
              </StandardDialog.Content>
            </StandardDialog>
          </>
        );
      }}
    </Formik>
  );
};
