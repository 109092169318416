import React from 'react';

import rejoinerLogo from '../assets/vendor_logos/rejoiner.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const rejoiner: Vendor = {
  logo: rejoinerLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://rejoiner.com/',
  infoPagesEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contacts to Rejoiner.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Rejoiner so that you can start sending marketing emails immediately. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A Rejoiner account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        "Automatically sync subscriber's emails with Rejoiner so that you can start sending marketing emails immediately.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360042223392',
      label: 'Rejoiner integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.REJOINER}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'siteId',
            type: IntegrationFieldType.TEXT,
            label: 'Site ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listId',
            type: IntegrationFieldType.TEXT,
            label: 'List ID',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.REJOINER}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'siteId',
            type: IntegrationFieldType.TEXT,
            label: 'Site ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listId',
            type: IntegrationFieldType.TEXT,
            label: 'List ID',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Rejoiner',
          }),
        ]}
      />
    );
  },
};
