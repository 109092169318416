import React, { FC } from 'react';

import { Box, Stack } from '@attentive/picnic';

import { useConfigureIntegrationMutation } from '../../api';
import useSetMilestone, { MilestoneKey } from '../../hooks/useSetMilestone';
import { ListField } from '../Klaviyo';

import { DisconnectButton } from './DisconnectButton';
import { IntegrationForm } from './IntegrationForm';
import { SubmitButton } from './SubmitButton';

interface Props {
  onComplete: () => void;
  superUserEnabled?: boolean;
}

export const KlaviyoConfigureForm: FC<Props> = ({ onComplete }) => {
  const setMilestonesComplete = useSetMilestone();

  const initialValues = {
    listId: '',
  };

  const validationConfig = {
    fields: {
      listId: { required: true },
    },
  };

  const customOnComplete = () => {
    setMilestonesComplete([MilestoneKey.MilestoneKeyEspIntegrationCompleted]);
    onComplete();
  };

  return (
    <IntegrationForm
      loadingText="Configuring"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useConfigureIntegrationMutation}
      onComplete={customOnComplete}
    >
      <Box css={{ width: '100%' }}>
        <Box as="span">Now, select the list where you want to connect to.</Box>
      </Box>

      <ListField label="Select the list you want to connect with" />

      <Stack direction="horizontal" spacing="$space6">
        <SubmitButton text="Save and continue" dataIntegrationsId="Klaviyo Submit Button" />
        <DisconnectButton />
      </Stack>
    </IntegrationForm>
  );
};
