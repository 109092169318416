import React, { FC } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { Box } from '@attentive/picnic';

import { useConnectIntegrationMutation } from '../../api';
import { IntegrationFieldType } from '../../types';
import { IntegrationField } from '../IntegrationField';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';
import { WebhookCallbackUrl } from '../WebhookCallbackUrl';

interface Props {
  onComplete: () => void;
}

export const AftershipConnectForm: FC<Props> = ({ onComplete }) => {
  const newAftershipSetupEnabled = useCompanyFeatureFlag('ENABLE_NEW_AFTERSHIP_SETUP');

  const initialValues = {
    apiKey: '',
    trackingPage: '',
    webhookSecret: '',
  };

  const validationConfig = {
    fields: {
      apiKey: { required: true },
      trackingPage: { required: true },
      webhookSecret: { required: true },
    },
  };

  return (
    <IntegrationForm
      loadingText="Configuring"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useConnectIntegrationMutation}
      onComplete={onComplete}
    >
      {newAftershipSetupEnabled && (
        <Box css={{ width: '100%' }}>
          <WebhookCallbackUrl
            path="/v1/inbound-webhooks/aftership"
            subtext="Paste this into Aftership to get your Webhook Secret"
            useCentralizedInboundWebhookUrl={false}
          />
        </Box>
      )}

      <IntegrationField
        name="apiKey"
        type={IntegrationFieldType.PASSWORD}
        label="API Key"
        required={true}
        css={{ width: '100%' }}
      />
      <IntegrationField
        name="trackingPage"
        type={IntegrationFieldType.TEXT}
        label="Tracking Page"
        required={true}
        css={{ width: '100%' }}
      />
      <IntegrationField
        name="webhookSecret"
        type={IntegrationFieldType.TEXT}
        label="Webhook Secret"
        required={true}
        css={{ width: '100%' }}
      />

      <SubmitButton text="Save and continue" />
    </IntegrationForm>
  );
};
