import React from 'react';

import placeholderLogo from '../assets/vendor_logos/placeholder.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  CreativeSpecificDataKeyNames,
} from '../types';

export const vendorOptimove: Vendor = {
  category: VendorCategory.ANALYTICS,
  logo: placeholderLogo,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_OPTIMOVE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'tenantId',
            type: IntegrationFieldType.TEXT,
            label: 'Tenant Id',
            required: true,
          }),
          IntegrationField.Config({
            name: 'version',
            type: IntegrationFieldType.TEXT,
            label: 'version',
            required: true,
          }),
          IntegrationField.Config({
            name: 'customEvent',
            type: IntegrationFieldType.TEXT,
            label: 'Custom Event',
            required: false,
          }),
          IntegrationField.Config({
            name: 'customEventJson',
            type: IntegrationFieldType.TEXT,
            label: 'Custom Event Json',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_OPTIMOVE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'tenantId',
            type: IntegrationFieldType.TEXT,
            label: 'Tenant Id',
            required: true,
          }),
          IntegrationField.Config({
            name: 'version',
            type: IntegrationFieldType.TEXT,
            label: 'version',
            required: true,
          }),
          IntegrationField.Config({
            name: 'customEvent',
            type: IntegrationFieldType.TEXT,
            label: 'Custom Event',
            required: false,
          }),
          IntegrationField.Config({
            name: 'customEventJson',
            type: IntegrationFieldType.TEXT,
            label: 'Custom Event Json',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Optimove',
          }),
        ]}
      />
    );
  },
  creativeSpecificData: { type: 'compact', key: CreativeSpecificDataKeyNames.DATA },
};
