import React from 'react';

import fivetranLogo from '../assets/vendor_logos/fivetran.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

export const vendorFivetran: Vendor = {
  logo: fivetranLogo,
  category: VendorCategory.CUSTOMER_DATA_PLATFORM,
  categories: [
    { name: VendorCategory.CUSTOMER_DATA_PLATFORM, primary: true },
    { name: VendorCategory.ANALYTICS, primary: false },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_FIVETRAN}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiToken',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Token',
            required: true,
          }),
          IntegrationField.Config({
            name: 'endpoint',
            type: IntegrationFieldType.TEXT,
            label: 'Endpoint',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_FIVETRAN}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'apiToken',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Token',
            required: true,
          }),
          IntegrationField.Config({
            name: 'endpoint',
            type: IntegrationFieldType.TEXT,
            label: 'Endpoint',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Fivetran.',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward phone numbers',
            subtext: 'Forward any phone numbers collected by Attentive to Fivetran.',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
        ]}
      />
    );
  },
};
