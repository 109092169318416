import narvarLogo from '../assets/vendor_logos/narvar.png';
import { VendorCategory, Vendor } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorNarvar: Vendor = {
  logo: narvarLogo,
  category: VendorCategory.ECOMMERCE,
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description: 'Set up journeys to trigger messages based on actions like Order Delivered',
    },
  ],
};
