import React, { FC, ReactNode } from 'react';

import { Box, Stack } from '@attentive/picnic';

import { useConnectIntegrationMutation } from '../../api';
import { buildValidationConfig } from '../../utils';
import { IntegrationFieldConfig } from '../IntegrationField';

import { IntegrationForm } from './IntegrationForm';
import { SubmitButton } from './SubmitButton';

interface Props {
  instructions?: ReactNode;
  fields: IntegrationFieldConfig[];
  onComplete: () => void;
  additionalAction?: ReactNode;
}

export const StandardConnectForm: FC<Props> = ({
  instructions,
  fields,
  onComplete,
  additionalAction,
}) => {
  let formHasfields = true;
  if (fields.length === 0) {
    formHasfields = false;
  }
  const initialValues = Object.fromEntries(fields.map((field) => [field.componentProps.name, '']));
  const validationConfig = buildValidationConfig({ fields });
  return (
    <IntegrationForm
      loadingText="Connecting"
      initialValues={initialValues}
      validationConfig={validationConfig}
      formHasfields={formHasfields}
      mutation={useConnectIntegrationMutation}
      onComplete={onComplete}
    >
      {instructions && <Box>{instructions}</Box>}
      {fields.map((field) => field())}
      <Stack direction="horizontal">
        <SubmitButton text="Save and continue" />
        {additionalAction}
      </Stack>
    </IntegrationForm>
  );
};
