import React from 'react';

import gorgiasLogo from '../assets/vendor_logos/gorgias.png';
import { SettingsStandard } from '../components/SettingsStandard';
import { GorgiasSettingsForm } from '../components/SettingsStandard/GorgiasSettingsForm';
import { GorgiasDualSetupForm } from '../components/SetupGorgias/GorgiasDualSetupForm';
import { VendorCategory, Vendor, VendorKey } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const gorgias: Vendor = {
  logo: gorgiasLogo,
  category: VendorCategory.CUSTOMER_EXPERIENCE,
  companyWebsite: 'https://www.gorgias.com/',
  salesEmail: 'sales@gorgias.com',
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext: 'Deliver great customer service and offer support via text messaging.',
  jointValueProp:
    'Gorgias gives customer support teams a true all-in-one help desk experience across email, live chat, phone, Facebook, Instagram, and contact us page in one unified view to streamline support tickets and save Ecommerce stores time and money. Our integration with Gorgias allows you to deliver great customer service by supporting customers via text messaging. Customer service teams can respond directly to text messages via text within the Gorgias platform.',
  whatYoullNeedItems: ['A Gorgias account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.CUSTOMER_SUPPORT,
      description:
        'All messages received from a customer through SMS are forwarded to your customer support team queue in Gorgias.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360041419212',
      label: 'Gorgias integration guide',
    },
  ],
  setupComponent(onComplete) {
    return <GorgiasDualSetupForm onComplete={onComplete} />;
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.GORGIAS}
        onComplete={onComplete}
        form={({ onFormComplete }) => <GorgiasSettingsForm onComplete={onFormComplete} />}
      />
    );
  },
};
