import React from 'react';

import salesforceServiceCloudLogo from '../assets/vendor_logos/salesforce-service-cloud.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

export const salesforceJourney: Vendor = {
  logo: salesforceServiceCloudLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.SALESFORCE_JOURNEY}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'EventDefinitionKey',
            type: IntegrationFieldType.TEXT,
            label: 'Event Definition Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.TEXT,
            label: 'Client ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'subdomain',
            type: IntegrationFieldType.TEXT,
            label: 'Subdomain',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailField',
            type: IntegrationFieldType.TEXT,
            label: 'Email Field',
            required: true,
          }),
          IntegrationField.Config({
            name: 'sourceField',
            type: IntegrationFieldType.TEXT,
            label: 'Source Field',
            required: true,
          }),
          IntegrationField.Config({
            name: 'phoneField',
            type: IntegrationFieldType.TEXT,
            label: 'Phone Field',
            required: false,
          }),
          IntegrationField.Config({
            name: 'dateAddedField',
            type: IntegrationFieldType.TEXT,
            label: 'Date Added Field',
            required: false,
          }),
          IntegrationField.Config({
            name: 'postPreferences',
            type: IntegrationFieldType.TEXT,
            label: 'Post Coupon Preferences',
            required: false,
          }),
          IntegrationField.Config({
            name: 'JoinEventDefinitionKey',
            type: IntegrationFieldType.TEXT,
            label: 'Join EventDefinition Key',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.SALESFORCE_JOURNEY}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'EventDefinitionKey',
            type: IntegrationFieldType.TEXT,
            label: 'Event Definition Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.TEXT,
            label: 'Client ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'subdomain',
            type: IntegrationFieldType.TEXT,
            label: 'Subdomain',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailField',
            type: IntegrationFieldType.TEXT,
            label: 'Email Field',
            required: true,
          }),
          IntegrationField.Config({
            name: 'sourceField',
            type: IntegrationFieldType.TEXT,
            label: 'Source Field',
            required: true,
          }),
          IntegrationField.Config({
            name: 'phoneField',
            type: IntegrationFieldType.TEXT,
            label: 'Phone Field',
            required: false,
          }),
          IntegrationField.Config({
            name: 'dateAddedField',
            type: IntegrationFieldType.TEXT,
            label: 'Date Added Field',
            required: false,
          }),
          IntegrationField.Config({
            name: 'postPreferences',
            type: IntegrationFieldType.TEXT,
            label: 'Post Coupon Preferences',
            required: false,
          }),
          IntegrationField.Config({
            name: 'JoinEventDefinitionKey',
            type: IntegrationFieldType.TEXT,
            label: 'Join EventDefinition Key',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Email Forwarding',
            subtext: 'Forward any emails collected by Attentive to Salesforce.',
            visibility: IntegrationVisibilityType.SUPER_USER,
            fields: [
              IntegrationField.Config({
                name: 'customContactKeyTemplate',
                type: IntegrationFieldType.TEXT,
                label: 'Custom Contact Key Template',
                subtext: 'Custom template for the Salesforce contact key: must include {email}',
                placeholder: '{email}-ExampleBrand-US',
                required: false,
              }),
              IntegrationField.Config({
                name: 'emailSourceValue',
                type: IntegrationFieldType.TEXT,
                label: 'Email Source Value',
                subtext:
                  'Value of the source field when forwarding an email capture event. Default is Attentive',
                placeholder: 'Attentive',
                required: false,
              }),
              IntegrationField.Config({
                name: 'smsSourceValue',
                type: IntegrationFieldType.TEXT,
                label: 'SMS Source Value',
                subtext:
                  'Value of the source field when forwarding an SMS capture event. Default is Attentive',
                placeholder: 'Attentive',
                required: false,
              }),
            ],
          }),
        ]}
      />
    );
  },
};
