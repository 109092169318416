import pick from 'lodash/pick';
import React, { FC } from 'react';
import { useFragment } from 'react-relay';

import { Box } from '@attentive/picnic';

import {
  useIntegrationsCommonQuery,
  IntegrationsCommonQueryBody,
} from '../../IntegrationsCommonPresenter';
import { useUpdateIntegrationSettingsMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import {
  IntegrationFormFeatureValues,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../../types';
import { IntegrationFeature } from '../IntegrationFeature';
import { IntegrationField } from '../IntegrationField';
import { IntegrationTextField } from '../IntegrationField/IntegrationTextField';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

import IntegrationsCommonWebhookCallbackInstructionsFragment_query, {
  IntegrationsCommonWebhookCallbackInstructionsFragment_query$key,
} from '../WebhookCallbackUrl/__generated__/IntegrationsCommonWebhookCallbackInstructionsFragment_query.graphql';

const valueKeys = {
  apiKey: 'apiKey',
  apiSecret: 'apiSecret',
  environment: 'environment',
  affiliateToken: 'affiliate_token',
  postbackEmails: 'POSTBACK_EMAILS',
  thirdPartyProcessorForwarder: 'THIRD_PARTY_PROCESSOR_FORWARDER',
  featureIngestUserData: 'FEATURE_INGEST_USER_DATA',
} as const;

interface Props {
  onComplete: () => void;
}

const MParticleSettingsFormComponent: FC<Props> = ({ onComplete }) => {
  const queryData = useIntegrationsCommonQuery();
  const fragData = useFragment<IntegrationsCommonWebhookCallbackInstructionsFragment_query$key>(
    IntegrationsCommonWebhookCallbackInstructionsFragment_query,
    queryData
  );
  const { externalId = '' } = fragData.company || {};
  const { integrationDetailValues } = useIntegrationContext();

  const initialValues = pick(integrationDetailValues || {}, Object.values(valueKeys)) as {
    [type: string]: IntegrationFormFeatureValues;
  };

  const validationConfig = {
    fields: {
      [valueKeys.apiKey]: { required: true },
      [valueKeys.apiSecret]: { required: true },
      [valueKeys.environment]: { required: true },
      [valueKeys.affiliateToken]: { required: true },
    },
  };

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useUpdateIntegrationSettingsMutation}
      onComplete={onComplete}
    >
      <IntegrationField
        name={valueKeys.apiKey}
        type={IntegrationFieldType.TEXT}
        label="API Key"
        required
        css={{ width: '100%' }}
      />
      <IntegrationField
        name={valueKeys.apiSecret}
        type={IntegrationFieldType.TEXT}
        label="API Secret"
        required
        css={{ width: '100%' }}
      />
      <IntegrationField
        name={valueKeys.environment}
        type={IntegrationFieldType.SELECT}
        label="Environment"
        required
        options={[
          {
            name: 'Development',
            value: 'development',
          },
          {
            name: 'Production',
            value: 'production',
          },
        ]}
        css={{ width: '100%' }}
      />
      {initialValues[valueKeys.affiliateToken] ? (
        <IntegrationField
          name={valueKeys.affiliateToken}
          type={IntegrationFieldType.PASSWORD}
          label="Attentive API Key"
          subtext="This API Key can be used to install an Attentive Destination in the mParticle UI."
          required
          disabled
          css={{ width: '100%' }}
        />
      ) : null}
      <Box css={{ width: '100%' }}>
        <IntegrationTextField
          label="External Company ID"
          name="externalCompanyId"
          value={externalId.toString()}
          subtext="Enter this value for customer ID in mParticle while setting up an Attentive output event."
          onChange={() => {}}
          onBlur={() => {}}
          required
          disabled
          copyable
        />
      </Box>
      <IntegrationFeature
        type={valueKeys.postbackEmails}
        label="Customer Data forwarding HTTP Adapter"
        subtext="Forward any users collected by Attentive to mParticle"
        visibility={IntegrationVisibilityType.SUPER_USER}
      />
      <IntegrationFeature
        type={valueKeys.thirdPartyProcessorForwarder}
        label="Customer Data forwarding HTTP Processor"
        subtext="Forward any users collected by Attentive to mParticle"
        visibility={IntegrationVisibilityType.SUPER_USER}
      />
      <IntegrationFeature
        type={valueKeys.featureIngestUserData}
        label="Ingest User Profiles and Events"
        subtext="Ingest User Profiles and Events from mParticle"
        visibility={IntegrationVisibilityType.SUPER_USER}
      />
      <SubmitButton text="Save Changes" />
    </IntegrationForm>
  );
};

export const MParticleSettingsForm: React.FC<Props> = (props) => (
  <IntegrationsCommonQueryBody>
    <MParticleSettingsFormComponent {...props} />
  </IntegrationsCommonQueryBody>
);
