import React from 'react';

import shopifyLogo from '../assets/vendor_logos/shopify.png';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupShopify } from '../components/SetupShopify';
import { ShopifySettingsForm } from '../components/SetupShopify/ShopifySettingsForm';
import { VendorCategory, Vendor, VendorKey } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const shopify: Vendor = {
  logo: shopifyLogo,
  companyWebsite: 'https://www.shopify.com/',
  salesEmail: 'partnerships@shopify.com',
  category: VendorCategory.ECOMMERCE,
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext:
    'Deliver personalized, interactive customer experiences, powered by your Shopify data.',
  jointValueProp:
    'Grow your subscriber lists faster by enrolling customers in SMS directly from the Shopify checkout page. Create customer segments based on past shopping behaviour and product preferences, and auto-generate dynamic coupons for up-sell and cross-sell campaigns. Use Shopify data to send curated product recommendations, automate revenue-generating moments with browse, cart, and checkout abandonment reminders, and keep customers engaged every step of the way with timely updates about their orders.',
  whatYoullNeedItems: [
    'A Shopify or Shopify Plus account.',
    <>
      Your Shopify Shop Name. You can find your Shop Name from your Shop URL which should follow
      this pattern: <b>myshopname.myshopify.com.</b> For example, given a Shop URL{' '}
      <b>siren-denim.myshopify.com</b>, your Shop Name would be <b>siren-denim</b>.
    </>,
  ],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.LIST_GROWTH,
      description:
        'Enabling this integration allows you to collect subscribers during checkout in your Shopify Store. Visit the <a href="https://help.shopify.com/en/manual/promoting-marketing/create-marketing/customer-contact-information" target="_blank">Shopify Help Center</a> to learn more.',
    },
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Set up journeys based on inventory changes like: Back in Stock, Low Inventory or Product Purchased events. Set up journeys based on transactional events like: Order Confirmed and Order Shipped.',
    },
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description:
        'Target subscribers by creating segments based on Product Collections, Product Prices, Purchased data and Customer data.',
    },
    {
      feature: ATTENTIVE_FEATURES.OFFERS,
      description:
        'Set up dynamic discount codes that automatically re-generate when they run out.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360041854231-Shopify-Integration-Guide',
      label: 'Set up the Shopify integration',
    },
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360056977212',
      label: 'Set up transactional messages for Shopify',
    },
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360056612472-ReCharge',
      label: 'Set up the ReCharge integration',
    },
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360057610371-Set-up-transactional-messages-for-ReCharge',
      label: 'Set up transactional messages for ReCharge',
    },
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360057252592-Create-auto-gen-discount-codes-with-Shopify',
      label: 'Auto-gen coupon sets with Shopify',
    },
  ],
  disconnectText:
    'If you disconnect this integration, your Shopify segments, journeys, and offers will stop working.',
  disconnectConfirmationText:
    'Are you sure that you want to disconnect your Shopify integration? If you disconnect this integration, your Shopify segments, journeys, and offers will stop working.',
  fieldSubtexts: {
    shopName: {
      manage:
        'Updating your Shop Name will disconnect your current shop and remove all of its segments, journeys and offers.',
    },
  },
  confirmChangesText:
    'This will disconnect your current shop and remove all of its segments, journeys and offers. It will take a few minutes to sync data for your new shop.',
  setupComponent(_onComplete, superUserEnabled) {
    return <SetupShopify superUserEnabled={superUserEnabled || false} />;
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.SHOPIFY}
        onComplete={onComplete}
        form={({ onFormComplete }) => (
          <ShopifySettingsForm
            onComplete={onFormComplete}
            superUserEnabled={superUserEnabled || false}
          />
        )}
      />
    );
  },
};
