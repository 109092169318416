import React from 'react';

import placeholderLogo from '../assets/vendor_logos/placeholder.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { ChatterSettingsForm } from '../components/SettingsStandard/ChatterSettingsForm';
import { SetupConnectAndConfigure } from '../components/SetupConnectAndConfigure';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

export const vendorChatter: Vendor = {
  category: VendorCategory.OTHER,
  logo: placeholderLogo,
  setupComponent(onComplete) {
    return (
      <SetupConnectAndConfigure
        vendorKey={VendorKey.VENDOR_CHATTER}
        onComplete={onComplete}
        connectFields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API KEY',
            required: true,
          }),
        ]}
        configureFeatures={[
          IntegrationFeature.Config({
            type: 'FEATURE_SURVEY_MESSAGE',
            label: 'Chatter Survey Properties',
            subtext: 'Chatter Survey Properties',
            initiallyEnabled: true,
            fields: [],
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_CHATTER}
        onComplete={onComplete}
        form={({ onFormComplete }) => <ChatterSettingsForm onComplete={onFormComplete} />}
      />
    );
  },
};
