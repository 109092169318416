import React from 'react';

import salesforceServiceCloudLogo from '../assets/vendor_logos/salesforce-service-cloud.png';
import { SettingsStandard } from '../components/SettingsStandard';
import { SalesforceServiceCloudSettingsForm } from '../components/SettingsStandard/SalesforceServiceCloudSettingsForm';
import { SetupSalesforceServiceCloud } from '../components/SetupSalesforceServiceCloud';
import { DistributionStatus, VendorCategory, Vendor, VendorKey } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorSalesforceServiceCloud: Vendor = {
  logo: salesforceServiceCloudLogo,
  category: VendorCategory.CUSTOMER_EXPERIENCE,
  distributionStatus: DistributionStatus.PUBLIC,
  companyWebsite: 'https://www.salesforce.com/products/service-cloud/overview/',
  infoPagesEnabled: true,
  learnSubtext: 'Help customers via SMS & sync contacts.',
  jointValueProp:
    'Attentive’s integration with Salesforce Service Cloud enables you to deliver seamless customer support via SMS across the customer cycle.  Support teams can respond to questions from SMS subscribers via SMS - directly from the Salesforce service console. This integration also allows you to sync new contacts collected by Attentive’s sign-up forms to Salesforce.',
  whatYoullNeedItems: ['A Salesforce Service Cloud account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        'Forward any emails collected by Attentive to Salesforce Service or Sales Cloud.',
    },
    {
      feature: ATTENTIVE_FEATURES.CUSTOMER_SUPPORT,
      description:
        'Responses to a specific support keyword are forwarded to your customer support team through the Salesforce Service Console.',
    },
  ],
  setupComponent(onComplete) {
    return <SetupSalesforceServiceCloud onComplete={onComplete} />;
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_SALESFORCE_SERVICE_CLOUD}
        onComplete={onComplete}
        form={({ onFormComplete }) => (
          <SalesforceServiceCloudSettingsForm onComplete={onFormComplete} />
        )}
      />
    );
  },
};
