import React, { FC } from 'react';

import { Button } from '@attentive/picnic';

import { useIntegrationContext } from '../../hooks/useIntegrationContext';

export const DisconnectButton: FC = () => {
  const { disconnectIntegration } = useIntegrationContext();

  return (
    <Button
      variant="subdued"
      onClick={() => {
        disconnectIntegration();
      }}
    >
      Disconnect
    </Button>
  );
};
