import React from 'react';

import travelclickLogo from '../assets/vendor_logos/travelclick.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

export const vendorTravelclick: Vendor = {
  logo: travelclickLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_TRAVELCLICK}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'username',
            type: IntegrationFieldType.TEXT,
            label: 'Username',
            required: true,
          }),
          IntegrationField.Config({
            name: 'password',
            type: IntegrationFieldType.TEXT,
            label: 'Password',
            required: true,
          }),
          IntegrationField.Config({
            name: 'list',
            type: IntegrationFieldType.TEXT,
            label: 'List Name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'source',
            type: IntegrationFieldType.TEXT,
            label: 'Source Name',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_TRAVELCLICK}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'username',
            type: IntegrationFieldType.TEXT,
            label: 'Username',
            required: true,
          }),
          IntegrationField.Config({
            name: 'password',
            type: IntegrationFieldType.TEXT,
            label: 'Password',
            required: true,
          }),
          IntegrationField.Config({
            name: 'list',
            type: IntegrationFieldType.TEXT,
            label: 'List Name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'source',
            type: IntegrationFieldType.TEXT,
            label: 'Source Name',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to TravelClick',
          }),
        ]}
      />
    );
  },
};
