import React from 'react';

import mparticleLogo from '../assets/vendor_logos/mparticle.png';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { MParticleSettingsForm } from '../components/SettingsStandard/MParticleSettingsForm';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  DistributionStatus,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const mparticle: Vendor = {
  logo: mparticleLogo,
  category: VendorCategory.CUSTOMER_DATA_PLATFORM,
  distributionStatus: DistributionStatus.BETA,
  companyWebsite: 'https://www.mparticle.com/',
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext: 'Real-time AI customer data platform that powers your marketing.',
  jointValueProp:
    'Sync customer and event data from mParticle to Attentive so that you can send highly personalized and timely messages. You can leverage mParticle user attributes or events to build segments to send targeted SMS messages and event data to trigger messages based on user actions. This integration also sends Attentive subscriber data to mParticle, such as SMS opt-in, SMS opt-out, and other activity to ensure mParticle is up-to-date.',
  whatYoullNeedItems: ['A mParticle account.'],
  categories: [
    { name: VendorCategory.CUSTOMER_DATA_PLATFORM, primary: true },
    { name: VendorCategory.ANALYTICS, primary: false },
  ],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description: 'Send new Attentive sign-ups as well as opt-outs to mParticle automatically.',
    },
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Trigger text messages based on events available in mParticle, like a user logging into your mobile site, clicking on an email, purchasing a product, and many more.',
    },
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description:
        'Create dynamic segments based on customer attributes or events from mParticle like user preferences, location, or loyalty tier.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360052417732-mParticle',
      label: 'mParticle integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.MPARTICLE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.TEXT,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'apiSecret',
            type: IntegrationFieldType.TEXT,
            label: 'API Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'environment',
            type: IntegrationFieldType.SELECT,
            label: 'Environment',
            required: true,
            options: [
              {
                name: 'Development',
                value: 'development',
              },
              {
                name: 'Production',
                value: 'production',
              },
            ],
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.MPARTICLE}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        form={({ onFormComplete }) => <MParticleSettingsForm onComplete={onFormComplete} />}
      />
    );
  },
};
