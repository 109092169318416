import React from 'react';

import { Select } from '@attentive/picnic';

import { FormField } from '../../FormField';

type IntegrationSelectFieldProps = {
  required: boolean;
  label: string;
  subtext?: string;
  error?: string;
  value?: string;
  placeholder?: string;
  disabled: boolean;
  onChange: (value: string) => void;
  options: Array<{ name: string; value: string }>;
  dataIntegrationsId?: string;
};

export const IntegrationSelectField: React.FC<IntegrationSelectFieldProps> = ({
  required,
  label,
  subtext,
  error,
  value,
  placeholder,
  disabled,
  onChange,
  options,
  dataIntegrationsId,
}) => {
  return (
    <FormField required={required} label={label} subtext={subtext} error={error}>
      <Select
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        state={error ? 'error' : 'normal'}
        data-integrations-id={dataIntegrationsId}
      >
        {options.map((option) => (
          <Select.Item key={option.value} value={option.value}>
            {option.name}
          </Select.Item>
        ))}
      </Select>
    </FormField>
  );
};
