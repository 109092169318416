import React from 'react';

import dotDigitalLogo from '../assets/vendor_logos/dotdigital.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorDotdigital: Vendor = {
  logo: dotDigitalLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://dotdigital.com/',
  infoPagesEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms, sync contacts to DotDigital.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to DotDigital and can trigger a welcome email to new email marketing subscribers. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A DotDigital account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        "Automatically sync subscriber's emails with DotDigital so that you can start sending marketing emails immediately.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360053314851',
      label: 'Dotdigital integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_DOTDIGITAL}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'username',
            type: IntegrationFieldType.TEXT,
            label: 'Username',
            required: true,
          }),
          IntegrationField.Config({
            name: 'password',
            type: IntegrationFieldType.PASSWORD,
            label: 'Password',
            required: true,
          }),
          IntegrationField.Config({
            name: 'region',
            type: IntegrationFieldType.SELECT,
            label: 'Region',
            required: true,
            options: [
              {
                name: 'Europe',
                value: 'r1',
              },
              {
                name: 'North America',
                value: 'r2',
              },
              {
                name: 'Asia Pacific',
                value: 'r3',
              },
            ],
          }),
          IntegrationField.Config({
            name: 'address_book_id',
            type: IntegrationFieldType.TEXT,
            label: 'Address Book ID',
            required: false,
          }),
          IntegrationField.Config({
            name: 'consent_text',
            type: IntegrationFieldType.TEXT,
            label: 'Consent Text',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_DOTDIGITAL}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'username',
            type: IntegrationFieldType.TEXT,
            label: 'Username',
            required: true,
          }),
          IntegrationField.Config({
            name: 'password',
            type: IntegrationFieldType.PASSWORD,
            label: 'Password',
            required: true,
          }),
          IntegrationField.Config({
            name: 'region',
            type: IntegrationFieldType.SELECT,
            label: 'Region',
            required: true,
            options: [
              {
                name: 'Europe',
                value: 'r1',
              },
              {
                name: 'North America',
                value: 'r2',
              },
              {
                name: 'Asia Pacific',
                value: 'r3',
              },
            ],
          }),
          IntegrationField.Config({
            name: 'address_book_id',
            type: IntegrationFieldType.TEXT,
            label: 'Address Book ID',
            required: false,
          }),
          IntegrationField.Config({
            name: 'consent_text',
            type: IntegrationFieldType.TEXT,
            label: 'Consent Text',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to dotdigital.',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
          IntegrationFeature.Config({
            type: 'FEATURE_TRIGGER_VENDOR_EMAIL_SEND',
            label: 'Trigger email campaign send',
            subtext: 'Trigger email campaign send within Dot Digital',
            fields: [
              IntegrationField.Config({
                name: 'campaign_id',
                type: IntegrationFieldType.TEXT,
                label: 'Campaign ID',
                required: true,
              }),
            ],
          }),
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward phone numbers',
            subtext: 'Forward any phone numbers collected by Attentive to Dot Digital',
            fields: [
              IntegrationField.Config({
                name: 'phone_custom_data_field_key',
                type: IntegrationFieldType.TEXT,
                label: 'Custom Data Field Name',
                required: true,
              }),
            ],
          }),
        ]}
      />
    );
  },
};
