import React, { FC } from 'react';

import { useMutation } from '@attentive/data/react-query';

import { useOAuthLinkMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFieldType, IntegrationSettingsRequest } from '../../types';
import { IntegrationField } from '../IntegrationField';

import { IntegrationForm } from './IntegrationForm';
import { SubmitButton } from './SubmitButton';

interface Props {
  superUserEnabled?: boolean;
  oAuthState?: string;
  oAuthKey?: string;
}

export const WooCommerceConnectForm: FC<Props> = ({ oAuthState, oAuthKey }) => {
  const generateOAuthRedirectMutation = useOAuthLinkMutation();
  const { vendorKey } = useIntegrationContext();

  const redirectToThirdPartyMutation = useMutation(
    async ({ payload }: { vendor: string; payload: IntegrationSettingsRequest }) => {
      const link = await generateOAuthRedirectMutation.mutateAsync({
        vendorOAuthKey: oAuthKey || vendorKey,
        oAuthState,
        fields: payload?.fields,
      });

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      window.location.assign(link!);
    }
  );

  const initialValues = {
    baseStoreUrl: '',
  };

  const validationConfig = {
    fields: {
      baseStoreUrl: { required: true },
    },
  };

  return (
    <IntegrationForm
      loadingText="Configuring"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={() => redirectToThirdPartyMutation}
      onComplete={() => {}}
    >
      <IntegrationField
        name="baseStoreUrl"
        type={IntegrationFieldType.TEXT}
        required={true}
        label="Base Store URL"
        placeholder="example: siren-denim.woocomerce.com"
        css={{ width: '100%' }}
      />
      <SubmitButton text="Set up integration" />
    </IntegrationForm>
  );
};
