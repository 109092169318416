import React from 'react';

import mailtrainLogo from '../assets/vendor_logos/mailtrain.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

export const vendorMailtrain: Vendor = {
  logo: mailtrainLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_MAILTRAIN}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listId',
            type: IntegrationFieldType.TEXT,
            label: 'List Id',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_MAILTRAIN}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listId',
            type: IntegrationFieldType.TEXT,
            label: 'List Id',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward emails to Mailtrain',
          }),
        ]}
      />
    );
  },
};
