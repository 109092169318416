import React from 'react';

import punchhLogo from '../assets/vendor_logos/punchh.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  IntegrationFieldType,
  IntegrationVisibilityType,
  Vendor,
  VendorCategory,
  VendorKey,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

const BASE_URL_SETTING_SUBTEXT =
  'e.g. https://hooks.punchh.com - Contact your Punchh implementation manager or CS team member for the base URL.';

export const vendorPunchh: Vendor = {
  logo: punchhLogo,
  category: VendorCategory.LOYALTY,
  companyWebsite: 'https://punchh.com/',
  infoPagesEnabled: true,
  learnSubtext: 'Reward customers with loyalty points when they sign up for SMS.',
  jointValueProp:
    'Encourage customers to opt-in to SMS by rewarding them with loyalty points in Punchh when they sign up for your text message program. Grow your subscriber list and boost customer engagement with timely and relevant promotions and offers.',
  whatYoullNeedItems: ['A Punchh account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description: 'Adds loyalty points to loyalty members who sign up for SMS marketing.',
    },
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description: "Create dynamic segments based on a customer's unique loyalty data.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/4407299182868',
      label: 'Punchh integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_PUNCHH}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'baseUrl',
            type: IntegrationFieldType.TEXT,
            label: 'Base URL',
            subtext: BASE_URL_SETTING_SUBTEXT,
            required: true,
          }),
          IntegrationField.Config({
            name: 'uuid',
            type: IntegrationFieldType.TEXT,
            label: 'Business UUID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.TEXT,
            label: 'Client ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'secret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Secret',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_PUNCHH}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'baseUrl',
            type: IntegrationFieldType.TEXT,
            label: 'Base URL',
            subtext: BASE_URL_SETTING_SUBTEXT,
            required: true,
          }),
          IntegrationField.Config({
            name: 'platformBaseUrl',
            type: IntegrationFieldType.TEXT,
            label: 'Platform Function API Base URL',
            subtext: 'Override the default Punchh Platform API Base URL.',
            placeholder: 'https://dashboard-api.punchh.com',
            required: false,
          }),
          IntegrationField.Config({
            name: 'uuid',
            type: IntegrationFieldType.TEXT,
            label: 'Business UUID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.TEXT,
            label: 'Client ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'secret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Secret',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'FEATURE_SYNC_LOYALTY_EVENTS',
            label: 'Loyalty Event Webhooks Synchronization',
            subtext: 'Loyalty Event Webhooks Synchronization',
            visibility: IntegrationVisibilityType.SUPER_USER,
            initiallyEnabled: false,
            fields: [
              IntegrationField.Config({
                name: 'apiKey',
                type: IntegrationFieldType.PASSWORD,
                label: 'API Key',
                subtext: 'Enter the API Key received from your Punchh representative',
                required: true,
              }),
              IntegrationField.Config({
                name: 'attentiveWebhooksBaseUrl',
                type: IntegrationFieldType.TEXT,
                label: 'Attentive Webhook Base URL',
                subtext:
                  'Enter this value into "Webhook Base URL" on your Punchh Webhooks Manager dashboard',
                placeholder: 'Will be generated upon saving',
                required: false,
                disabled: true,
                copyable: true,
              }),
              IntegrationField.Config({
                name: 'attentiveWebhooksEndpoint',
                type: IntegrationFieldType.TEXT,
                label: 'Attentive Webhook End Point',
                subtext:
                  'Enter this value into "Webhook End Point" on your Punchh Webhooks Manager dashboard',
                placeholder: 'Will be generated upon saving',
                required: false,
                disabled: true,
                copyable: true,
              }),
            ],
          }),
          IntegrationFeature.Config({
            type: 'FEATURE_SYNC_USER_PROFILES',
            label: 'Bulk User Profiles Synchronization',
            subtext: 'Upload existing Loyalty users for segmentation',
            visibility: IntegrationVisibilityType.SUPER_USER,
            initiallyEnabled: false,
            fields: [
              IntegrationField.Config({
                name: 's3SourceKeyPrefix',
                type: IntegrationFieldType.TEXT,
                label: 'Bulk User File Upload Location',
                subtext:
                  "Choose the location in Attentive's SFTP where you would like to upload your files. Remember to include your company folder name.",
                placeholder: 'company_name/uploads/punchh/users',
                required: true,
              }),
            ],
          }),
          IntegrationFeature.Config({
            type: 'FEATURE_ATTENTIVE_LOYALTY_SIGNUP',
            label: 'Capture Loyalty Sign-ups Through Attentive',
            subtext:
              'Capture Loyalty Sign-ups through Attentive Sign-up Units and Text-a-Keyword Journeys',
            visibility: IntegrationVisibilityType.SUPER_USER,
            initiallyEnabled: false,
            featureFlag: 'ENABLE_PUNCHH_ATTENTIVE_LOYALTY_SIGNUP',
          }),
        ]}
      />
    );
  },
};
