import React from 'react';

import salesforceServiceCloudLogo from '../assets/vendor_logos/salesforce-service-cloud.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

export const salesforceDataExtension: Vendor = {
  logo: salesforceServiceCloudLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.SALESFORCE_DATA_EXTENSION}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'externalKey',
            type: IntegrationFieldType.TEXT,
            label: 'External Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.TEXT,
            label: 'Client ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'subdomain',
            type: IntegrationFieldType.TEXT,
            label: 'Subdomain',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailField',
            type: IntegrationFieldType.TEXT,
            label: 'Email Field',
            required: true,
          }),
          IntegrationField.Config({
            name: 'sourceField',
            type: IntegrationFieldType.TEXT,
            label: 'Source Field',
            required: true,
          }),
          IntegrationField.Config({
            name: 'phoneField',
            type: IntegrationFieldType.TEXT,
            label: 'Phone Field',
            required: false,
          }),
          IntegrationField.Config({
            name: 'dateAddedField',
            type: IntegrationFieldType.TEXT,
            label: 'Date Added Field',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.SALESFORCE_DATA_EXTENSION}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'externalKey',
            type: IntegrationFieldType.TEXT,
            label: 'External Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.TEXT,
            label: 'Client ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'subdomain',
            type: IntegrationFieldType.TEXT,
            label: 'Subdomain',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailField',
            type: IntegrationFieldType.TEXT,
            label: 'Email Field',
            required: true,
          }),
          IntegrationField.Config({
            name: 'sourceField',
            type: IntegrationFieldType.TEXT,
            label: 'Source Field',
            required: true,
          }),
          IntegrationField.Config({
            name: 'phoneField',
            type: IntegrationFieldType.TEXT,
            label: 'Phone Field',
            required: false,
          }),
          IntegrationField.Config({
            name: 'dateAddedField',
            type: IntegrationFieldType.TEXT,
            label: 'Date Added Field',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Email Forwarding',
            subtext: 'Forward any emails collected by Attentive to Salesforce Data Extention.',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
          IntegrationFeature.Config({
            type: 'FEATURE_MARKETING_EMAIL_MESSAGE_SEND',
            label: 'Forward marketing email to PartyCity SFMC',
            subtext: 'Forward marketing email to PartyCity SFMC',
            fields: [
              IntegrationField.Config({
                name: 'products',
                type: IntegrationFieldType.TEXT,
                label: 'Products',
                required: false,
              }),
              IntegrationField.Config({
                name: 'relatedProducts',
                type: IntegrationFieldType.TEXT,
                label: 'Related Products',
                required: false,
              }),
              IntegrationField.Config({
                name: 'source',
                type: IntegrationFieldType.TEXT,
                label: 'Source',
                required: false,
              }),
              IntegrationField.Config({
                name: 'divisionId',
                type: IntegrationFieldType.TEXT,
                label: 'Division Id',
                required: false,
              }),
              IntegrationField.Config({
                name: 'imageId',
                type: IntegrationFieldType.TEXT,
                label: 'Image Id',
                required: false,
              }),
              IntegrationField.Config({
                name: 'subjectLine',
                type: IntegrationFieldType.TEXT,
                label: 'Subject Line',
                required: false,
              }),
              IntegrationField.Config({
                name: 'preheader',
                type: IntegrationFieldType.TEXT,
                label: 'Pre Header',
                required: false,
              }),
              IntegrationField.Config({
                name: 'kazaamId',
                type: IntegrationFieldType.TEXT,
                label: 'Kazaam Id',
                required: false,
              }),
              IntegrationField.Config({
                name: 'discountCode',
                type: IntegrationFieldType.TEXT,
                label: 'Discount Code',
                required: false,
              }),
              IntegrationField.Config({
                name: 'deClientId',
                type: IntegrationFieldType.TEXT,
                label: 'DE Client Id',
                required: false,
              }),
              IntegrationField.Config({
                name: 'deClientSecret',
                type: IntegrationFieldType.TEXT,
                label: 'DE Secret',
                required: false,
              }),
            ],
          }),
        ]}
      />
    );
  },
};
