import React from 'react';

import brontoLogo from '../assets/vendor_logos/bronto.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
  CreativeSpecificDataKeyNames,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const bronto: Vendor = {
  logo: brontoLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://bronto.com/',
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext: 'Grow your email list through Attentive sign-up forms and sync contacts to Bronto.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Bronto so that you can start sending marketing emails immediately. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A Bronto account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        "Automatically sync subscriber's emails with Bronto so that you can start sending marketing emails immediately.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360041853571',
      label: 'Bronto integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.BRONTO}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'Access Token',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listIds',
            type: IntegrationFieldType.TEXT,
            label: 'List IDs (comma separated)',
            required: false,
          }),
          IntegrationField.Config({
            name: 'sourceApikey',
            type: IntegrationFieldType.TEXT,
            label: 'Signup Type Field ID',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.BRONTO}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'Access Token',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listIds',
            type: IntegrationFieldType.TEXT,
            label: 'List IDs (comma separated)',
            required: false,
          }),
          IntegrationField.Config({
            name: 'sourceApikey',
            type: IntegrationFieldType.TEXT,
            label: 'Signup Type Field ID',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Bronto.',
          }),
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward phone numbers',
            subtext: 'Forward any phone numbers collected by Attentive to Bronto.',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
          IntegrationFeature.Config({
            type: 'FEATURE_EMAIL_OPT_OUT_SYNC',
            label: 'Email Opt Out Syncronization',
            subtext: 'Email Opt Out Syncronization',
            featureFlag: 'ENABLE_TRIGGERED_EMAIL',
            visibility: IntegrationVisibilityType.SUPER_USER,
            fields: [
              IntegrationField.Config({
                name: 'optOutListId',
                type: IntegrationFieldType.TEXT,
                label: 'Opt Out List Id',
                required: true,
              }),
            ],
          }),
        ]}
      />
    );
  },
  creativeSpecificSources: { type: 'compact', key: CreativeSpecificDataKeyNames.SOURCES },
};
