import React from 'react';
import { useFragment } from 'react-relay';

import {
  useLegacyInboundWebhookServiceBaseUrl,
  useCentralizedInboundWebhookServiceBaseUrl,
} from '@attentive/acore-utils';
import { validateRelayObject } from '@attentive/data';
import { Box, Button, TextInput } from '@attentive/picnic';

import {
  useIntegrationsCommonQuery,
  IntegrationsCommonQueryBody,
} from '../../IntegrationsCommonPresenter';
import { FormField } from '../FormField';

import IntegrationsCommonWebhookCallbackInstructionsFragment_query, {
  IntegrationsCommonWebhookCallbackInstructionsFragment_query$key,
} from './__generated__/IntegrationsCommonWebhookCallbackInstructionsFragment_query.graphql';

type Props = {
  path: string;
  subtext: string;
  useCentralizedInboundWebhookUrl: boolean;
};

const WebhookCallbackUrlComponent: React.FC<Props> = ({
  path,
  subtext: webhookUrlFieldSubtext,
  useCentralizedInboundWebhookUrl: useCentralizedInboundWebhookUrl,
}) => {
  const [copied, setCopied] = React.useState<boolean>(false);

  const queryData = useIntegrationsCommonQuery();
  const fragData = useFragment<IntegrationsCommonWebhookCallbackInstructionsFragment_query$key>(
    IntegrationsCommonWebhookCallbackInstructionsFragment_query,
    queryData
  );
  const { externalId } = validateRelayObject(fragData.company);
  const webhookUrl = `${useLegacyInboundWebhookServiceBaseUrl()}${path}/${externalId}`;
  const centralizedInboundWebhookUrl = `${useCentralizedInboundWebhookServiceBaseUrl()}${path}&externalCompanyId=${externalId}`;

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(webhookUrl);
    setCopied(true);
  };

  return (
    <Box css={{ mt: '$space6' }}>
      <FormField
        label="Webhook Destination URL"
        subtext={webhookUrlFieldSubtext}
        required={false}
        hideOptionalLabel={true}
      >
        <Box css={{ display: 'flex' }}>
          <TextInput
            value={useCentralizedInboundWebhookUrl ? centralizedInboundWebhookUrl : webhookUrl}
            type="text"
            readOnly
            css={{
              opacity: '0.5',
              background: '$grayscale100',
              borderColor: '$grayscale800',
              color: '$grayscale900',
            }}
          />
          <Button
            variant="basic"
            css={{
              ml: '$space4',
              px: '$space8',
              ...(copied ? { borderColor: '$green800', color: '$green800' } : {}),
            }}
            onClick={() => copyToClipboard()}
          >
            {copied ? 'Copied!' : 'Copy'}
          </Button>
        </Box>
      </FormField>
    </Box>
  );
};

export const WebhookCallbackUrl: React.FC<Props> = (props) => {
  return (
    <IntegrationsCommonQueryBody>
      <WebhookCallbackUrlComponent {...props} />
    </IntegrationsCommonQueryBody>
  );
};
