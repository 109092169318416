/**
 * @generated SignedSource<<9fe99214a895a750e58d6afda595eabd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FacebookAdsFeatureSettingsFormQuery$variables = {
  adAccountId: string;
  companyId: string;
};
export type FacebookAdsFeatureSettingsFormQuery$data = {
  readonly company: {
    readonly companyVendorIntegrations?: ReadonlyArray<{
      readonly externalVendorData: {
        readonly data: {
          readonly " $fragmentSpreads": FragmentRefs<"MapSegmentToFBAudienceFragment_facebookAdsExternalVendorData">;
        };
      } | null;
    }>;
    readonly segments?: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
        };
      }>;
    } | null;
  };
};
export type FacebookAdsFeatureSettingsFormQuery = {
  response: FacebookAdsFeatureSettingsFormQuery$data;
  variables: FacebookAdsFeatureSettingsFormQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "adAccountId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "companyId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "companyId"
  }
],
v3 = [
  {
    "kind": "Literal",
    "name": "status",
    "value": "ENABLED"
  },
  {
    "kind": "Literal",
    "name": "vendor",
    "value": "VENDOR_FACEBOOK_ADS"
  }
],
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "adAccountId",
        "variableName": "adAccountId"
      }
    ],
    "kind": "ObjectValue",
    "name": "facebookFilter"
  },
  {
    "kind": "Literal",
    "name": "fields",
    "value": [
      "EXTERNAL_DATA_FIELD_FACEBOOK_ADS_CUSTOM_AUDIENCES"
    ]
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 1
    }
  ],
  "concreteType": "SegmentConnection",
  "kind": "LinkedField",
  "name": "segments",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SegmentEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Segment",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v5/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "segments(first:1)"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FacebookAdsFeatureSettingsFormQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": "company",
          "args": (v2/*: any*/),
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "kind": "RequiredField",
                  "field": {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "CompanyVendorIntegration",
                    "kind": "LinkedField",
                    "name": "companyVendorIntegrations",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v4/*: any*/),
                        "concreteType": "ExternalVendorDataWrapper",
                        "kind": "LinkedField",
                        "name": "externalVendorData",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "MapSegmentToFBAudienceFragment_facebookAdsExternalVendorData"
                                  }
                                ],
                                "type": "FacebookAdsExternalVendorData",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "companyVendorIntegrations(status:\"ENABLED\",vendor:\"VENDOR_FACEBOOK_ADS\")"
                  },
                  "action": "THROW",
                  "path": "company.companyVendorIntegrations"
                },
                (v6/*: any*/)
              ],
              "type": "Company",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "company"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "FacebookAdsFeatureSettingsFormQuery",
    "selections": [
      {
        "alias": "company",
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v7/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "CompanyVendorIntegration",
                "kind": "LinkedField",
                "name": "companyVendorIntegrations",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "ExternalVendorDataWrapper",
                    "kind": "LinkedField",
                    "name": "externalVendorData",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "data",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "FacebookCustomAudience",
                                "kind": "LinkedField",
                                "name": "customAudiences",
                                "plural": true,
                                "selections": [
                                  (v5/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "FacebookAdsExternalVendorData",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": "companyVendorIntegrations(status:\"ENABLED\",vendor:\"VENDOR_FACEBOOK_ADS\")"
              },
              (v6/*: any*/)
            ],
            "type": "Company",
            "abstractKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d4ae1d6e538c8c0b1facdee1f92d2c25",
    "id": null,
    "metadata": {},
    "name": "FacebookAdsFeatureSettingsFormQuery",
    "operationKind": "query",
    "text": "query FacebookAdsFeatureSettingsFormQuery(\n  $companyId: ID!\n  $adAccountId: String!\n) {\n  company: node(id: $companyId) {\n    __typename\n    ... on Company {\n      companyVendorIntegrations(status: ENABLED, vendor: \"VENDOR_FACEBOOK_ADS\") {\n        externalVendorData(fields: [EXTERNAL_DATA_FIELD_FACEBOOK_ADS_CUSTOM_AUDIENCES], facebookFilter: {adAccountId: $adAccountId}) {\n          data {\n            __typename\n            ... on FacebookAdsExternalVendorData {\n              ...MapSegmentToFBAudienceFragment_facebookAdsExternalVendorData\n            }\n          }\n        }\n        id\n      }\n      segments(first: 1) {\n        edges {\n          node {\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment MapSegmentToFBAudienceFragment_facebookAdsExternalVendorData on FacebookAdsExternalVendorData {\n  customAudiences {\n    id\n    name\n  }\n}\n"
  }
};
})();

(node as any).hash = "5689aa5c2677144213aaaefbb4411dba";

export default node;
