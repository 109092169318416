import React from 'react';

import zetaglobalLogo from '../assets/vendor_logos/zetaglobal.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupConnectAndConfigure } from '../components/SetupConnectAndConfigure';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorZetaGlobal: Vendor = {
  logo: zetaglobalLogo,
  category: VendorCategory.CUSTOMER_DATA_PLATFORM,
  categories: [
    { name: VendorCategory.CUSTOMER_DATA_PLATFORM, primary: true },
    { name: VendorCategory.ANALYTICS, primary: false },
  ],
  companyWebsite: 'https://zetaglobal.com/',
  infoPagesEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contact data to Zeta Global.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Zeta Global and can trigger a welcome email to new email marketing subscribers. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A Zeta Global account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description: "Sync subscriber's emails automatically to Zeta Global.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/4405033709204',
      label: 'Zeta Global integration guide',
    },
  ],
  setupComponent(onComplete, superUserEnabled) {
    return (
      <SetupConnectAndConfigure
        vendorKey={VendorKey.VENDOR_ZETA_GLOBAL}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        connectFields={[
          IntegrationField.Config({
            name: 'site_id',
            type: IntegrationFieldType.TEXT,
            label: 'Site Id',
            subtext: 'The ZMP Site ID provided by Zeta',
            required: true,
          }),
          IntegrationField.Config({
            name: 'api_key',
            type: IntegrationFieldType.PASSWORD,
            label: 'REST API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'source',
            type: IntegrationFieldType.TEXT,
            label: 'Source',
            subtext: 'Source name for forwarded identity events',
            placeholder: 'Attentive',
            required: true,
          }),
        ]}
        configureFeatures={[
          IntegrationFeature.Config({
            type: 'FEATURE_TRIGGER_VENDOR_EMAIL_SEND',
            label: 'Enable Track Events',
            subtext: 'Forward track events to Zeta Global',
            initiallyEnabled: true,
            fields: [
              IntegrationField.Config({
                name: 'event',
                type: IntegrationFieldType.TEXT,
                label: 'Track event name',
                subtext:
                  'Name of the activity. You can refer to this name when searching for activities or in the message',
                placeholder: 'attentive_signup',
                required: true,
              }),
            ],
          }),
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward phones',
            subtext: 'Forward any phones collected by Attentive to Zeta Global',
            initiallyEnabled: true,
          }),
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Zeta Global',
            initiallyEnabled: true,
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_ZETA_GLOBAL}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'site_id',
            type: IntegrationFieldType.TEXT,
            label: 'Site Id',
            subtext: 'The ZMP Site ID provided by Zeta',
            required: true,
          }),
          IntegrationField.Config({
            name: 'api_key',
            type: IntegrationFieldType.PASSWORD,
            label: 'REST API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'source',
            type: IntegrationFieldType.TEXT,
            label: 'Source',
            subtext: 'Source name for forwarded identity events',
            placeholder: 'Attentive',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'FEATURE_TRIGGER_VENDOR_EMAIL_SEND',
            label: 'Enable Track Events',
            subtext: 'Forward track events to Zeta Global',
            fields: [
              IntegrationField.Config({
                name: 'event',
                type: IntegrationFieldType.TEXT,
                label: 'Track event name',
                subtext:
                  'Name of the activity. You can refer to this name when searching for activities or in the message',
                placeholder: 'attentive_signup',
                required: true,
              }),
            ],
          }),
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward phones',
            subtext: 'Forward any phones collected by Attentive to Zeta Global',
          }),
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Zeta Global',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
        ]}
      />
    );
  },
};
