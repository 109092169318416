import React from 'react';

import placeholderLogo from '../assets/vendor_logos/placeholder.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

export const vendorPaytronix: Vendor = {
  category: VendorCategory.LOYALTY,
  logo: placeholderLogo,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_PAYTRONIX}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.TEXT,
            label: 'Client ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'merchantId',
            type: IntegrationFieldType.TEXT,
            label: 'Merchant ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'domain',
            type: IntegrationFieldType.TEXT,
            label: 'API Domain',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_PAYTRONIX}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.TEXT,
            label: 'Client ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'merchantId',
            type: IntegrationFieldType.TEXT,
            label: 'Merchant ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'domain',
            type: IntegrationFieldType.TEXT,
            label: 'API Domain',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward phones',
            subtext: 'Forward any phone numbers collected by Attentive to Paytronix',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
          IntegrationFeature.Config({
            type: 'FEATURE_POST_SUBSCRIBER_OPT_OUT',
            label: 'Forward opt-outs',
            subtext: 'Forward any opt outs to Paytronix',
          }),
        ]}
      />
    );
  },
};
