import pick from 'lodash/pick';
import React, { FC } from 'react';

import { Box } from '@attentive/picnic';

import { useUpdateIntegrationSettingsMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFormFeatureValues, IntegrationFieldType } from '../../types';
import { IntegrationFeature } from '../IntegrationFeature';
import { IntegrationField } from '../IntegrationField';
import { OloInstructions } from '../OloInstructions';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

interface Props {
  onComplete: () => void;
}

export const OloSettingsForm: FC<Props> = ({ onComplete }) => {
  const { integrationDetailValues } = useIntegrationContext();

  const validationConfig = {
    fields: {
      sharedSecret: { required: true },
    },
  };

  // pick out only the fields/features that this form actually shows from
  // the current state of what's in the backend. This ensures we only submit
  // the values that the form actually shows inputs for.
  const initialValues = pick(integrationDetailValues || {}, [
    'sharedSecret',
    'clientId',
    'clientSecret',
    'SYNC_PRODUCTS',
  ]) as {
    [type: string]: IntegrationFormFeatureValues;
  };

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useUpdateIntegrationSettingsMutation}
      onComplete={onComplete}
    >
      <Box css={{ width: '100%' }}>
        <OloInstructions alreadyConfigured={true} />
      </Box>

      <IntegrationField
        name="sharedSecret"
        type={IntegrationFieldType.PASSWORD}
        label="Shared Secret"
        required={true}
        css={{ width: '100%' }}
      />
      <IntegrationField
        name="clientId"
        type={IntegrationFieldType.TEXT}
        label="Client ID"
        required={false}
        css={{ width: '100%' }}
      />
      <IntegrationField
        name="clientSecret"
        type={IntegrationFieldType.PASSWORD}
        label="Client Secret"
        required={false}
        css={{ width: '100%' }}
      />

      <IntegrationFeature
        type="SYNC_PRODUCTS"
        label="Sync Olo Product Data"
        subtext="Create targeted segments based on your Olo product data."
        featureFlag="ENABLE_OLO_SYNC_PRODUCTS"
        css={{ width: '100%' }}
      />

      <SubmitButton text="Save changes" />
    </IntegrationForm>
  );
};
