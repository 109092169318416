import React from 'react';

import cheetahmailLogo from '../assets/vendor_logos/cheetahmail.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const cheetahmail: Vendor = {
  logo: cheetahmailLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://www.cheetahdigital.com/',
  infoPagesEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contacts to Cheetah Digital.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Cheetah Digital and can trigger a welcome email to new email marketing subscribers. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A Cheetah Digital account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        "Automatically sync subscriber's emails with Cheetah Digital so that you can start sending marketing emails immediately.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/4408521371156',
      label: 'Cheetah Digital integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.CHEETAHMAIL}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiPostId',
            type: IntegrationFieldType.TEXT,
            label: 'API Post Id',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'sourceName',
            type: IntegrationFieldType.TEXT,
            label: 'Source Name',
            subtext: 'Name of the source label. Defaults to attentive_source.',
            placeholder: 'attentive_source',
            required: false,
          }),
          IntegrationField.Config({
            name: 'eventDateName',
            type: IntegrationFieldType.TEXT,
            label: 'Event Date Name ',
            subtext: 'Name of the event date field label when sending event.',
            placeholder: 'signup_date',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.CHEETAHMAIL}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiPostId',
            type: IntegrationFieldType.TEXT,
            label: 'API Post Id',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'sourceName',
            type: IntegrationFieldType.TEXT,
            label: 'Source Name',
            subtext: 'Name of the source label. Defaults to attentive_source.',
            placeholder: 'attentive_source',
            required: false,
          }),
          IntegrationField.Config({
            name: 'eventDateName',
            type: IntegrationFieldType.TEXT,
            label: 'Event Date Name ',
            subtext: 'Name of the event date field label when sending event.',
            placeholder: 'signup_date',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Cheetah Digital.',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward phone',
            subtext: 'Forward any phones collected by Attentive to Cheetah Digital',
          }),
          IntegrationFeature.Config({
            type: 'FEATURE_POST_SUBSCRIBER_OPT_OUT',
            label: 'Forward opt-outs',
            subtext: 'Forward any opt outs to Cheetahmail',
            fields: [
              IntegrationField.Config({
                name: 'optOutStatusName',
                type: IntegrationFieldType.TEXT,
                label: 'Subscription Opt Out Status Name',
                subtext: 'Name of the subscription opt out event label.',
                placeholder: 'attentive_status',
                required: true,
              }),
              IntegrationField.Config({
                name: 'optOutStatusValue',
                type: IntegrationFieldType.TEXT,
                label: 'Subscription Opt Out Status Value',
                subtext: 'Value of the subscription opt out event',
                placeholder: 'unsubscribed',
                required: true,
              }),
            ],
          }),
        ]}
      />
    );
  },
};
