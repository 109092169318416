import React from 'react';

import zohoLogo from '../assets/vendor_logos/zoho.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

export const vendorZoho: Vendor = {
  logo: zohoLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_ZOHO}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'moduleApiName',
            type: IntegrationFieldType.TEXT,
            label: 'Module API Name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.TEXT,
            label: 'Client ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.TEXT,
            label: 'Client Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'refreshToken',
            type: IntegrationFieldType.TEXT,
            label: 'Refresh Token',
            required: true,
          }),
          IntegrationField.Config({
            name: 'accountUrl',
            type: IntegrationFieldType.TEXT,
            label: 'Account URL',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_ZOHO}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'moduleApiName',
            type: IntegrationFieldType.TEXT,
            label: 'Module API Name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.TEXT,
            label: 'Client ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.TEXT,
            label: 'Client Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'refreshToken',
            type: IntegrationFieldType.TEXT,
            label: 'Refresh Token',
            required: true,
          }),
          IntegrationField.Config({
            name: 'accountUrl',
            type: IntegrationFieldType.TEXT,
            label: 'Account URL',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward emails to Zoho ESP',
          }),
        ]}
      />
    );
  },
};
