import _useSetMilestoneMutation from "./__generated__/useSetMilestoneMutation.graphql";
import { useCallback } from 'react';
import { useMutation } from 'react-relay';
import { useCurrentCompanyId } from '@attentive/acore-utils';
import { graphql } from '@attentive/data';
import { MilestoneKey } from '@attentive/data/types';
import { logError } from '../utils/logger';
import SetMilestonesMutation from './__generated__/useSetMilestoneMutation.graphql';
_useSetMilestoneMutation;

const useSetMilestone = () => {
  const companyId = useCurrentCompanyId();
  const [completeOnboardingMilestones] = useMutation(SetMilestonesMutation);
  return useCallback(milestoneKeys => {
    if (!milestoneKeys.length) return;
    completeOnboardingMilestones({
      variables: {
        input: {
          companyId,
          milestoneKeys
        }
      },
      onCompleted: (_, errors) => {
        if (!errors) return;
        logError(new Error(errors[0].message));
      },
      onError: logError
    });
  }, [companyId, completeOnboardingMilestones]);
};

export { MilestoneKey };
export default useSetMilestone;