import pick from 'lodash/pick';
import React, { FC } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { Button } from '@attentive/picnic';

import { useUpdateIntegrationSettingsMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFormFeatureValues, VendorKey } from '../../types';
import { IntegrationFeature } from '../IntegrationFeature';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

import { CreateUserAttributesFeature } from './CreateUserAttributesFeature';

interface Props {
  onComplete: () => void;
  superUserEnabled?: boolean;
}

export const SalesforceSettingsForm: FC<Props> = ({ onComplete, superUserEnabled }) => {
  const { integrationDetailValues } = useIntegrationContext();

  // pick out only the fields/features that this form actually shows from
  // the current state of what's in the backend. This ensures we only submit
  // the values that the form actually shows inputs for.
  const initialValues = pick(integrationDetailValues || {}, [
    'POSTBACK_EMAILS',
    'POST_WITH_PHONE',
    'FEATURE_POSTBACK_USER_DATA',
    'FEATURE_POSTBACK_USER_ATTRIBUTES',
  ]) as { [type: string]: IntegrationFormFeatureValues };

  const isSFMCEnhancementsEnabled = useCompanyFeatureFlag('ENABLE_SFMC_POSTBACK_ENHANCEMENTS');

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      mutation={useUpdateIntegrationSettingsMutation}
      onComplete={onComplete}
    >
      {superUserEnabled && (
        <IntegrationFeature
          type="POSTBACK_EMAILS"
          label="Email Forwarding"
          subtext="Forward any emails collected by Attentive to Salesforce."
        />
      )}

      <IntegrationFeature
        type="POST_WITH_PHONE"
        label="Phone Forwarding"
        subtext="Forward phone numbers to Salesforce"
      />

      <IntegrationFeature
        type="FEATURE_POSTBACK_USER_DATA"
        label="Message Link Click Forwarding"
        subtext="Forward message link click events to Salesforce"
      />

      {isSFMCEnhancementsEnabled && <CreateUserAttributesFeature vendor={VendorKey.SALESFORCE} />}

      <Button as="a" variant="basic" href="/tactical/salesforce-marketing-cloud">
        Configure Integration
      </Button>

      <SubmitButton text="Save changes" />
    </IntegrationForm>
  );
};
