import React from 'react';

import activecampaignLogo from '../assets/vendor_logos/activecampaign.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorActivecampaign: Vendor = {
  logo: activecampaignLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://www.activecampaign.com/',
  infoPagesEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contacts to ActiveCampaign.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to ActiveCampaign so that you can start sending marketing emails immediately. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['An ActiveCampaign account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        "Automatically sync subscriber's emails with ActiveCampaign so that you can start sending marketing emails immediately.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/4404977720468',
      label: 'ActiveCampaign integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_ACTIVECAMPAIGN}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.TEXT,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'accountName',
            type: IntegrationFieldType.TEXT,
            label: 'Account Name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listId',
            type: IntegrationFieldType.TEXT,
            label: 'List ID',
            subtext: 'Numeric List ID associated with the List Name',
            required: false,
          }),
          IntegrationField.Config({
            name: 'timeStampFieldKey',
            type: IntegrationFieldType.TEXT,
            label: 'Timestamp ID',
            subtext: 'Numeric ID associated with the custom timestamp field',
            required: false,
          }),
          IntegrationField.Config({
            name: 'tag',
            type: IntegrationFieldType.TEXT,
            label: 'Tag ID',
            subtext: 'Numeric Tag ID associated with the tag',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_ACTIVECAMPAIGN}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.TEXT,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'accountName',
            type: IntegrationFieldType.TEXT,
            label: 'Account Name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listId',
            type: IntegrationFieldType.TEXT,
            label: 'List ID',
            subtext: 'Numeric List ID associated with the List Name',
            required: false,
          }),
          IntegrationField.Config({
            name: 'timeStampFieldKey',
            type: IntegrationFieldType.TEXT,
            label: 'Timestamp ID',
            subtext: 'Numeric ID associated with the custom timestamp field',
            required: false,
          }),
          IntegrationField.Config({
            name: 'tag',
            type: IntegrationFieldType.TEXT,
            label: 'Tag ID',
            subtext: 'Numeric Tag ID associated with the tag',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward Phone Number',
            subtext:
              'Forward any phone numbers collected by Attentive to ActiveCampaign using a custom field.',
          }),
        ]}
      />
    );
  },
};
