import pick from 'lodash/pick';
import React, { FC } from 'react';

import { useUpdateIntegrationSettingsMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFormFeatureValues, IntegrationFieldType } from '../../types';
import { GenerateToken } from '../GenerateToken';
import { IntegrationFeature } from '../IntegrationFeature';
import { IntegrationField } from '../IntegrationField';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

interface Props {
  onComplete: () => void;
}

export const RichpanelSettingsForm: FC<Props> = ({ onComplete }) => {
  const { integrationDetailValues } = useIntegrationContext();

  const validationConfig = {
    fields: {
      token: { required: true },
      clientId: { required: true },
      sendMarketingMessage: { required: true },
      bufferTime: { required: false },
    },
    features: {
      CUSTOMER_SERVICE_OPT_IN: {
        serviceVerificationKeyword: { required: true },
        serviceBeginMessage: { required: true },
      },
    },
  };

  // pick out only the fields/features that this form actually shows from
  // the current state of what's in the backend. This ensures we only submit
  // the values that the form actually shows inputs for.
  const initialValues = pick(integrationDetailValues || {}, [
    'token',
    'clientId',
    'sendMarketingMessage',
    'bufferTime',
    'CUSTOMER_SERVICE_OPT_IN',
  ]) as {
    [type: string]: IntegrationFormFeatureValues;
  };

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useUpdateIntegrationSettingsMutation}
      onComplete={onComplete}
    >
      <IntegrationField
        name="token"
        type={IntegrationFieldType.PASSWORD}
        label="API Token"
        required={true}
        css={{ width: '100%' }}
      />
      <IntegrationField
        name="clientId"
        type={IntegrationFieldType.TEXT}
        label="Client Id"
        required={true}
        css={{ width: '100%' }}
      />
      <IntegrationField
        name="sendMarketingMessage"
        type={IntegrationFieldType.SELECT}
        label="Additional Context Message"
        subtext="If Enabled, an additional context message will be sent to Richpanel along with the customer message. If Disabled, only the customer message will be sent."
        required={true}
        css={{ width: '100%' }}
        options={[
          {
            name: 'Enabled',
            value: 'true',
          },
          {
            name: 'Disabled',
            value: 'false',
          },
        ]}
      />
      <IntegrationField
        name="bufferTime"
        type={IntegrationFieldType.TEXT}
        label="Context Message Window"
        subtext="Set the number of hours to wait for an Additional Context Message. If blank, default will be 2 hours."
        required={false}
        css={{ width: '100%' }}
      />

      <IntegrationFeature
        type="CUSTOMER_SERVICE_OPT_IN"
        label="Support Confirmation"
        subtext="Turn on Support Confirmation to allow customers to escalate their text message conversation to your customer support team."
        css={{ width: '100%' }}
        fields={[
          IntegrationField.Config({
            name: 'serviceVerificationKeyword',
            type: IntegrationFieldType.TEXT,
            label: 'Support Confirmation Keyword',
            subtext:
              'Choose a keyword your customers can use to confirm they need assistance. Your default autoresponder must include this keyword.',
            placeholder: 'Support',
            required: true,
          }),
          IntegrationField.Config({
            name: 'serviceBeginMessage',
            type: IntegrationFieldType.MULTILINE,
            label: 'Acknowledgement Message',
            subtext:
              'Your customers will receive this message after replying with the Support Confirmation keyword.',
            placeholder:
              'Your request has been forwarded to our support team. We will get back to you as soon as possible during our business hours.',
            required: true,
          }),
          IntegrationField.Config({
            name: 'serviceEndMessage',
            type: IntegrationFieldType.MULTILINE,
            label: 'Resolution Message ',
            subtext:
              'Your customers will receive this message once the support ticket has been resolved.',
            placeholder:
              'Your request has been resolved. If you need anything else, do not hesitate to reach out.',
            required: false,
          }),
        ]}
      />

      <GenerateToken
        heading="Generate Attentive Webhooks"
        description="Generates webhook urls to save in Richpanel and complete the integration setup."
        buttonText="Generate Urls"
        generateTokenVendorSlug="richpanel"
      />

      <SubmitButton text="Save changes" />
    </IntegrationForm>
  );
};
