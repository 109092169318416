import React from 'react';

import listrakLogo from '../assets/vendor_logos/listrak.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const listrak: Vendor = {
  logo: listrakLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://www.listrak.com/',
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext: 'Grow your email list through Attentive sign-up forms, sync contacts to Listrak.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Listrak and can trigger a welcome email to new email marketing subscribers. You can also sync subscriber coupon codes to Listrak. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A Listrak account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description: 'Pass email addresses collected through the Attentive sign-up unit to Listrak.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360041419072',
      label: 'Listrak integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.LISTRAK}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.TEXT,
            label: 'Client ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listId',
            type: IntegrationFieldType.TEXT,
            label: 'List ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'phoneListId',
            type: IntegrationFieldType.TEXT,
            label: 'Phone List ID',
            required: false,
          }),
          IntegrationField.Config({
            name: 'sourceListId',
            type: IntegrationFieldType.TEXT,
            label: 'Source List ID',
            required: false,
          }),
          IntegrationField.Config({
            name: 'externalEventId',
            type: IntegrationFieldType.TEXT,
            label: 'External Event ID',
            required: false,
          }),
          IntegrationField.Config({
            name: 'couponListId',
            type: IntegrationFieldType.TEXT,
            label: 'Coupon List ID',
            required: false,
          }),
          IntegrationField.Config({
            name: 'customEmailSaveAttributes',
            type: IntegrationFieldType.TEXT,
            label: 'Custom Email Save JSON',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.LISTRAK}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.TEXT,
            label: 'Client ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'Client Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listId',
            type: IntegrationFieldType.TEXT,
            label: 'List ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'phoneListId',
            type: IntegrationFieldType.TEXT,
            label: 'Phone List ID',
            required: false,
          }),
          IntegrationField.Config({
            name: 'sourceListId',
            type: IntegrationFieldType.TEXT,
            label: 'Source List ID',
            required: false,
          }),
          IntegrationField.Config({
            name: 'externalEventId',
            type: IntegrationFieldType.TEXT,
            label: 'External Event ID',
            required: false,
          }),
          IntegrationField.Config({
            name: 'couponListId',
            type: IntegrationFieldType.TEXT,
            label: 'Coupon List ID',
            required: false,
          }),
          IntegrationField.Config({
            name: 'customEmailSaveAttributes',
            type: IntegrationFieldType.TEXT,
            label: 'Custom Email Save JSON',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Listrak.',
          }),
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward phone numbers',
            subtext: 'Forward any phone number collected by Attentive to Listrak.',
          }),
          IntegrationFeature.Config({
            type: 'FEATURE_EMAIL_OPT_OUT_SYNC',
            label: 'Email Opt Out Syncronization',
            subtext: 'Email Opt Out Syncronization',
            featureFlag: 'ENABLE_TRIGGERED_EMAIL',
            visibility: IntegrationVisibilityType.SUPER_USER,
            fields: [
              IntegrationField.Config({
                name: 'optOutListId',
                type: IntegrationFieldType.TEXT,
                label: 'Opt Out List Id',
                required: true,
              }),
            ],
          }),
        ]}
      />
    );
  },
};
