import React, { PropsWithChildren } from 'react';

import { Icon, Box } from '@attentive/picnic';

export const TimeEstimation: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Box
      css={{
        ml: 'auto',
        color: '$textSubdued',
        flexDirection: 'column',
        display: 'flex',
        alignItems: 'center',
        flexShrink: 0,
        '@bp1': {
          flexDirection: 'row',
        },
      }}
    >
      <Icon name="Timer" color="subdued" css={{ '@bp1': { mr: '$space1' } }} /> {children}
    </Box>
  );
};
