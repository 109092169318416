import React from 'react';

import campaignMonitorLogo from '../assets/vendor_logos/campaign_monitor.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorCampaignMonitor: Vendor = {
  logo: campaignMonitorLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://www.campaignmonitor.com/',
  infoPagesEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contacts to Campaign Monitor.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Campaign Monitor so that you can start sending marketing emails immediately. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A Campaign Monitor account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        "Automatically sync subscriber's emails with Campaign Monitor so that you can start sending marketing emails immediately.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/4404993344404',
      label: 'Campaign Monitor integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_CAMPAIGN_MONITOR}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listId',
            type: IntegrationFieldType.TEXT,
            label: 'List ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'restartSubscription',
            type: IntegrationFieldType.SELECT,
            label: 'Restart Subscription Based Autoresponders',
            placeholder: 'true',
            required: true,
            options: [
              {
                name: 'True',
                value: 'true',
              },
              {
                name: 'False',
                value: 'false',
              },
            ],
          }),
          IntegrationField.Config({
            name: 'consentToTrack',
            type: IntegrationFieldType.SELECT,
            label: 'Consent To Track',
            placeholder: 'Yes',
            required: true,
            options: [
              {
                name: 'Yes',
                value: 'Yes',
              },
              {
                name: 'No',
                value: 'No',
              },
              {
                name: 'Unchanged',
                value: 'Unchanged',
              },
            ],
          }),
          IntegrationField.Config({
            name: 'name',
            type: IntegrationFieldType.TEXT,
            label: 'Name',
            placeholder: 'New Subscriber',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_CAMPAIGN_MONITOR}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listId',
            type: IntegrationFieldType.TEXT,
            label: 'List ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'restartSubscription',
            type: IntegrationFieldType.SELECT,
            label: 'Restart Subscription Based Autoresponders',
            placeholder: 'true',
            required: true,
            options: [
              {
                name: 'True',
                value: 'true',
              },
              {
                name: 'False',
                value: 'false',
              },
            ],
          }),
          IntegrationField.Config({
            name: 'consentToTrack',
            type: IntegrationFieldType.SELECT,
            label: 'Consent To Track',
            placeholder: 'Yes',
            required: true,
            options: [
              {
                name: 'Yes',
                value: 'Yes',
              },
              {
                name: 'No',
                value: 'No',
              },
              {
                name: 'Unchanged',
                value: 'Unchanged',
              },
            ],
          }),
          IntegrationField.Config({
            name: 'name',
            type: IntegrationFieldType.TEXT,
            label: 'Name',
            placeholder: 'New Subscriber',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward Phone Number',
            subtext:
              'Forward any phone numbers collected by Attentive to Campaign Monitor using a custom field.',
            fields: [
              IntegrationField.Config({
                name: 'phoneKey',
                type: IntegrationFieldType.TEXT,
                label: 'Custom Phone Field Name',
                subtext: 'Sends field as a custom key field back to Campaign Monitor',
                placeholder: 'phone',
                required: true,
              }),
            ],
          }),
        ]}
      />
    );
  },
};
