import React from 'react';

import { Box, Button, Link, Separator, Text, Heading, Icon } from '@attentive/picnic';

import { WebhookCallbackUrl } from '../WebhookCallbackUrl';

type Props = {
  alreadyConfigured: boolean;
};

export const OloInstructions: React.FC<Props> = ({ alreadyConfigured }) => {
  const [showInstructions, setShowInstructions] = React.useState(!alreadyConfigured);
  return (
    <>
      {alreadyConfigured || (
        <Heading variant="md" css={{ mb: '$space2' }}>
          Instructions
        </Heading>
      )}
      {showInstructions && (
        <Box css={{ mt: '$space4' }}>
          <Box css={{ display: 'flex' }}>
            <Box as="span" css={{ px: '$space2' }}>
              &bull;
            </Box>
            <Text>Log in to your Olo account and navigate to the Developer Portal</Text>
          </Box>

          <Box css={{ display: 'flex', mt: '$space1' }}>
            <Box as="span" css={{ px: '$space2' }}>
              &bull;
            </Box>
            <Text>
              If you have not already, create a webhook in Olo using the Destination URL shown
              below. Further instructions can be found{' '}
              <Link
                href="https://olosupport.zendesk.com/hc/en-us/articles/360061153692-Self-Service-Webhooks"
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </Link>
              .
            </Text>
          </Box>

          <Box css={{ display: 'flex', mt: '$space1' }}>
            <Box as="span" css={{ px: '$space2' }}>
              &bull;
            </Box>
            <Text>
              Once you are ready to publish your webhook, first select "Test Webhook". This will
              bring up the Shared Secret modal. A 64 character Shared Secret will be provided for
              you and can only be retrieved at this step. Copy this Shared Secret and paste it in
              the field below.
            </Text>
          </Box>
        </Box>
      )}
      {alreadyConfigured &&
        (showInstructions ? (
          <Box css={{ display: 'flex', alignItems: 'center', mt: '$space4' }}>
            <Icon color="default" description="" name="MinusSign" size="small" />
            <Button
              variant="subdued"
              css={{ ml: '$space2', minHeight: '$size6' }}
              onClick={() => setShowInstructions(false)}
            >
              Hide instructions
            </Button>
          </Box>
        ) : (
          <Box css={{ display: 'flex', alignItems: 'center', mt: '$space4' }}>
            <Icon color="default" description="" name="PlusSign" size="small" />
            <Button
              variant="subdued"
              css={{ ml: '$space2', minHeight: '$size6' }}
              onClick={() => setShowInstructions(true)}
            >
              View instructions
            </Button>
          </Box>
        ))}

      <Separator orientation="horizontal" css={{ mt: '$space6' }} />

      <WebhookCallbackUrl
        path="/v1/inbound-webhooks/olo"
        subtext="Paste this into Olo to get your Shared Secret"
        useCentralizedInboundWebhookUrl={false}
      />
    </>
  );
};
