import React from 'react';

import { Box, Link } from '@attentive/picnic';

import mailchimpLogo from '../assets/vendor_logos/mailchimp.png';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { MailchimpSettingsForm } from '../components/SettingsStandard/MailchimpSettingsForm';
import { SetupConnectAndConfigure } from '../components/SetupConnectAndConfigure';
import { MailchimpConfigureForm } from '../components/SetupConnectAndConfigure/MailchimpConfigureForm';
import { SetupConnectAndConfigureInternal } from '../components/SetupConnectAndConfigure/SetupConnectAndConfigureInternal';
import { IntegrationContextProvider } from '../hooks/useIntegrationContext';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  CreativeSpecificDataKeyNames,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

const connectInstructions = (
  <Box as="span">
    To find the API Key,{' '}
    <Link target="_blank" rel="noopener noreferrer" href="https://mailchimp.com/">
      log in to Mailchimp
    </Link>{' '}
    and go to Account &gt; Extras &gt; API Keys. You'll be able to configure your integration after
    you've connected.
  </Box>
);

const connectFields = [
  IntegrationField.Config({
    name: 'apiKey',
    type: IntegrationFieldType.PASSWORD,
    label: 'API Key',
    placeholder: 'Enter API Key',
    required: true,
    dataIntegrationsId: 'MailChimp API Key',
  }),
];

export const mailchimp: Vendor = {
  logo: mailchimpLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://mailchimp.com/',
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contacts to Mailchimp.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Mailchimp so that you can start sending marketing emails immediately. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A Mailchimp account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        "Automatically sync subscriber's emails with Mailchimp so that you can start sending marketing emails immediately.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360041419052',
      label: 'Mailchimp integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupConnectAndConfigure
        vendorKey={VendorKey.MAILCHIMP}
        onComplete={onComplete}
        connectInstructions={connectInstructions}
        connectFields={connectFields}
        configureForm={({ onConfigureComplete }) => (
          <MailchimpConfigureForm onComplete={onConfigureComplete} />
        )}
      />
    );
  },
  setupComponentInternals(onComplete, additionalAction) {
    return (
      <IntegrationContextProvider vendorKey={VendorKey.MAILCHIMP}>
        <SetupConnectAndConfigureInternal
          onComplete={onComplete}
          connectInstructions={connectInstructions}
          connectFields={connectFields}
          configureForm={({ onConfigureComplete }) => (
            <MailchimpConfigureForm onComplete={onConfigureComplete} />
          )}
          additionalAction={additionalAction}
        />
      </IntegrationContextProvider>
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.MAILCHIMP}
        onComplete={onComplete}
        form={({ onFormComplete }) => (
          <MailchimpSettingsForm
            onComplete={onFormComplete}
            superUserEnabled={superUserEnabled || false}
          />
        )}
      />
    );
  },
  creativeSpecificLists: { type: 'compact', key: CreativeSpecificDataKeyNames.LISTS },
  creativeSpecificTags: { type: 'verbose', key: CreativeSpecificDataKeyNames.TAGS },
};
