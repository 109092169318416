import { useFormikContext } from 'formik';
import React, { FC } from 'react';

import { Button } from '@attentive/picnic';

interface Props {
  text: string;
  dataIntegrationsId?: string;
}

export const SubmitButton: FC<Props> = ({ text, dataIntegrationsId }) => {
  const { isSubmitting } = useFormikContext();
  return (
    <Button type="submit" loading={isSubmitting} data-integrations-id={dataIntegrationsId}>
      {text}
    </Button>
  );
};
