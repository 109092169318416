import React from 'react';

import bluecoreLogo from '../assets/vendor_logos/bluecore.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  CreativeSpecificDataKeyNames,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const bluecore: Vendor = {
  logo: bluecoreLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://www.bluecore.com/',
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contacts to Bluecore.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Bluecore so that you can start sending marketing emails immediately. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A BlueCore account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        "Automatically sync subscriber's emails with BlueCore so that you can start sending marketing emails immediately.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360041419192',
      label: 'Bluecore integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.BLUECORE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'campaignId',
            type: IntegrationFieldType.TEXT,
            label: 'Campaign ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'partnerName',
            type: IntegrationFieldType.TEXT,
            label: 'Partner Name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'token',
            type: IntegrationFieldType.TEXT,
            label: 'Company Token',
            required: false,
          }),
          IntegrationField.Config({
            name: 'shouldSendEmailCampaign',
            type: IntegrationFieldType.SELECT,
            label: 'Should Send Email Campaigns',
            options: [
              {
                name: 'True',
                value: 'true',
              },
              {
                name: 'False',
                value: 'false',
              },
            ],
            required: true,
          }),
          IntegrationField.Config({
            name: 'eventName',
            type: IntegrationFieldType.TEXT,
            label: 'Event Name for Track API',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.BLUECORE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'campaignId',
            type: IntegrationFieldType.TEXT,
            label: 'Campaign ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'partnerName',
            type: IntegrationFieldType.TEXT,
            label: 'Partner Name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'token',
            type: IntegrationFieldType.TEXT,
            label: 'Company Token',
            required: false,
          }),
          IntegrationField.Config({
            name: 'shouldSendEmailCampaign',
            type: IntegrationFieldType.SELECT,
            label: 'Should Send Email Campaigns',
            options: [
              {
                name: 'True',
                value: 'true',
              },
              {
                name: 'False',
                value: 'false',
              },
            ],
            required: true,
          }),
          IntegrationField.Config({
            name: 'eventName',
            type: IntegrationFieldType.TEXT,
            label: 'Event Name for Track API',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Bluecore.',
          }),
        ]}
      />
    );
  },
  creativeSpecificCampaignId: { type: 'verbose', key: CreativeSpecificDataKeyNames.CAMPAIGN_ID },
};
