import React from 'react';

import crowdTwistLogo from '../assets/vendor_logos/crowdtwist.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

export const crowdTwist: Vendor = {
  logo: crowdTwistLogo,
  category: VendorCategory.LOYALTY,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.CROWD_TWIST}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'Public Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'idType',
            type: IntegrationFieldType.TEXT,
            label: 'ID Type',
            required: true,
          }),
          IntegrationField.Config({
            name: 'domain',
            type: IntegrationFieldType.TEXT,
            label: 'CrowdTwist API URL',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.CROWD_TWIST}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'Public Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'idType',
            type: IntegrationFieldType.TEXT,
            label: 'ID Type',
            required: true,
          }),
          IntegrationField.Config({
            name: 'domain',
            type: IntegrationFieldType.TEXT,
            label: 'CrowdTwist API URL',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Crowd Twist HTTP Adapter',
            subtext: 'Integration with Crowd Twist For Joins and Opt Outs',
          }),
        ]}
      />
    );
  },
};
