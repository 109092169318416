import React from 'react';

import bigCommerceLogo from '../assets/vendor_logos/bigcommerce.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOauthConnectAndConfigure } from '../components/SetupOauthConnectAndConfigure';
import { VendorCategory, Vendor, VendorKey } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorBigcommerce: Vendor = {
  logo: bigCommerceLogo,
  category: VendorCategory.ECOMMERCE,
  whatYoullNeedItems: ['A BigCommerce Account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Send high-performing journeys, including Back in Stock, Low Inventory, and Price Drop. Branch journeys based on product attributes or inventory – for example only sending a message if the product is in stock',
    },
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description:
        'Segment customers based on purchasing and browsing activity using product data such as name, category, tag, price, and other attributes.',
    },
  ],
  companyWebsite: 'https://www.bigcommerce.com/',
  infoPagesEnabled: true,
  learnSubtext: 'Sync product and customer data to automate and personalize your SMS marketing.',
  jointValueProp:
    'Our BigCommerce integration auto-installs the Attentive tag onto your site, which allows Attentive to receive many important customer events, including product views, add to carts, and purchases. This integration also allows you to sync your entire product catalog to Attentive so that you can send high-performing journeys such as back in stock, low inventory, and price drop. It also lets you segment your customers and branch journeys based on your customers’ purchasing and browsing activity.',
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/4406502960404-BigCommerce',
      label: 'BigCommerce integration guide',
    },
  ],
  setupComponent(_onComplete) {
    return (
      <SetupOauthConnectAndConfigure
        vendorKey={VendorKey.VENDOR_BIGCOMMERCE}
        connectInstructions="Click the Connect button to connect."
        configureFields={[]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_BIGCOMMERCE}
        onComplete={onComplete}
        fields={[]}
        features={[
          IntegrationFeature.Config({
            type: 'SYNC_PRODUCTS',
            label: 'Sync Product Data',
            subtext: 'Create targeted segments based on your Big Commerce product data.',
            featureFlag: 'ALLOW_BIG_COMMERCE_PRODUCT_SYNC',
          }),
        ]}
      />
    );
  },
};
