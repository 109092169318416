import React from 'react';

import placeholderLogo from '../assets/vendor_logos/placeholder.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupConnectAndConfigure } from '../components/SetupConnectAndConfigure';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

export const vendorAttentiveGoogleProductCatalogue: Vendor = {
  category: VendorCategory.OTHER,
  logo: placeholderLogo,
  setupComponent(onComplete, superUserEnabled) {
    return (
      <SetupConnectAndConfigure
        vendorKey={VendorKey.VENDOR_ATTENTIVE_GOOGLE_PRODUCT_CATALOGUE}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        connectFields={[]}
        configureFeatures={[
          IntegrationFeature.Config({
            type: 'SYNC_PRODUCTS',
            label: 'Sync Google Product data',
            subtext: 'Create targeted segments based on your Google product data.',
            initiallyEnabled: true,
            visibility: IntegrationVisibilityType.SUPER_USER,
            fields: [
              IntegrationField.Config({
                name: 's3SourceKeyPrefix',
                type: IntegrationFieldType.TEXT,
                label: 'Product Data Upload Location',
                subtext:
                  "Choose the location in Attentive's SFTP that you would like to upload your files. Remember to include your company folder name",
                placeholder: 'company_name/uploads/product_feed',
                required: true,
              }),
            ],
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_ATTENTIVE_GOOGLE_PRODUCT_CATALOGUE}
        onComplete={onComplete}
        fields={[]}
        superUserEnabled={superUserEnabled}
        features={[
          IntegrationFeature.Config({
            type: 'SYNC_PRODUCTS',
            label: 'Sync Google Product data',
            subtext: 'Create targeted segments based on your Google product data.',
            visibility: IntegrationVisibilityType.SUPER_USER,
            fields: [
              IntegrationField.Config({
                name: 's3SourceKeyPrefix',
                type: IntegrationFieldType.TEXT,
                label: 'Product Data Upload Location',
                subtext:
                  "Choose the location in Attentive's SFTP that you would like to upload your files. Remember to include your company folder name",
                placeholder: 'company_name/uploads/product_feed',
                required: true,
              }),
            ],
          }),
        ]}
      />
    );
  },
};
