import React from 'react';

import richpanelLogo from '../assets/vendor_logos/richpanel.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { RichpanelSettingsForm } from '../components/SettingsStandard/RichpanelSettingsForm';
import { SetupConnectAndConfigure } from '../components/SetupConnectAndConfigure';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

export const vendorRichpanel: Vendor = {
  logo: richpanelLogo,
  category: VendorCategory.CUSTOMER_EXPERIENCE,
  salesEmail: 'sales@richpanel.com',
  setupComponent(onComplete) {
    return (
      <SetupConnectAndConfigure
        vendorKey={VendorKey.VENDOR_RICHPANEL}
        onComplete={onComplete}
        connectFields={[
          IntegrationField.Config({
            name: 'token',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Token',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.TEXT,
            label: 'Client Id',
            required: true,
          }),
          IntegrationField.Config({
            name: 'sendMarketingMessage',
            type: IntegrationFieldType.SELECT,
            label: 'Additional Context Message',
            subtext:
              'If Enabled, an additional context message will be sent to Richpanel along with the customer message. If Disabled, only the customer message will be sent.',
            required: true,
            options: [
              {
                name: 'Enabled',
                value: 'true',
              },
              {
                name: 'Disabled',
                value: 'false',
              },
            ],
          }),
          IntegrationField.Config({
            name: 'bufferTime',
            type: IntegrationFieldType.INT,
            label: 'Context Message Window ',
            subtext:
              'Set the number of hours to wait for an Additional Context Message. If blank, default will be 2 hours.',
            required: false,
          }),
        ]}
        configureFeatures={[
          IntegrationFeature.Config({
            type: 'CUSTOMER_SERVICE_OPT_IN',
            label: 'Support Confirmation',
            subtext:
              'Turn on Support Confirmation to allow customers to escalate their text message conversation to your customer support team.',
            initiallyEnabled: true,
            fields: [
              IntegrationField.Config({
                name: 'serviceVerificationKeyword',
                type: IntegrationFieldType.TEXT,
                label: 'Support Confirmation Keyword',
                subtext:
                  'Choose a keyword your customers can use to confirm they need assistance. Your default autoresponder must include this keyword.',
                placeholder: 'Support',
                required: true,
              }),
              IntegrationField.Config({
                name: 'serviceBeginMessage',
                type: IntegrationFieldType.MULTILINE,
                label: 'Acknowledgement Message',
                subtext:
                  'Your customers will receive this message after replying with the Support Confirmation keyword.',
                placeholder:
                  'Your request has been forwarded to our support team. We will get back to you as soon as possible during our business hours.',
                required: true,
              }),
              IntegrationField.Config({
                name: 'serviceEndMessage',
                type: IntegrationFieldType.MULTILINE,
                label: 'Resolution Message ',
                subtext:
                  'Your customers will receive this message once the support ticket has been resolved.',
                placeholder:
                  'Your request has been resolved. If you need anything else, do not hesitate to reach out.',
                required: false,
              }),
            ],
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_RICHPANEL}
        onComplete={onComplete}
        form={({ onFormComplete }) => <RichpanelSettingsForm onComplete={onFormComplete} />}
      />
    );
  },
};
