import { useCurrentCompanyId } from '@attentive/acore-utils';
import { useQuery, useMutation } from '@attentive/data/react-query';

import { VendorKey } from '../types';

import {
  fetchIntegration,
  fetchVendors,
  fetchOAuthLink,
  connectIntegration,
  configureIntegration,
  updateIntegrationSettings,
  disconnectIntegration,
  NO_INTEGRATION_RESULTS,
  NO_VENDOR_RESULTS,
} from './integrations';

const FETCH_INTEGRATION_QUERY = 'FETCH_INTEGRATION_QUERY';
const FETCH_VENDORS_QUERY = 'FETCH_VENDORS_QUERY';

export const useIntegrationQuery = (vendor: VendorKey) => {
  const companyId = useCurrentCompanyId();
  const results = useQuery([FETCH_INTEGRATION_QUERY, companyId, vendor], () =>
    fetchIntegration(vendor)
  );
  const resultsData = results.data === NO_INTEGRATION_RESULTS ? undefined : results.data;
  return { ...results, data: resultsData };
};

export const useVendorsQuery = () => {
  const companyId = useCurrentCompanyId();
  const queryResults = useQuery([FETCH_VENDORS_QUERY, companyId], () => fetchVendors());
  const queryResultData = queryResults.data === NO_VENDOR_RESULTS ? undefined : queryResults.data;
  return { ...queryResults, data: queryResultData };
};

export const useOAuthLinkMutation = () => useMutation(fetchOAuthLink);

export const useConnectIntegrationMutation = () => useMutation(connectIntegration);

export const useConfigureIntegrationMutation = () => useMutation(configureIntegration);

export const useUpdateIntegrationSettingsMutation = () => useMutation(updateIntegrationSettings);

export const useDisconnectIntegrationMutation = () => useMutation(disconnectIntegration);
