import pick from 'lodash/pick';
import React, { Suspense } from 'react';

import { LoadingIndicator, Box } from '@attentive/picnic';

import { useIntegrationContext } from '../../../hooks/useIntegrationContext';
import { IntegrationFormFeatureValues } from '../../../types';

import AdAccountSelectionForm from './AdAccountSelectionForm';
import FacebookAdsFeatureSettingsForm from './FacebookAdsFeatureSettingsForm';
import { SEGMENT_FEATURE_TYPE, AD_ACCOUNT_ID_KEY, AD_ACCOUNT_NAME_KEY } from './constants';

const SuspenseLoader = () => (
  <Box
    css={{
      display: 'flex',
      justifyContent: 'center',
      minHeight: '$size16',
      alignItems: 'center',
    }}
  >
    <LoadingIndicator />
  </Box>
);

interface Props {
  onComplete: () => void;
  superUserEnabled: boolean;
}

export const FacebookAdsSettingsForm = (formProps: Props) => {
  const { integrationDetailValues } = useIntegrationContext();
  const initialValues = pick(integrationDetailValues || {}, [SEGMENT_FEATURE_TYPE]);
  const segmentFeatureValues = initialValues[SEGMENT_FEATURE_TYPE] as IntegrationFormFeatureValues;
  const hasSelectedAccount = Boolean(
    segmentFeatureValues[AD_ACCOUNT_ID_KEY] && segmentFeatureValues[AD_ACCOUNT_NAME_KEY]
  );

  return (
    <Suspense fallback={<SuspenseLoader />}>
      {hasSelectedAccount ? (
        <FacebookAdsFeatureSettingsForm {...formProps} />
      ) : (
        <AdAccountSelectionForm {...formProps} />
      )}
    </Suspense>
  );
};
