import React, { PropsWithChildren } from 'react';

import { useCurrentCompanyId } from '@attentive/acore-utils';
import { createPresenter } from '@attentive/data';

import IntegrationsCommonBodyQuery, {
  IntegrationsCommonBodyQuery as IntegrationsCommonBodyQueryType,
} from './__generated__/IntegrationsCommonBodyQuery.graphql';

const [useIntegrationsCommonQuery, IntegrationsCommonPresenter] =
  createPresenter<IntegrationsCommonBodyQueryType>(IntegrationsCommonBodyQuery);

const IntegrationsCommonQueryBody: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const currentCompanyId = useCurrentCompanyId();

  return (
    <IntegrationsCommonPresenter vars={{ companyId: currentCompanyId }}>
      {children}
    </IntegrationsCommonPresenter>
  );
};

export { useIntegrationsCommonQuery, IntegrationsCommonQueryBody };
