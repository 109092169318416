import React from 'react';

import zendeskLogo from '../assets/vendor_logos/zendesk.png';
import { SettingsStandard } from '../components/SettingsStandard';
import { ZendeskSettingsForm } from '../components/SettingsStandard/ZendeskSettingsForm';
import { ConnectForm } from '../components/SetupZendesk/ConnectForm';
import { VendorCategory, Vendor, VendorKey } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const zendesk: Vendor = {
  logo: zendeskLogo,
  category: VendorCategory.CUSTOMER_EXPERIENCE,
  companyWebsite: 'https://www.zendesk.com/',
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext: 'Deliver great customer service and offer support via text messaging.',
  jointValueProp:
    'Ensure your customers have a great experience by supporting them via text messaging. Our integration with Zendesk, a customer service software, enables your customer service team to directly respond to text messages via text within the Zendesk platform.',
  whatYoullNeedItems: ['A Zendesk account with an Admin Zendesk user.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.CUSTOMER_SUPPORT,
      description:
        'All messages received from a customer through SMS are forwarded to your customer support team queue in Zendesk.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360041419232',
      label: 'Zendesk integration guide',
    },
  ],
  setupComponent(onComplete) {
    return <ConnectForm onComplete={onComplete} />;
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.ZENDESK}
        onComplete={onComplete}
        form={({ onFormComplete }) => <ZendeskSettingsForm onComplete={onFormComplete} />}
      />
    );
  },
};
