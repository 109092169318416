import React, { FC } from 'react';
import GoogleButton from 'react-google-button';

import { Heading, Stack, Text, Icon, Box } from '@attentive/picnic';

import { NO_INTEGRATION_RESULTS, useOAuthLinkMutation } from '../../api';
import {
  IntegrationContextProvider,
  IntegrationContextBag,
  useIntegrationContext,
} from '../../hooks/useIntegrationContext';
import { MilestoneKey } from '../../hooks/useSetMilestone';
import { IntegrationDetailsResponse, VendorKey } from '../../types';
import { InstallationCard } from '../InstallationCard';
import { ErrorBanner } from '../SetupConnectAndConfigure/ErrorBanner';
import { LoadingScreen } from '../SetupConnectAndConfigure/LoadingScreen';
import { SuccessStep } from '../SetupConnectAndConfigure/SuccessStep';
import { TimeEstimation } from '../TimeEstimation';

import { ConfigureForm } from './ConfigureForm';

interface Props {
  logo: React.ReactNode;
  heading: string;
  extraText?: React.ReactNode | string;
  oAuthState?: string;
  onComplete?: (integrationDetails: IntegrationDetailsResponse) => void;
}

export const SetupGoogleTagManagerInternals: FC<Partial<Props>> = ({
  onComplete,
  oAuthState,
  extraText,
}) => {
  const {
    vendorKey,
    isFetching,
    isFetchingError,
    isConnectStep,
    isConfigureStep,
    isSuccessStep,
    refetchIntegrationDetails,
  } = useIntegrationContext();
  const generateOAuthRedirectMutation = useOAuthLinkMutation();

  const handleGoogleButtonClick = async () => {
    const link = await generateOAuthRedirectMutation.mutateAsync({
      vendorOAuthKey: vendorKey,
      oAuthState,
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    window.location.assign(link!);
  };

  const onConfigureComplete = async () => {
    const { data } = await refetchIntegrationDetails();

    if (onComplete) {
      const onCompleteData = data === NO_INTEGRATION_RESULTS ? undefined : data;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onComplete(onCompleteData!);
    }
  };

  return (
    <>
      {isFetching && <LoadingScreen />}
      {isFetchingError && <ErrorBanner text="Unable to load setup module." />}
      {isConnectStep && (
        <Stack>
          <Text css={{ mb: '$space3' }}>
            Connect to Google Tag Manager and complete your integration setup.
          </Text>
          <Stack direction="horizontal" css={{ alignItems: 'center' }}>
            <Box data-integrations-id="GTM Setup Button">
              <GoogleButton
                disabled={generateOAuthRedirectMutation.isLoading}
                onClick={handleGoogleButtonClick}
              />
            </Box>
            {extraText}
          </Stack>
        </Stack>
      )}
      {isConfigureStep && <ConfigureForm onComplete={onConfigureComplete} />}
      {isSuccessStep && (
        <SuccessStep milestonesCompleted={[MilestoneKey.MilestoneKeyInstalledTag]} />
      )}
    </>
  );
};

export const SetupGoogleTagManager: FC<Props> = ({
  logo,
  heading,
  extraText,
  onComplete,
  oAuthState,
}) => {
  return (
    <IntegrationContextProvider vendorKey={VendorKey.VENDOR_GOOGLE_TAG_MANAGER}>
      {({ isSuccessStep }: IntegrationContextBag) => {
        return (
          <InstallationCard>
            <InstallationCard.Header
              leftSlot={
                <Stack spacing="$space2" direction="horizontal" css={{ alignItems: 'center' }}>
                  {logo}
                  <Heading variant="sm">{heading}</Heading>
                </Stack>
              }
              rightSlot={
                isSuccessStep ? (
                  <Stack
                    direction="horizontal"
                    spacing="$space1"
                    css={{ alignItems: 'center', color: '$green700' }}
                  >
                    <Icon name="CircleCheckmark" />
                    <Text variant="caption">Completed</Text>
                  </Stack>
                ) : (
                  <TimeEstimation>2 mins</TimeEstimation>
                )
              }
            />
            <InstallationCard.Body>
              <SetupGoogleTagManagerInternals
                extraText={extraText}
                onComplete={onComplete}
                oAuthState={oAuthState}
              />
            </InstallationCard.Body>
          </InstallationCard>
        );
      }}
    </IntegrationContextProvider>
  );
};
