import React, { FC, ReactNode } from 'react';

import { useMutation } from '@attentive/data/react-query';
import { Box } from '@attentive/picnic';

import { useOAuthLinkMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationSettingsRequest, VendorKey } from '../../types';
import { buildValidationConfig } from '../../utils';
import { IntegrationFieldConfig } from '../IntegrationField/IntegrationField';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

interface Props {
  instructions?: ReactNode;
  oAuthKey?: string;
  oAuthState?: string;
  fields?: IntegrationFieldConfig[];
}

export const StandardOauthConnectForm: FC<Props> = ({
  instructions,
  oAuthKey,
  oAuthState,
  fields = [],
}) => {
  const generateOAuthRedirectMutation = useOAuthLinkMutation();
  const { vendorKey, integrationDetailValues } = useIntegrationContext();

  const integrationsToReusePreviousIntegrationData: [VendorKey] = [VendorKey.ZENDESK];
  const shouldUsePreviousIntegrationFieldData: boolean =
    integrationsToReusePreviousIntegrationData.includes(vendorKey);

  const redirectToThirdPartyMutation = useMutation(
    async ({ payload }: { vendor: string; payload: IntegrationSettingsRequest }) => {
      const link = await generateOAuthRedirectMutation.mutateAsync({
        vendorOAuthKey: oAuthKey || vendorKey,
        oAuthState,
        fields: payload?.fields,
      });

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      window.location.assign(link!);
    }
  );

  const getFieldValue = (field: IntegrationFieldConfig) => {
    const defaultValue = '';
    if (!shouldUsePreviousIntegrationFieldData) {
      return defaultValue;
    }

    if (!integrationDetailValues || !integrationDetailValues[field.componentProps.name]) {
      return defaultValue;
    }

    return integrationDetailValues[field.componentProps.name];
  };

  const initialValues = Object.fromEntries([
    ...fields.map((field) => [field.componentProps.name, getFieldValue(field)]),
  ]);

  const validationConfig = buildValidationConfig({ fields });

  return (
    <IntegrationForm
      loadingText="Connecting"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={() => redirectToThirdPartyMutation}
      onComplete={() => {}}
    >
      {instructions && <Box>{instructions}</Box>}
      {fields.map((field) => field())}
      <SubmitButton text="Connect" />
    </IntegrationForm>
  );
};
