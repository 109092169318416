import React, { FC } from 'react';

import { Stack, Text } from '@attentive/picnic';

import { useConfigureIntegrationMutation } from '../../api';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

interface SetupGoogleAdsSetupFormProps {
  onComplete: () => void;
}

export const SetupGoogleAdsSetupForm: FC<SetupGoogleAdsSetupFormProps> = ({ onComplete }) => {
  // IntegrationForm requires an initialValues set, even if its empty.
  const initialValues = {};

  return (
    <IntegrationForm
      initialValues={initialValues}
      loadingText="Configuring"
      mutation={useConfigureIntegrationMutation}
      onComplete={onComplete}
    >
      <Stack direction="vertical" spacing="$space6">
        <Text>
          Your Google Ads account has been connected. Please click Submit to complete your
          installation.
        </Text>
        <SubmitButton text="Submit" />
      </Stack>
    </IntegrationForm>
  );
};
