import { Vendors } from '../types';

import { aftership } from './aftership';
import { bluecore } from './bluecore';
import { blueshiftEvents } from './blueshift_events';
import { braze } from './braze';
import { bronto } from './bronto';
import { cheetahmail } from './cheetahmail';
import { cheetahmailSetuser } from './cheetahmail_setuser';
import { conversio } from './conversio';
import { cordial } from './cordial';
import { crowdTwist } from './crowd_twist';
import { drip } from './drip';
import { emarsys } from './emarsys';
import { freshdesk } from './freshdesk';
import { gorgias } from './gorgias';
import { hubspot } from './hubspot';
import { iterable } from './iterable';
import { klaviyo } from './klaviyo';
import { kustomer } from './kustomer';
import { listrak } from './listrak';
import { mailchimp } from './mailchimp';
import { maropost } from './maropost';
import { mparticle } from './mparticle';
import { omnisend } from './omnisend';
import { rejoiner } from './rejoiner';
import { responsys } from './responsys';
import { retentionScience } from './retention_science';
import { sailthru } from './sailthru';
import { salesforce } from './salesforce';
import { salesforceDataExtension } from './salesforce_data_extension';
import { salesforceJourney } from './salesforce_journey';
import { segmentAnalytics } from './segment_analytics';
import { segmentAnalyticsPgBrands } from './segment_analytics_pg_brands';
import { shopify } from './shopify';
import { silverpop } from './silverpop';
import { sprouts } from './sprouts';
import { vendorActivecampaign } from './vendor_activecampaign';
import { vendorAdobe } from './vendor_adobe';
import { vendorAttentiveGoogleProductCatalogue } from './vendor_attentive_google_product_catalogue';
import { vendorAweber } from './vendor_aweber';
import { vendorBazaarvoice } from './vendor_bazaarvoice';
import { vendorBigcommerce } from './vendor_bigcommerce';
import { vendorBloomreach } from './vendor_bloomreach';
import { vendorBlotout } from './vendor_blotout_whitelabel';
import { vendorCampaignMonitor } from './vendor_campaign_monitor';
import { vendorChatter } from './vendor_chatter';
import { vendorConstantContact } from './vendor_constant_contact';
import { vendorDotdigital } from './vendor_dotdigital';
import { vendorEloqua } from './vendor_eloqua';
import { vendorEpsilon } from './vendor_epsilon';
import { vendorExponea } from './vendor_exponea';
import { vendorFacebookAds } from './vendor_facebook_ads';
import { vendorFivetran } from './vendor_fivetran';
import { vendorGladly } from './vendor_gladly';
import { vendorGoogleAds } from './vendor_google_ads';
import { vendorGoogleContentApi } from './vendor_google_content_api';
import { vendorGoogleTagManager } from './vendor_google_tag_manager';
import { vendorHelpscout } from './vendor_helpscout';
import { vendorIntercom } from './vendor_intercom';
import { vendorLiveperson } from './vendor_liveperson';
import { vendorLoyaltylion } from './vendor_loyaltylion';
import { vendorMagento } from './vendor_magento';
import { vendorMailtrain } from './vendor_mailtrain';
import { vendorNarvar } from './vendor_narvar';
import { vendorOlo } from './vendor_olo';
import { vendorOmetria } from './vendor_ometria';
import { vendorOptimizely } from './vendor_optimizely';
import { vendorOptimove } from './vendor_optimove';
import { vendorPasskit } from './vendor_passkit';
import { vendorPaytronix } from './vendor_paytronix';
import { vendorPostPilot } from './vendor_post_pilot';
import { vendorPunchh } from './vendor_punchh';
import { vendorReamaze } from './vendor_reamaze';
import { vendorRecharge } from './vendor_recharge';
import { vendorRichpanel } from './vendor_richpanel';
import { vendorSalesforceServiceCloud } from './vendor_salesforce_service_cloud';
import { vendorSatisfilabs } from './vendor_satisfilabs';
import { vendorSegment } from './vendor_segment';
import { vendorSendgrid } from './vendor_sendgrid';
import { vendorSendinblue } from './vendor_sendinblue';
import { vendorSendlane } from './vendor_sendlane';
import { vendorSmileio } from './vendor_smileio';
import { vendorStamped } from './vendor_stamped';
import { vendorTalonOne } from './vendor_talon_one';
import { vendorTravelclick } from './vendor_travelclick';
import { vendorWoocommerce } from './vendor_woocommerce';
import { vendorWorldMarket } from './vendor_world_market';
import { vendorYesmail } from './vendor_yesmail';
import { vendorYotpo } from './vendor_yotpo';
import { vendorZetaGlobal } from './vendor_zeta_global';
import { vendorZoho } from './vendor_zoho';
import { whatcounts } from './whatcounts';
import { zaius } from './zaius';
import { zendesk } from './zendesk';

export const integrationSupportEmail = 'whiteglove@attentivemobile.com';

export const vendors: Vendors = {
  AFTERSHIP: aftership,
  BLUECORE: bluecore,
  BLUESHIFT_EVENTS: blueshiftEvents,
  BRAZE: braze,
  BRONTO: bronto,
  CHEETAHMAIL: cheetahmail,
  CHEETAHMAIL_SETUSER: cheetahmailSetuser,
  CONVERSIO: conversio,
  CORDIAL: cordial,
  CROWD_TWIST: crowdTwist,
  DRIP: drip,
  EMARSYS: emarsys,
  FRESHDESK: freshdesk,
  GORGIAS: gorgias,
  HUBSPOT: hubspot,
  ITERABLE: iterable,
  KLAVIYO: klaviyo,
  KUSTOMER: kustomer,
  LISTRAK: listrak,
  MAILCHIMP: mailchimp,
  MAROPOST: maropost,
  MPARTICLE: mparticle,
  OMNISEND: omnisend,
  REJOINER: rejoiner,
  RESPONSYS: responsys,
  RETENTION_SCIENCE: retentionScience,
  SAILTHRU: sailthru,
  SALESFORCE: salesforce,
  SALESFORCE_DATA_EXTENSION: salesforceDataExtension,
  SALESFORCE_JOURNEY: salesforceJourney,
  SEGMENT_ANALYTICS: segmentAnalytics,
  SEGMENT_ANALYTICS_PG_BRANDS: segmentAnalyticsPgBrands,
  SHOPIFY: shopify,
  SILVERPOP: silverpop,
  SPROUTS: sprouts,
  VENDOR_ACTIVECAMPAIGN: vendorActivecampaign,
  VENDOR_ADOBE: vendorAdobe,
  VENDOR_ATTENTIVE_GOOGLE_PRODUCT_CATALOGUE: vendorAttentiveGoogleProductCatalogue,
  VENDOR_AWEBER: vendorAweber,
  VENDOR_BAZAARVOICE: vendorBazaarvoice,
  VENDOR_BIGCOMMERCE: vendorBigcommerce,
  VENDOR_BLOOMREACH: vendorBloomreach,
  VENDOR_BLOTOUT_WHITELABEL: vendorBlotout,
  VENDOR_CAMPAIGN_MONITOR: vendorCampaignMonitor,
  VENDOR_CHATTER: vendorChatter,
  VENDOR_CONSTANT_CONTACT: vendorConstantContact,
  VENDOR_DOTDIGITAL: vendorDotdigital,
  VENDOR_FACEBOOK_ADS: vendorFacebookAds,
  VENDOR_ELOQUA: vendorEloqua,
  VENDOR_EPSILON: vendorEpsilon,
  VENDOR_EXPONEA: vendorExponea,
  VENDOR_FIVETRAN: vendorFivetran,
  VENDOR_GLADLY: vendorGladly,
  VENDOR_GOOGLE_TAG_MANAGER: vendorGoogleTagManager,
  VENDOR_GOOGLE_CONTENT_API: vendorGoogleContentApi,
  VENDOR_GOOGLE_ADS: vendorGoogleAds,
  VENDOR_HELPSCOUT: vendorHelpscout,
  VENDOR_INTERCOM: vendorIntercom,
  VENDOR_LIVEPERSON: vendorLiveperson,
  VENDOR_LOYALTYLION: vendorLoyaltylion,
  VENDOR_MAGENTO: vendorMagento,
  VENDOR_MAILTRAIN: vendorMailtrain,
  VENDOR_NARVAR: vendorNarvar,
  VENDOR_OLO: vendorOlo,
  VENDOR_OMETRIA: vendorOmetria,
  VENDOR_OPTIMIZELY: vendorOptimizely,
  VENDOR_OPTIMOVE: vendorOptimove,
  VENDOR_PASSKIT: vendorPasskit,
  VENDOR_PAYTRONIX: vendorPaytronix,
  VENDOR_PUNCHH: vendorPunchh,
  VENDOR_REAMAZE: vendorReamaze,
  VENDOR_RECHARGE: vendorRecharge,
  VENDOR_RICHPANEL: vendorRichpanel,
  VENDOR_SALESFORCE_SERVICE_CLOUD: vendorSalesforceServiceCloud,
  VENDOR_SATISFILABS: vendorSatisfilabs,
  VENDOR_SEGMENT: vendorSegment,
  VENDOR_SENDGRID: vendorSendgrid,
  VENDOR_SENDINBLUE: vendorSendinblue,
  VENDOR_SENDLANE: vendorSendlane,
  VENDOR_STAMPED: vendorStamped,
  VENDOR_SMILE_IO: vendorSmileio,
  VENDOR_TALON_ONE: vendorTalonOne,
  VENDOR_TRAVELCLICK: vendorTravelclick,
  VENDOR_WOOCOMMERCE: vendorWoocommerce,
  VENDOR_YESMAIL: vendorYesmail,
  VENDOR_YOTPO: vendorYotpo,
  VENDOR_ZETA_GLOBAL: vendorZetaGlobal,
  VENDOR_ZOHO: vendorZoho,
  WHATCOUNTS: whatcounts,
  ZAIUS: zaius,
  ZENDESK: zendesk,
  VENDOR_WORLD_MARKET: vendorWorldMarket,
  VENDOR_POST_PILOT: vendorPostPilot,
};
