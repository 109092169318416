import React from 'react';

import placeholderLogo from '../assets/vendor_logos/placeholder.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

export const vendorSendgrid: Vendor = {
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  logo: placeholderLogo,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_SENDGRID}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'access_token',
            type: IntegrationFieldType.PASSWORD,
            label: 'Access Token',
            required: true,
          }),
          IntegrationField.Config({
            name: 'list_ids',
            type: IntegrationFieldType.TEXT,
            label: 'List Ids (comma separated)',
            required: false,
          }),
          IntegrationField.Config({
            name: 'creative_specific_lists',
            type: IntegrationFieldType.TEXT,
            label: 'Creative Specific Lists (inline json)',
            required: false,
          }),
          IntegrationField.Config({
            name: 'use_legacy_endpoint',
            type: IntegrationFieldType.TEXT,
            label: 'Use Legacy Endpoint (true / false)',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_SENDGRID}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'access_token',
            type: IntegrationFieldType.PASSWORD,
            label: 'Access Token',
            required: true,
          }),
          IntegrationField.Config({
            name: 'list_ids',
            type: IntegrationFieldType.TEXT,
            label: 'List Ids (comma separated)',
            required: false,
          }),
          IntegrationField.Config({
            name: 'creative_specific_lists',
            type: IntegrationFieldType.TEXT,
            label: 'Creative Specific Lists (inline json)',
            required: false,
          }),
          IntegrationField.Config({
            name: 'use_legacy_endpoint',
            type: IntegrationFieldType.TEXT,
            label: 'Use Legacy Endpoint (true / false)',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to SendGrid.',
          }),
        ]}
      />
    );
  },
};
