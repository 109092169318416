import { useFormikContext, getIn } from 'formik';
import React, { FC } from 'react';

import { API } from '@attentive/acore-utils';
import { useQuery } from '@attentive/data/react-query';
import { Box, Stack } from '@attentive/picnic';

import { useConfigureIntegrationMutation } from '../../api';
import { getErrorMessage } from '../../api/utils';
import {
  GoogleTagManagerAccount,
  GoogleTagManagerContainer,
  GoogleTagManagerWorkspace,
  IntegrationFieldType,
} from '../../types';
import { IntegrationField } from '../IntegrationField';
import { DisconnectButton } from '../SetupConnectAndConfigure/DisconnectButton';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

const PU_MAX_INPUT_WIDTH = '600px';
const GTM_BASE_ENDPOINT = '/integrations/gtm';

async function apiGet<T>(path: string) {
  const response = await API.get<T>(path);
  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return response.body!;
}

async function fetchAllGtmAccounts() {
  // GMRU: GET /integrations/gtm/accounts
  return await apiGet<GoogleTagManagerAccount[]>(`${GTM_BASE_ENDPOINT}/accounts`);
}

async function fetchAllGtmContainers(account?: string) {
  if (!account) {
    return [];
  }
  // GMRU: GET /integrations/gtm/containers
  return await apiGet<GoogleTagManagerContainer[]>(
    `${GTM_BASE_ENDPOINT}/containers?account=${account}`
  );
}

async function fetchAllGtmWorkspaces(account?: string, container?: string) {
  if (!account || !container) {
    return [];
  }
  // GMRU: GET /integrations/gtm/workspaces
  return await apiGet<GoogleTagManagerWorkspace[]>(
    `${GTM_BASE_ENDPOINT}/workspaces?account=${account}&container=${container}`
  );
}

const FieldEnabler: React.FC<{ arr: unknown; children: React.ReactNode }> = (props: {
  arr: unknown;
  children: React.ReactNode;
}) => {
  const isArray = Array.isArray(props.arr) && props.arr.length > 0;
  return (
    <Box
      css={{
        opacity: isArray ? '1' : '0.5',
        pointerEvents: isArray ? 'auto' : 'none',
        width: '100%',
        maxWidth: PU_MAX_INPUT_WIDTH,
      }}
    >
      {props.children}
    </Box>
  );
};

const AccountField: React.FC = () => {
  const { data: accounts } = useQuery(['accounts'], fetchAllGtmAccounts);

  return (
    <FieldEnabler arr={accounts}>
      <IntegrationField
        type={IntegrationFieldType.SELECT}
        required={true}
        options={(accounts || []).map((opt) => {
          return { name: opt.name, value: opt.accountId };
        })}
        name="account"
        label="Account"
      />
    </FieldEnabler>
  );
};

const ContainerField: React.FC = () => {
  const { values } = useFormikContext();
  const selectedAccount = getIn(values, 'account');
  const { data: containers } = useQuery(['containers', selectedAccount], () =>
    fetchAllGtmContainers(selectedAccount)
  );

  return (
    <FieldEnabler arr={containers}>
      <IntegrationField
        type={IntegrationFieldType.SELECT}
        required={true}
        options={(containers || []).map((opt) => {
          return { name: `${opt.name} (${opt.publicId})`, value: opt.containerId };
        })}
        name="container"
        label="Container"
      />
    </FieldEnabler>
  );
};

const WorkspaceField: React.FC = () => {
  const { values } = useFormikContext();
  const selectedAccount = getIn(values, 'account');
  const selectedContainer = getIn(values, 'container');
  const { data: workspaces } = useQuery(['workspaces', selectedAccount, selectedContainer], () =>
    fetchAllGtmWorkspaces(selectedAccount, selectedContainer)
  );

  return (
    <FieldEnabler arr={workspaces}>
      <IntegrationField
        type={IntegrationFieldType.SELECT}
        required={true}
        options={(workspaces || []).map((opt) => {
          return { name: opt.name, value: opt.workspaceId };
        })}
        name="workspace"
        label="Workspace"
      />
    </FieldEnabler>
  );
};

interface Props {
  onComplete: () => void;
}

export const ConfigureForm: FC<Props> = ({ onComplete }) => {
  const initialValues = {
    account: '',
    container: '',
    workspace: '',
  };

  const validationConfig = {
    fields: {
      account: { required: true },
      container: { required: true },
      workspace: { required: true },
    },
  };

  return (
    <IntegrationForm
      loadingText="Configuring"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useConfigureIntegrationMutation}
      onComplete={onComplete}
    >
      <AccountField />
      <ContainerField />
      <WorkspaceField />

      <Stack direction="horizontal" spacing="$space6">
        <SubmitButton text="Create Tag" />
        <DisconnectButton />
      </Stack>
    </IntegrationForm>
  );
};
