import React from 'react';

import googleContentAPILogo from '../assets/vendor_logos/googlecontentapi.png';
import {
  SetupGoogleContentApi,
  SetupGoogleContentApiSteps,
  SetupGoogleContentApiSettings,
} from '../components/SetupGoogleContentApi';
import { IntegrationContextProvider } from '../hooks';
import { VendorCategory, Vendor, VendorKey } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorGoogleContentApi: Vendor = {
  logo: googleContentAPILogo,
  category: VendorCategory.OTHER,
  infoPagesEnabled: true,
  setupComponent(onComplete) {
    return <SetupGoogleContentApi onComplete={onComplete} />;
  },
  setupComponentInternals(onComplete, extraText, _, oAuthState) {
    return (
      <IntegrationContextProvider vendorKey={VendorKey.VENDOR_GOOGLE_CONTENT_API}>
        <SetupGoogleContentApiSteps
          onComplete={onComplete}
          extraText={extraText}
          oAuthState={oAuthState}
        />
      </IntegrationContextProvider>
    );
  },
  settingsComponent() {
    return <SetupGoogleContentApiSettings />;
  },
  companyWebsite: 'https://developers.google.com/shopping-content/guides/quickstart/',
  learnSubtext:
    'Google Content API provides a seamless way to import your Google Product Catalog to Attentive.',
  jointValueProp:
    'Google Content API provides a seamless way to import your Google Product Catalog to Attentive. With the click of a button, import your product catalog from Google Shopping.',
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.LIST_GROWTH,
      description:
        'Seamlessly import your product catalog to Attentive through the Google Content API and use your products in journeys.',
    },
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Seamlessly import your product catalog to Attentive through the Google Content API and use your products in journeys.',
    },
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description:
        'Seamlessly import your product catalog to Attentive through the Google Content API and use your products in journeys.',
    },
    {
      feature: ATTENTIVE_FEATURES.OFFERS,
      description:
        'Seamlessly import your product catalog to Attentive through the Google Content API and use your products in journeys.',
    },
  ],
  helpLinks: [
    {
      href: 'https://developers.google.com/shopping-content/guides/quickstart/',
      label: 'Google Content API documentation',
    },
  ],
  whatYoullNeedItems: [
    'A Google Content API account.',
    'Products uploaded to Google Shopping.',
    'No existing product catalog in Attentive.',
  ],
  disconnectText: 'We will no longer be able to import your product catalog from Google Shopping.',
  disconnectConfirmationText:
    "Are you sure? If you'd like to import your product catalog using an alternate method, we may need to delete your product catalog. Contact your customer support manager for more information.",
};
