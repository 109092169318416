import React from 'react';

import omnisendLogo from '../assets/vendor_logos/omnisend.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const omnisend: Vendor = {
  logo: omnisendLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://www.omnisend.com/v4/',
  infoPagesEnabled: true,
  learnSubtext: 'Grow your email list through Attentive sign-up forms, sync contacts to Omnisend.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Omnisend and can trigger a welcome email to new email marketing subscribers. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['An Omnisend account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        'Automatically pass emails and phone numbers capture collected through the Attentive sign-up units to the Omnisend platform.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/4405709470996',
      label: 'Omnisend integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.OMNISEND}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.OMNISEND}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Omnisend',
          }),
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward phones',
            subtext: 'Forward any phone numbers collected by Attentive to Omnisend',
          }),
          IntegrationFeature.Config({
            type: 'FEATURE_TRIGGER_VENDOR_EMAIL_SEND',
            label: 'Trigger email send',
            subtext:
              'Trigger email send within Omnisend via the sendWelcomeMessagefield under identifiers.type["email"]',
          }),
          IntegrationFeature.Config({
            type: 'FEATURE_POST_SUBSCRIBER_OPT_OUT',
            label: 'Forward opt-outs',
            subtext: 'Forward any opt outs to Omnisend',
          }),
        ]}
      />
    );
  },
};
