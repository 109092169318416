import React, { FC } from 'react';

import { Heading, Stack, Text, Icon } from '@attentive/picnic';

import {
  IntegrationContextProvider,
  IntegrationContextBag,
} from '../../hooks/useIntegrationContext';
import { VendorKey } from '../../types';
import { InstallationCard } from '../InstallationCard';
import { SetupLogo } from '../SetupLogo';
import { TimeEstimation } from '../TimeEstimation';

import {
  SetupConnectAndConfigureInternal,
  SetupConnectAndConfigureInternalProps,
} from './SetupConnectAndConfigureInternal';

type Props = {
  vendorKey: VendorKey;
} & SetupConnectAndConfigureInternalProps;

export const SetupConnectAndConfigure: FC<Props> = (props) => {
  const { vendorKey, ...internalProps } = props;

  return (
    <IntegrationContextProvider vendorKey={vendorKey}>
      {({ vendorConfig, vendorDetails, isSuccessStep }: IntegrationContextBag) => {
        return (
          <InstallationCard>
            <InstallationCard.Header
              leftSlot={
                <Stack spacing="$space2" direction="horizontal" css={{ alignItems: 'center' }}>
                  <SetupLogo src={vendorConfig.logo} />
                  <Heading variant="sm">Connect {vendorDetails?.displayName}</Heading>
                </Stack>
              }
              rightSlot={
                isSuccessStep ? (
                  <Stack
                    direction="horizontal"
                    spacing="$space1"
                    css={{ alignItems: 'center', color: '$green700' }}
                  >
                    <Icon name="CircleCheckmark" />
                    <Text variant="caption">Completed</Text>
                  </Stack>
                ) : (
                  <TimeEstimation>2 mins</TimeEstimation>
                )
              }
            />
            <InstallationCard.Body>
              <SetupConnectAndConfigureInternal {...internalProps} />
            </InstallationCard.Body>
          </InstallationCard>
        );
      }}
    </IntegrationContextProvider>
  );
};
