import React from 'react';

import klaviyoLogo from '../assets/vendor_logos/klaviyo.png';
import { IntegrationField } from '../components/IntegrationField';
import { setShowKlaviyoDialogId } from '../components/Klaviyo/KlaviyoIdDialog';
import { SettingsStandard } from '../components/SettingsStandard';
import { KlaviyoSettingsForm } from '../components/SettingsStandard/KlaviyoSettingsForm';
import { SetupConnectAndConfigure } from '../components/SetupConnectAndConfigure';
import { KlaviyoConfigureForm } from '../components/SetupConnectAndConfigure/KlaviyoConfigureForm';
import { SetupConnectAndConfigureInternal } from '../components/SetupConnectAndConfigure/SetupConnectAndConfigureInternal';
import { IntegrationContextProvider } from '../hooks/useIntegrationContext';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  CreativeSpecificDataKeyNames,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

const connectFields = [
  IntegrationField.Config({
    name: 'apiKey',
    type: IntegrationFieldType.PASSWORD,
    label: 'API Key',
    placeholder: 'Enter API Key',
    required: true,
    dataIntegrationsId: 'Klaviyo API Key',
  }),
];

export const klaviyo: Vendor = {
  logo: klaviyoLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://www.klaviyo.com/',
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  customSuccessToastMessage:
    'Your Klaviyo integration has been updated. Changes may take 24 hours to propagate.',
  learnSubtext:
    'Orchestrate and personalize your SMS and email campaigns with contact and behavorial data.',
  jointValueProp:
    'Orchestrate your SMS and email communications by connecting Attentive to Klaviyo. Sync Klaviyo profile data, segments, and historical email engagement to Attentive to send targeted and personalized campaigns and trigger messages based on subscriber activity. This integration also allows you to use high-performing Attentive sign-up forms to collect emails and SMS opt-ins in the same flow and sync contacts to Klaviyo for email marketing.',
  whatYoullNeedItems: ['A Klaviyo account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description:
        'You can sync your Klaviyo segments to Attentive. You can also create segments based on Klaviyo subscriber email engagement or Klaviyo profile data and custom attributes.',
    },
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description: 'Pass email addresses collected through the Attentive sign-up forms to Klaviyo.',
    },
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Trigger messages based on Klaviyo email activity, such as opens, clicks, and messages received.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360041419172',
      label: 'Klaviyo integration guide',
    },
  ],
  setupComponent(onComplete, superUserEnabled) {
    return (
      <SetupConnectAndConfigure
        vendorKey={VendorKey.KLAVIYO}
        onComplete={onComplete}
        connectFields={connectFields}
        configureForm={({ onConfigureComplete }) => (
          <KlaviyoConfigureForm
            onComplete={() => {
              setShowKlaviyoDialogId();
              onConfigureComplete();
            }}
            superUserEnabled={superUserEnabled || false}
          />
        )}
      />
    );
  },
  setupComponentInternals(onComplete, additionalAction, superUserEnabled) {
    return (
      <IntegrationContextProvider vendorKey={VendorKey.KLAVIYO}>
        <SetupConnectAndConfigureInternal
          onComplete={onComplete}
          connectFields={connectFields}
          configureForm={({ onConfigureComplete }) => (
            <KlaviyoConfigureForm
              onComplete={() => {
                setShowKlaviyoDialogId();
                onConfigureComplete();
              }}
              superUserEnabled={superUserEnabled || false}
            />
          )}
          additionalAction={additionalAction}
        />
      </IntegrationContextProvider>
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.KLAVIYO}
        onComplete={onComplete}
        form={({ onFormComplete }) => (
          <KlaviyoSettingsForm
            onComplete={onFormComplete}
            superUserEnabled={superUserEnabled || false}
          />
        )}
      />
    );
  },
  creativeSpecificLists: { type: 'verbose', key: CreativeSpecificDataKeyNames.LISTS },
  creativeSpecificSources: { type: 'verbose', key: CreativeSpecificDataKeyNames.SOURCES },
};
