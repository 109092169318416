import optimizelyLogo from '../assets/vendor_logos/optimizely.png';
import { VendorCategory, Vendor, CreativeSpecificDataKeyNames } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const zaius: Vendor = {
  logo: optimizelyLogo,
  category: VendorCategory.CUSTOMER_DATA_PLATFORM,
  companyWebsite: 'https://www.optimizely.com/',
  infoPagesEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contact data to Optimizely.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Optimizely so that you can start sending marketing emails immediately. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['An Optimizely account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description: "Sync subscriber's emails automatically to Optimizely.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360041419012',
      label: 'Optimizely integration guide',
    },
  ],
  creativeSpecificData: { type: 'compact', key: CreativeSpecificDataKeyNames.DATA },
};
