import React, { FC } from 'react';

import { Banner } from '@attentive/picnic';

interface Props {
  text: string;
}

export const ErrorBanner: FC<Props> = ({ text }) => (
  <Banner variant="error" css={{ width: '100%' }}>
    <Banner.Text>{text}</Banner.Text>
  </Banner>
);
