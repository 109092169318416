import React, { FC } from 'react';

import { Config, API } from '@attentive/acore-utils';
import { useMutation } from '@attentive/data/react-query';

import { getErrorMessage } from '../../api/utils';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationSettingsRequest, Feature, IntegrationFieldType } from '../../types';
import { IntegrationFeature } from '../IntegrationFeature';
import { IntegrationField } from '../IntegrationField';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

class SalesforceAPI extends API {
  public static getAPIUrl() {
    return Config.get('salesForceApiUrl');
  }
}

export const ConnectForm: FC = () => {
  const { vendorDetails } = useIntegrationContext();

  const featuresByType =
    vendorDetails?.features.reduce((obj, feature) => {
      obj[feature.type] = feature;
      return obj;
    }, {} as { [type: string]: Feature }) ?? {};

  const fetchOAuthLinkMutation = useMutation(
    async ({ payload }: { vendor: string; payload: IntegrationSettingsRequest }) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const featureData = Object.entries(payload.features!).map(([type, feature]) => ({
        externalVendorFeatureId: featuresByType[type].externalVendorFeatureId,
        enabled: feature.enabled,
        configuredParameters: feature.fields,
      }));

      const querystring = `featureData=${encodeURIComponent(JSON.stringify(featureData))}`;
      // GMRU: GET /service-cloud/next-step
      const response = await SalesforceAPI.get<{ link: string }>(
        `/service-cloud/next-step?${querystring}`
      );

      if (response.status >= 300) {
        throw new Error(getErrorMessage(response));
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      window.location.assign(response.body!.link!);
    }
  );

  const initialValues = {
    POSTBACK_EMAILS: { enabled: true },
    FORWARD_MESSAGE_CUSTOMER_SERVICE: { enabled: true },
    CUSTOMER_SERVICE_OPT_IN: {
      enabled: false,
      chatRestUrl: '',
      deploymentId: '',
      buttonId: '',
      serviceVerificationKeyword: '',
      serviceBeginMessage: '',
      serviceEndMessage: '',
    },
  };

  const validationConfig = {
    features: {
      CUSTOMER_SERVICE_OPT_IN: {
        chatRestUrl: { required: true },
        deploymentId: { required: true },
        buttonId: { required: true },
        serviceVerificationKeyword: { required: true },
        serviceBeginMessage: { required: true },
      },
    },
  };

  return (
    <IntegrationForm
      loadingText="Connecting"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={() => fetchOAuthLinkMutation}
      onComplete={() => {}}
    >
      <IntegrationFeature
        type="POSTBACK_EMAILS"
        label="Contact Sync"
        subtext="Forward any emails collected by Attentive to Salesforce."
      />

      <IntegrationFeature
        type="CUSTOMER_SERVICE_OPT_IN"
        label="Enable Customer Support via SMS"
        subtext="This feature requires Salesforce Digital Engagement add-on. Please consult the help guide or reach out to your CSM to finish set up."
        fields={[
          IntegrationField.Config({
            name: 'chatRestUrl',
            type: IntegrationFieldType.TEXT,
            subtext: 'URL that lets you access data from your organization’s Live Agent sessions',
            required: true,
            label: 'Chat API Endpoint',
          }),
          IntegrationField.Config({
            name: 'deploymentId',
            type: IntegrationFieldType.TEXT,
            subtext: 'The ID of the deployment that the chat will originate from',
            required: true,
            label: 'Deployment Id',
          }),
          IntegrationField.Config({
            name: 'buttonId',
            type: IntegrationFieldType.TEXT,
            subtext: 'The ID of the button that the chat will originate from',
            required: true,
            label: 'Button Id',
          }),
          IntegrationField.Config({
            name: 'serviceVerificationKeyword',
            type: IntegrationFieldType.TEXT,
            subtext:
              'Choose a keyword your customers can use to confirm they need assistance. Your default autoresponder must include this keyword.',
            required: true,
            label: 'Support Confirmation Keyword',
            placeholder: 'Support',
          }),
          IntegrationField.Config({
            name: 'serviceBeginMessage',
            type: IntegrationFieldType.MULTILINE,
            subtext:
              'Your customers will receive this message after replying with the Support Confirmation keyword.',
            required: true,
            label: 'Acknowledgement Message',
            placeholder:
              'Your request has been forwarded to our support team. We will get back to you as soon as possible during our business hours.',
          }),
          IntegrationField.Config({
            name: 'serviceEndMessage',
            type: IntegrationFieldType.MULTILINE,
            subtext:
              'Your customers will receive this message once the support ticket has been resolved.',
            required: false,
            label: 'Resolution Message',
            placeholder:
              'Your request has been resolved. If you need anything else, do not hesitate to reach out.',
          }),
        ]}
      />

      <SubmitButton text="Connect" />
    </IntegrationForm>
  );
};
