import bloomreachLogo from '../assets/vendor_logos/bloomreach.png';
import { VendorCategory, Vendor } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorExponea: Vendor = {
  logo: bloomreachLogo,
  category: VendorCategory.CUSTOMER_DATA_PLATFORM,
  companyWebsite: 'https://www.bloomreach.com/en',
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contact data to Bloomreach.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Bloomreach so that you can start sending marketing emails immediately. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A Bloomreach account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        "Automatically sync subscriber's emails with Bloomreach so that you can start sending marketing emails immediately.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/4405769399828',
      label: 'Bloomreach integration guide',
    },
  ],
};
