import React, { FC } from 'react';

import { API } from '@attentive/acore-utils';
import { useMutation } from '@attentive/data/react-query';

import { getErrorMessage } from '../../api/utils';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationSettingsRequest, Feature, IntegrationFieldType } from '../../types';
import { IntegrationField } from '../IntegrationField';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';

export const ConnectForm: FC<{ isOnboarding?: boolean }> = ({ children, isOnboarding }) => {
  const { vendorDetails } = useIntegrationContext();

  const featuresByType =
    vendorDetails?.features.reduce((obj, feature) => {
      obj[feature.type] = feature;
      return obj;
    }, {} as { [type: string]: Feature }) ?? {};

  const fetchOAuthLinkMutation = useMutation(
    async ({ payload }: { vendor: string; payload: IntegrationSettingsRequest }) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const featureData = Object.entries(payload.features!)
        .filter(([type]) => featuresByType[type])
        .map(([type, feature]) => ({
          externalVendorFeatureId: featuresByType[type].externalVendorFeatureId,
          enabled: feature.enabled,
          configuredParameters: feature.fields,
        }));

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const shopNameParam = encodeURIComponent(payload.fields.shopName!);
      const featureDataParam = encodeURIComponent(JSON.stringify(featureData));
      let querystring = `shopName=${shopNameParam}&featureData=${featureDataParam}`;
      if (isOnboarding) {
        querystring = `${querystring}&state=ONBOARDING`;
      }

      // GMRU: GET /integrations/shopify/attentive/auth-link
      const response = await API.get<{ link: string }>(
        `/integrations/shopify/attentive/auth-link?${querystring}`
      );

      if (response.status >= 300) {
        throw new Error(getErrorMessage(response));
      }

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      window.location.assign(response.body!.link!);
    }
  );

  const initialValues = {
    shopName: '',

    // these 3 features are not in the form below
    // because we don't want to show them to the user
    // but we do want them to be enabled when the user
    // submits the form so we're setting them to enabled in
    // this initialValues object so that they get
    // turned on by default.
    DYNAMIC_COUPON_GENERATION: { enabled: true },
    FEATURE_SYNC_ACCOUNT_PROPERTIES: { enabled: true },
    FEATURE_SYNC_ORDER_EVENTS: { enabled: true },

    // these features only show in the form below for super users
    // but when a regular user saves the form we want them to be
    // on or off based on the values we're setting here
    // meaning we will send these values to the backend
    // when the user submits the form even if the toggles
    // don't show in the UI
    BRIDGE_IDENTIFIERS: { enabled: true },
    FEATURE_CREATE_MARKETING_EMAIL_SUBSCRIPTIONS: { enabled: false },
    FEATURE_SYNC_ABANDONED_CHECKOUTS: { enabled: true },
    FEATURE_SYNC_SMS_CONSENT: { enabled: true },
    INGEST_TRANSACTIONAL_EVENTS: { enabled: false },
    POSTBACK_EMAILS: { enabled: false },
    SYNC_HISTORICAL_PURCHASES: { enabled: true },
    SYNC_PRODUCTS: { enabled: true },
  };

  const validationConfig = {
    fields: {
      shopName: { required: true },
    },
  };

  return (
    <IntegrationForm
      loadingText="Connecting"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={() => fetchOAuthLinkMutation}
      onComplete={() => {}}
    >
      <IntegrationField
        name="shopName"
        type={IntegrationFieldType.TEXT}
        required={true}
        label="Shop Name"
        placeholder="example: siren-denim"
        css={{ width: '100%' }}
      />
      {children}
    </IntegrationForm>
  );
};
