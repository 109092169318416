import React from 'react';

import placeholderLogo from '../assets/vendor_logos/placeholder.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

export const segmentAnalyticsPgBrands: Vendor = {
  category: VendorCategory.OTHER,
  logo: placeholderLogo,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.SEGMENT_ANALYTICS_PG_BRANDS}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'writeKey',
            type: IntegrationFieldType.TEXT,
            label: 'Write Key can be found in Segment under your source settings.',
            required: true,
          }),
          IntegrationField.Config({
            name: 'initialOptStatus',
            type: IntegrationFieldType.TEXT,
            label: 'Initial Opt Status',
            required: true,
          }),
          IntegrationField.Config({
            name: 'phoneSubscriptionOptIndicator',
            type: IntegrationFieldType.TEXT,
            label: 'Phone Subscription Opt Status',
            required: true,
          }),
          IntegrationField.Config({
            name: 'marketingProgramNumber',
            type: IntegrationFieldType.TEXT,
            label: 'Marketing Program Number',
            required: true,
          }),
          IntegrationField.Config({
            name: 'countryCode',
            type: IntegrationFieldType.TEXT,
            label: 'Country Code',
            required: true,
          }),
          IntegrationField.Config({
            name: 'sourceId',
            type: IntegrationFieldType.TEXT,
            label: 'Source Id',
            required: true,
          }),
          IntegrationField.Config({
            name: 'phoneSubscriptionServiceName',
            type: IntegrationFieldType.TEXT,
            label: 'phone Subscription Service Name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'phoneSubscriptionOptNumber',
            type: IntegrationFieldType.TEXT,
            label: 'phone Subscription Opt Number',
            required: true,
          }),
          IntegrationField.Config({
            name: 'optTextPhone',
            type: IntegrationFieldType.TEXT,
            label: 'opt text Phone',
            required: true,
          }),
          IntegrationField.Config({
            name: 'textToJoinCodeValue',
            type: IntegrationFieldType.TEXT,
            label: 'text to join code value',
            required: false,
          }),
          IntegrationField.Config({
            name: 'attnPrefix',
            type: IntegrationFieldType.TEXT,
            label: 'attn prefix',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailSubscriptionServiceName',
            type: IntegrationFieldType.TEXT,
            label: 'email subscription service name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailSubscriptionOptNumber',
            type: IntegrationFieldType.TEXT,
            label: 'email subscription opt number',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailSubscriptionOptIndicator',
            type: IntegrationFieldType.TEXT,
            label: 'email subscription opt indicator',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailContactPointCategoryCode',
            type: IntegrationFieldType.TEXT,
            label: 'email contact point category code',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailValidContactPointIndicator',
            type: IntegrationFieldType.TEXT,
            label: 'email valid contact point indicator',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailRegistrationSource',
            type: IntegrationFieldType.TEXT,
            label: 'email registration source',
            required: false,
          }),
          IntegrationField.Config({
            name: 'emailSendTransWelcome',
            type: IntegrationFieldType.TEXT,
            label: 'email send trans welcome',
            required: false,
          }),
          IntegrationField.Config({
            name: 'eventType',
            type: IntegrationFieldType.TEXT,
            label: 'event type',
            required: true,
          }),
          IntegrationField.Config({
            name: 'languageCode',
            type: IntegrationFieldType.TEXT,
            label: 'language code',
            required: true,
          }),
          IntegrationField.Config({
            name: 'optTextEmail',
            type: IntegrationFieldType.TEXT,
            label: 'opt text Email',
            required: true,
          }),
          IntegrationField.Config({
            name: 'optId',
            type: IntegrationFieldType.TEXT,
            label: 'OptId',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.SEGMENT_ANALYTICS_PG_BRANDS}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'writeKey',
            type: IntegrationFieldType.TEXT,
            label: 'Write Key can be found in Segment under your source settings.',
            required: true,
          }),
          IntegrationField.Config({
            name: 'initialOptStatus',
            type: IntegrationFieldType.TEXT,
            label: 'Initial Opt Status',
            required: true,
          }),
          IntegrationField.Config({
            name: 'phoneSubscriptionOptIndicator',
            type: IntegrationFieldType.TEXT,
            label: 'Phone Subscription Opt Status',
            required: true,
          }),
          IntegrationField.Config({
            name: 'marketingProgramNumber',
            type: IntegrationFieldType.TEXT,
            label: 'Marketing Program Number',
            required: true,
          }),
          IntegrationField.Config({
            name: 'countryCode',
            type: IntegrationFieldType.TEXT,
            label: 'Country Code',
            required: true,
          }),
          IntegrationField.Config({
            name: 'sourceId',
            type: IntegrationFieldType.TEXT,
            label: 'Source Id',
            required: true,
          }),
          IntegrationField.Config({
            name: 'phoneSubscriptionServiceName',
            type: IntegrationFieldType.TEXT,
            label: 'phone Subscription Service Name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'phoneSubscriptionOptNumber',
            type: IntegrationFieldType.TEXT,
            label: 'phone Subscription Opt Number',
            required: true,
          }),
          IntegrationField.Config({
            name: 'optTextPhone',
            type: IntegrationFieldType.TEXT,
            label: 'opt text Phone',
            required: true,
          }),
          IntegrationField.Config({
            name: 'textToJoinCodeValue',
            type: IntegrationFieldType.TEXT,
            label: 'text to join code value',
            required: false,
          }),
          IntegrationField.Config({
            name: 'attnPrefix',
            type: IntegrationFieldType.TEXT,
            label: 'attn prefix',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailSubscriptionServiceName',
            type: IntegrationFieldType.TEXT,
            label: 'email subscription service name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailSubscriptionOptNumber',
            type: IntegrationFieldType.TEXT,
            label: 'email subscription opt number',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailSubscriptionOptIndicator',
            type: IntegrationFieldType.TEXT,
            label: 'email subscription opt indicator',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailContactPointCategoryCode',
            type: IntegrationFieldType.TEXT,
            label: 'email contact point category code',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailValidContactPointIndicator',
            type: IntegrationFieldType.TEXT,
            label: 'email valid contact point indicator',
            required: true,
          }),
          IntegrationField.Config({
            name: 'emailRegistrationSource',
            type: IntegrationFieldType.TEXT,
            label: 'email registration source',
            required: false,
          }),
          IntegrationField.Config({
            name: 'emailSendTransWelcome',
            type: IntegrationFieldType.TEXT,
            label: 'email send trans welcome',
            required: false,
          }),
          IntegrationField.Config({
            name: 'eventType',
            type: IntegrationFieldType.TEXT,
            label: 'event type',
            required: true,
          }),
          IntegrationField.Config({
            name: 'languageCode',
            type: IntegrationFieldType.TEXT,
            label: 'language code',
            required: true,
          }),
          IntegrationField.Config({
            name: 'optTextEmail',
            type: IntegrationFieldType.TEXT,
            label: 'opt text Email',
            required: true,
          }),
          IntegrationField.Config({
            name: 'optId',
            type: IntegrationFieldType.TEXT,
            label: 'OptId (Optional)',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'THIRD_PARTY_PROCESSOR_FORWARDER',
            label: 'Forward Subscribers to Segment PG BRANDS',
            subtext: 'Forward Subscribers to Segment PG BRANDS',
          }),
        ]}
      />
    );
  },
};
