import React from 'react';

import satisfilabsLogo from '../assets/vendor_logos/satisfilabs.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SatisfilabsSettingsForm } from '../components/SettingsStandard/SatisfilabsSettingsForm';
import { SetupConnectAndConfigure } from '../components/SetupConnectAndConfigure';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorSatisfilabs: Vendor = {
  logo: satisfilabsLogo,
  category: VendorCategory.CUSTOMER_EXPERIENCE,
  companyWebsite: 'https://satisfilabs.com/',
  infoPagesEnabled: true,
  learnSubtext: 'Deliver great customer service and offer support via text messaging.',
  jointValueProp:
    'Ensure your customers have a great experience by supporting them via text messaging. Our integration with Satisfi Labs, an AI-powered knowledge management platform, enables your customer service team to directly respond to text messages via text within the Satisfi Labs platform.',
  whatYoullNeedItems: ['A Satisfi Labs account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.CUSTOMER_SUPPORT,
      description:
        'All messages received from a customer through SMS are forwarded as ongoing threads to your chat system in Satisfi Labs.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/4406168482452',
      label: 'Satisfi Labs integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupConnectAndConfigure
        vendorKey={VendorKey.VENDOR_SATISFILABS}
        onComplete={onComplete}
        connectFields={[
          IntegrationField.Config({
            name: 'token',
            type: IntegrationFieldType.PASSWORD,
            label: 'Satisfi Labs Token',
            required: true,
          }),
          IntegrationField.Config({
            name: 'companyId',
            type: IntegrationFieldType.TEXT,
            label: 'Satisfi Labs Company Id',
            required: true,
          }),
          IntegrationField.Config({
            name: 'extBotId',
            type: IntegrationFieldType.TEXT,
            label: 'Satisfi Labs Bot Id',
            required: true,
          }),
        ]}
        configureFeatures={[
          IntegrationFeature.Config({
            type: 'CUSTOMER_SERVICE_OPT_IN',
            label: 'Support Confirmation',
            subtext:
              'Turn on Support Confirmation to allow customers to escalate their text message conversation to your customer support team.',
            initiallyEnabled: true,
            fields: [
              IntegrationField.Config({
                name: 'serviceVerificationKeyword',
                type: IntegrationFieldType.TEXT,
                label: 'Support Confirmation Keyword',
                subtext:
                  'Choose a keyword your customers can use to confirm they need assistance. Your default autoresponder must include this keyword.',
                placeholder: 'Support',
                required: true,
              }),
              IntegrationField.Config({
                name: 'serviceBeginMessage',
                type: IntegrationFieldType.MULTILINE,
                label: 'Acknowledgement Message',
                subtext:
                  'Your customers will receive this message after replying with the Support Confirmation keyword.',
                placeholder:
                  'Your request has been forwarded to our support team. We will get back to you as soon as possible during our business hours.',
                required: true,
              }),
              IntegrationField.Config({
                name: 'serviceEndMessage',
                type: IntegrationFieldType.MULTILINE,
                label: 'Resolution Message',
                subtext:
                  'Your customers will receive this message once the support ticket has been resolved.',
                placeholder:
                  'Your request has been resolved. If you need anything else, do not hesitate to reach out.',
                required: false,
              }),
            ],
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_SATISFILABS}
        onComplete={onComplete}
        form={({ onFormComplete }) => <SatisfilabsSettingsForm onComplete={onFormComplete} />}
      />
    );
  },
};
