import React from 'react';

import constantContactLogo from '../assets/vendor_logos/constant_contact.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOauthConnectAndConfigure } from '../components/SetupOauthConnectAndConfigure';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

export const vendorConstantContact: Vendor = {
  logo: constantContactLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  setupComponent(_onComplete) {
    return (
      <SetupOauthConnectAndConfigure
        oAuthKey="constantcontact"
        connectInstructions="Click the Connect button to link your Constant Contact account to Attentive."
        vendorKey={VendorKey.VENDOR_CONSTANT_CONTACT}
        configureFields={[
          IntegrationField.Config({
            name: 'list_memberships',
            type: IntegrationFieldType.TEXT,
            label: 'List Memberships (Comma Separated)',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_CONSTANT_CONTACT}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'list_memberships',
            type: IntegrationFieldType.TEXT,
            label: 'List Memberships (Comma Separated)',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward phone numbers',
            subtext: 'Forward any phone numbers collected by Attentive to Constant Contact.',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Constant Contact.',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
        ]}
      />
    );
  },
};
