import React, { FC } from 'react';

import { Heading, Stack, Text, Icon } from '@attentive/picnic';

import {
  IntegrationContextProvider,
  IntegrationContextBag,
} from '../../hooks/useIntegrationContext';
import { IntegrationDetailsResponse, VendorKey } from '../../types';
import { InstallationCard } from '../InstallationCard';
import { ErrorBanner } from '../SetupConnectAndConfigure/ErrorBanner';
import { LoadingScreen } from '../SetupConnectAndConfigure/LoadingScreen';
import { SuccessStep } from '../SetupConnectAndConfigure/SuccessStep';
import { SetupLogo } from '../SetupLogo';
import { TimeEstimation } from '../TimeEstimation';

import { ConnectForm } from './ConnectForm';

interface Props {
  onComplete?: (integrationDetails: IntegrationDetailsResponse) => void;
}

export const SetupSalesforceServiceCloud: FC<Props> = () => {
  return (
    <IntegrationContextProvider vendorKey={VendorKey.VENDOR_SALESFORCE_SERVICE_CLOUD}>
      {({
        vendorConfig,
        vendorDetails,
        isFetching,
        isFetchingError,
        isConnectStep,
        isSuccessStep,
      }: IntegrationContextBag) => {
        return (
          <InstallationCard>
            <InstallationCard.Header
              leftSlot={
                <Stack spacing="$space2" direction="horizontal" css={{ alignItems: 'center' }}>
                  <SetupLogo src={vendorConfig.logo} />
                  <Heading variant="sm">Connect {vendorDetails?.displayName}</Heading>
                </Stack>
              }
              rightSlot={
                isSuccessStep ? (
                  <Stack
                    direction="horizontal"
                    spacing="$space1"
                    css={{ alignItems: 'center', color: '$green700' }}
                  >
                    <Icon name="CircleCheckmark" />
                    <Text variant="caption">Completed</Text>
                  </Stack>
                ) : (
                  <TimeEstimation>2 mins</TimeEstimation>
                )
              }
            />
            <InstallationCard.Body>
              {isFetching && <LoadingScreen />}
              {isFetchingError && <ErrorBanner text="Unable to load setup module." />}
              {isConnectStep && <ConnectForm />}
              {isSuccessStep && <SuccessStep />}
            </InstallationCard.Body>
          </InstallationCard>
        );
      }}
    </IntegrationContextProvider>
  );
};
