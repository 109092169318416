import { IntegrationFeatureConfig } from '../components/IntegrationFeature';
import { IntegrationFieldConfig } from '../components/IntegrationField';

const buildFieldValidationConfig = (fields: IntegrationFieldConfig[]) => {
  return Object.fromEntries(
    fields.map((field) => [field.componentProps.name, { required: field.componentProps.required }])
  );
};

export const buildValidationConfig = ({
  fields = [],
  features = [],
}: {
  fields?: IntegrationFieldConfig[];
  features?: IntegrationFeatureConfig[];
}) => {
  return {
    fields: buildFieldValidationConfig(fields),
    features: Object.fromEntries(
      features.map((feature) => [
        feature.componentProps.type,
        buildFieldValidationConfig(feature.componentProps.fields || []),
      ])
    ),
  };
};
