import pick from 'lodash/pick';
import React, { FC } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { FormField, Link } from '@attentive/picnic';

import { useUpdateIntegrationSettingsMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFormFeatureValues, IntegrationFieldType } from '../../types';
import { IntegrationFeature } from '../IntegrationFeature';
import { IntegrationFeatureGroup } from '../IntegrationFeatureGroup';
import { IntegrationField } from '../IntegrationField';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

import { BridgeIdentifierFeatureToggle } from './BridgeIdentifierFeatureToggle';
import { BridgeIdentifierSubfeature } from './BridgeIdentifierSubfeature';
import { ShopifyAppEmbedButton } from './ShopifyAppEmbedButton';
import { ShopifyTerms } from './ShopifyTerms';

interface Props {
  onComplete: () => void;
  superUserEnabled: boolean;
}

export const ShopifySettingsForm: FC<Props> = ({ onComplete, superUserEnabled }) => {
  const { integrationDetailValues, integrationDetails } = useIntegrationContext();
  const emailMarketingToggleEnabled = useCompanyFeatureFlag('ENABLE_TRIGGERED_EMAIL');

  // pick out only the fields/features that this form actually shows from
  // the current state of what's in the backend. This ensures we only submit
  // the values that the form actually shows inputs for.
  const initialValues = pick(integrationDetailValues || {}, [
    'shopName',
    'apiKey',
    'apiPassword',
    'BRIDGE_IDENTIFIERS',
    'FEATURE_CREATE_MARKETING_EMAIL_SUBSCRIPTIONS',
    'FEATURE_SYNC_ABANDONED_CHECKOUTS',
    'FEATURE_SYNC_SMS_CONSENT',
    'INGEST_TRANSACTIONAL_EVENTS',
    'POSTBACK_EMAILS',
    'SYNC_HISTORICAL_PURCHASES',
    'SYNC_PRODUCTS',
  ]) as { [type: string]: IntegrationFormFeatureValues };

  const validationConfig = {
    fields: {
      shopName: { required: true },
    },
  };

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useUpdateIntegrationSettingsMutation}
      onComplete={onComplete}
    >
      <IntegrationField
        name="shopName"
        type={IntegrationFieldType.TEXT}
        required={true}
        label="Shop Name"
        subtext="Updating your Shop Name will disconnect your current shop and remove all of its segments, journeys and offers."
        placeholder="example: siren-denim"
        css={{ width: '100%' }}
      />
      <FormField>
        <FormField.Label>Activate your Attentive tag</FormField.Label>
        <FormField.HelperText>
          Your Attentive Tag may become disconnected from Shopify. To remedy this click on the
          button below to be taken to Shopify. <b>Be sure to click “save” on the Shopify site.</b>
        </FormField.HelperText>
        <ShopifyAppEmbedButton isSettings />
      </FormField>

      {integrationDetailValues?.apiKey && (
        <IntegrationField
          name="apiKey"
          type={IntegrationFieldType.TEXT}
          required={false}
          label="API Key"
          css={{ width: '100%' }}
        />
      )}

      {integrationDetailValues?.apiPassword && (
        <IntegrationField
          name="apiPassword"
          type={IntegrationFieldType.PASSWORD}
          required={false}
          label="API Password"
          css={{ width: '100%' }}
        />
      )}

      <IntegrationFeatureGroup label="Sync Shopify data">
        {superUserEnabled && (
          <>
            <IntegrationFeature type="SYNC_PRODUCTS" label="Product data" />
            <IntegrationFeature
              type="FEATURE_SYNC_ABANDONED_CHECKOUTS"
              label="Abandoned checkouts"
            />
            <BridgeIdentifierSubfeature type="SYNC_HISTORICAL_PURCHASES" label="Past purchases" />
          </>
        )}
        <BridgeIdentifierFeatureToggle
          type="BRIDGE_IDENTIFIERS"
          label="Customer data"
          companyVendorIntegrationId={integrationDetails?.companyVendorIntegrationId}
        />
      </IntegrationFeatureGroup>

      <IntegrationFeatureGroup label="Ingest transactional events" direction="vertical">
        <IntegrationFeature
          type="INGEST_TRANSACTIONAL_EVENTS"
          label="Send messages based on transactional events, by setting up a journey"
          subtext={
            <>
              Set up a journey{' '}
              <Link href="/journeys/list" target="_blank" rel="noopener noreferrer">
                here,
              </Link>{' '}
              to utilize these transactional events.
            </>
          }
        />
      </IntegrationFeatureGroup>

      <IntegrationFeatureGroup
        label="Collect subscribers via checkout and signup forms"
        direction="vertical"
      >
        <BridgeIdentifierSubfeature
          type="FEATURE_SYNC_SMS_CONSENT"
          label="Collect SMS marketing subscribers at checkout"
          subtext={
            <>
              ACTION REQUIRED: You must have an active Shopify sign-up unit assigned to a Welcome
              Journey to collect subscribers. Click{' '}
              <Link href="/subscribers/sign-up-units" target="_blank" rel="noopener noreferrer">
                here
              </Link>{' '}
              to complete signup.
            </>
          }
        />
        {emailMarketingToggleEnabled && (
          <BridgeIdentifierSubfeature
            type="FEATURE_CREATE_MARKETING_EMAIL_SUBSCRIPTIONS"
            label="Collect email marketing subscribers"
            subtext={
              <>
                ACTION REQUIRED: You must have an active Shopify sign-up unit to collect
                subscribers. Although a Welcome Journey is not required to collect email
                subscribers, we recommend you setting one up. Click{' '}
                <Link href="/subscribers/sign-up-units" target="_blank" rel="noopener noreferrer">
                  here
                </Link>{' '}
                to create a sign-up unit and optionally attach a Welcome Journey.
              </>
            }
          />
        )}
      </IntegrationFeatureGroup>

      {superUserEnabled && (
        <IntegrationFeatureGroup label="Forward emails to Shopify" direction="vertical">
          <IntegrationFeature
            type="POSTBACK_EMAILS"
            label="Automatically add all emails collected by Attentive to your Shopify store."
          />
        </IntegrationFeatureGroup>
      )}

      <ShopifyTerms />
      <SubmitButton text="Save" />
    </IntegrationForm>
  );
};
