import * as Collapsible from '@radix-ui/react-collapsible';
import { useField } from 'formik';
import { useAtomValue } from 'jotai';
import React, { FC } from 'react';

import { clientUIFeatureFlagsAtom, CompanyFeatureFlagNames } from '@attentive/acore-utils';
import {
  FormField as PicnicFormField,
  Box,
  Stack,
  PicnicCss,
  Checkbox,
  keyframes,
  styled,
} from '@attentive/picnic';

import { IntegrationVisibilityType } from '../../types';
import { IntegrationFieldConfig } from '../IntegrationField';

const open = keyframes({
  from: { height: 0, opacity: 0 },
  to: { height: 'var(--radix-collapsible-content-height)', opacity: 1 },
});

const close = keyframes({
  from: { height: 'var(--radix-collapsible-content-height)', opacity: 1 },
  to: { height: 0, opacity: 0 },
});

const CollapsibleContent = styled(Collapsible.Content, {
  overflow: 'hidden',
  '&[data-state="open"]': { animation: `${open} 200ms ease-out` },
  '&[data-state="closed"]': { animation: `${close} 200ms ease-out` },
});

export interface IntegrationFeatureProps {
  type: string;
  label: React.ReactNode;
  subtext?: React.ReactNode;
  fields?: IntegrationFieldConfig[];
  featureFlag?: CompanyFeatureFlagNames;
  css?: PicnicCss;
  visibility?: IntegrationVisibilityType;
  children?: React.ReactNode;
  onChange?: (val: boolean | 'indeterminate') => void;
  disabled?: boolean;
}

interface IntegrationFeatureConfigProps extends IntegrationFeatureProps {
  initiallyEnabled?: boolean;
}

export interface IntegrationFeatureConfig {
  (): JSX.Element;
  componentProps: IntegrationFeatureConfigProps;
}

const IntegrationFeatureComponent: FC<Omit<IntegrationFeatureProps, 'featureFlag'>> = ({
  type,
  label,
  subtext,
  fields = [],
  children,
  css = {},
  disabled = false,
  onChange,
}) => {
  const enabledName = `${type}.enabled`;
  const [{ value: isEnabled }, , { setValue }] = useField<boolean | 'indeterminate'>(enabledName);

  const updateFeatureValue = (val: boolean | 'indeterminate') => {
    setValue(val);
    if (onChange) onChange(val);
  };

  return (
    <Collapsible.Root
      open={isEnabled === 'indeterminate' ? false : isEnabled}
      style={{ width: '100%' }}
    >
      <Box css={{ ...css }}>
        <PicnicFormField layout="horizontal" css={{ display: 'block' }}>
          {subtext && (
            <PicnicFormField.HelperText css={{ margin: '$space2 0 0 $space5' }} color="subdued">
              {subtext}
            </PicnicFormField.HelperText>
          )}
          <Checkbox
            id={enabledName}
            name={enabledName}
            value="Feature handler"
            checked={isEnabled}
            onChange={updateFeatureValue}
            disabled={disabled}
            disabledVisually={disabled}
          >
            {label}
          </Checkbox>
        </PicnicFormField>
        <CollapsibleContent>
          {(fields.length || children) && (
            <Stack spacing="$space6" css={{ width: '100%', padding: '$space6 0 0 $space7' }}>
              {fields.map((field) => {
                const fullyQualifiedName = `${type}.${field.componentProps.name}`;
                return field({ name: fullyQualifiedName });
              })}
              {children && <Box css={{ width: '100%' }}>{children}</Box>}
            </Stack>
          )}
        </CollapsibleContent>
      </Box>
    </Collapsible.Root>
  );
};

const FeatureFlaggedIntegrationFeatureComponent: FC<IntegrationFeatureProps> = ({
  featureFlag,
  ...rest
}) => {
  const featureFlags = useAtomValue(clientUIFeatureFlagsAtom);
  if (
    !!featureFlag &&
    (!(featureFlag in featureFlags.company) || !featureFlags.company[featureFlag])
  ) {
    return null;
  }

  return <IntegrationFeatureComponent {...rest} />;
};

const IntegrationFeature = FeatureFlaggedIntegrationFeatureComponent as CompositeComponent;

const Config = (props: IntegrationFeatureConfigProps) => {
  const renderField = () => (
    <IntegrationFeature {...props} key={props.type} css={{ width: '100%' }} />
  );

  Object.assign(renderField, { componentProps: props });

  return renderField as IntegrationFeatureConfig;
};

type ComponentType = typeof FeatureFlaggedIntegrationFeatureComponent;
interface CompositeComponent extends ComponentType {
  Config: (props: IntegrationFeatureConfigProps) => IntegrationFeatureConfig;
}

IntegrationFeature.Config = Config;

export { IntegrationFeature };
