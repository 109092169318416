import React from 'react';

import responsysLogo from '../assets/vendor_logos/responsyslogo.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const responsys: Vendor = {
  logo: responsysLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://www.oracle.com/cx/marketing/campaign-management/',
  infoPagesEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contacts to Responsys.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Responsys so that you can start sending marketing emails immediately. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['A Responsys account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        "Automatically sync subscriber's emails with Responsys so that you can start sending marketing emails immediately.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360041418992',
      label: 'Responsys integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.RESPONSYS}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'username',
            type: IntegrationFieldType.TEXT,
            label: 'UserName',
            required: true,
          }),
          IntegrationField.Config({
            name: 'password',
            type: IntegrationFieldType.PASSWORD,
            label: 'Password',
            required: true,
          }),
          IntegrationField.Config({
            name: 'objectName',
            type: IntegrationFieldType.TEXT,
            label: 'List Name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'authApiEndpoint',
            type: IntegrationFieldType.TEXT,
            label: 'Auth API Endpoint',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.RESPONSYS}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'username',
            type: IntegrationFieldType.TEXT,
            label: 'UserName',
            required: true,
          }),
          IntegrationField.Config({
            name: 'password',
            type: IntegrationFieldType.PASSWORD,
            label: 'Password',
            required: true,
          }),
          IntegrationField.Config({
            name: 'objectName',
            type: IntegrationFieldType.TEXT,
            label: 'List Name',
            required: true,
          }),
          IntegrationField.Config({
            name: 'authApiEndpoint',
            type: IntegrationFieldType.TEXT,
            label: 'Auth API Endpoint',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Responsys.',
          }),
        ]}
      />
    );
  },
};
