import React from 'react';

import stampedLogo from '../assets/vendor_logos/stamped.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

export const vendorStamped: Vendor = {
  logo: stampedLogo,
  category: VendorCategory.LOYALTY,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_STAMPED}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'publicApiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'Public Api Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'privateApiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'Private Api Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'storeHash',
            type: IntegrationFieldType.TEXT,
            label: 'Store Hash',
            required: true,
          }),
          IntegrationField.Config({
            name: 'reason',
            type: IntegrationFieldType.TEXT,
            label: 'Reason',
            required: true,
          }),
          IntegrationField.Config({
            name: 'points',
            type: IntegrationFieldType.TEXT,
            label: 'Points',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_STAMPED}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'publicApiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'Public Api Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'privateApiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'Private Api Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'storeHash',
            type: IntegrationFieldType.TEXT,
            label: 'Store Hash',
            required: true,
          }),
          IntegrationField.Config({
            name: 'reason',
            type: IntegrationFieldType.TEXT,
            label: 'Reason',
            required: true,
          }),
          IntegrationField.Config({
            name: 'points',
            type: IntegrationFieldType.TEXT,
            label: 'Points',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Email Forwarding',
            subtext: 'Assign loyalty points when an email is registered with Stamped',
          }),
        ]}
      />
    );
  },
};
