import { useField } from 'formik';
import React from 'react';

import { Banner, Text } from '@attentive/picnic';

import { AD_ACCOUNT_ID_FIELD, AD_ACCOUNT_NAME_FIELD } from '../constants';

const ConnectedAdAccountBanner = () => {
  const [{ value: accountId }] = useField(AD_ACCOUNT_ID_FIELD);
  const [{ value: accountName }] = useField(AD_ACCOUNT_NAME_FIELD);

  return (
    <Banner role="generic" variant="success" iconName="CircleCheckmark" css={{ width: '100%' }}>
      <Text>
        <Text as="span" css={{ fontWeight: '$bold' }}>
          {accountName}
        </Text>
        <Text as="span"> - Ad account ID #{accountId}</Text>
      </Text>
    </Banner>
  );
};

export default ConnectedAdAccountBanner;
