import pick from 'lodash/pick';
import React, { FC } from 'react';

import { useUpdateIntegrationSettingsMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFormFeatureValues, IntegrationFieldType } from '../../types';
import { IntegrationFeature } from '../IntegrationFeature';
import { IntegrationField } from '../IntegrationField';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

interface Props {
  onComplete: () => void;
}

export const ZendeskSettingsForm: FC<Props> = ({ onComplete }) => {
  const { integrationDetailValues } = useIntegrationContext();

  // The legacy flow uses user/token credentials. The new flow uses OAuth accessToken credentials
  const legacy = (integrationDetailValues || {}).accessToken === undefined;

  if (legacy) {
    const validationConfig = {
      fields: {
        domain: { required: true },
        user: { required: true },
        token: { required: true },
      },
      features: {
        CUSTOMER_SERVICE_OPT_IN: {
          serviceVerificationKeyword: { required: true },
          serviceBeginMessage: { required: true },
        },
        SCRUB_MESSAGE_SIGNATURE: {
          signature_delimiter: { required: true },
        },
      },
    };

    // pick out only the fields/features that this form actually shows from
    // the current state of what's in the backend. This ensures we only submit
    // the values that the form actually shows inputs for.
    const initialValues = pick(integrationDetailValues || {}, [
      'domain',
      'user',
      'token',
      'CUSTOMER_SERVICE_OPT_IN',
      'SCRUB_MESSAGE_SIGNATURE',
    ]) as {
      [type: string]: IntegrationFormFeatureValues;
    };

    return (
      <IntegrationForm
        loadingText="Saving"
        initialValues={initialValues}
        validationConfig={validationConfig}
        mutation={useUpdateIntegrationSettingsMutation}
        onComplete={onComplete}
      >
        <IntegrationField
          name="user"
          type={IntegrationFieldType.TEXT}
          label="User Email"
          required={true}
          css={{ width: '100%' }}
        />
        <IntegrationField
          name="token"
          type={IntegrationFieldType.PASSWORD}
          label="API Token"
          required={true}
          css={{ width: '100%' }}
        />
        <IntegrationField
          name="domain"
          type={IntegrationFieldType.TEXT}
          label="Domain Name"
          required={true}
          css={{ width: '100%' }}
        />
        <IntegrationFeature
          type="CUSTOMER_SERVICE_OPT_IN"
          label="Support Confirmation"
          subtext="Turn on Support Confirmation to allow customers to escalate their text message conversation to your customer support team."
          css={{ width: '100%' }}
          fields={[
            IntegrationField.Config({
              name: 'serviceVerificationKeyword',
              type: IntegrationFieldType.TEXT,
              label: 'Support Confirmation Keyword',
              subtext:
                'Choose a keyword your customers can use to confirm they need assistance. Your default autoresponder must include this keyword.',
              placeholder: 'Support',
              required: true,
            }),
            IntegrationField.Config({
              name: 'serviceBeginMessage',
              type: IntegrationFieldType.MULTILINE,
              label: 'Acknowledgement Message',
              subtext:
                'Your customers will receive this message after replying with the Support Confirmation keyword.',
              placeholder:
                'Your request has been forwarded to our support team. We will get back to you as soon as possible during our business hours.',
              required: true,
            }),
            IntegrationField.Config({
              name: 'serviceEndMessage',
              type: IntegrationFieldType.MULTILINE,
              label: 'Resolution Message ',
              subtext:
                'Your customers will receive this message once the support ticket has been resolved.',
              placeholder:
                'Your request has been resolved. If you need anything else, do not hesitate to reach out.',
              required: false,
            }),
          ]}
        />
        <IntegrationFeature
          type="SCRUB_MESSAGE_SIGNATURE"
          label="Remove agent signature"
          subtext="Turn on 'remove agent signature' to exclude signatures from your customer service agents' SMS replies."
          fields={[
            IntegrationField.Config({
              name: 'signature_delimiter',
              type: IntegrationFieldType.TEXT,
              label: 'Signature delimiter',
              subtext:
                "Enter the symbol that separates your agents' SMS reply from their signature.",
              placeholder: '--',
              required: true,
            }),
          ]}
        />
        <SubmitButton text="Save changes" />
      </IntegrationForm>
    );
  }
  const validationConfig = {
    fields: {
      domain: { required: true },
    },
    features: {
      CUSTOMER_SERVICE_OPT_IN: {
        serviceVerificationKeyword: { required: true },
        serviceBeginMessage: { required: true },
      },
      SCRUB_MESSAGE_SIGNATURE: {
        signature_delimiter: { required: true },
      },
    },
  };

  // pick out only the fields/features that this form actually shows from
  // the current state of what's in the backend. This ensures we only submit
  // the values that the form actually shows inputs for.
  const initialValues = pick(integrationDetailValues || {}, [
    'domain',
    'CUSTOMER_SERVICE_OPT_IN',
    'SCRUB_MESSAGE_SIGNATURE',
  ]) as {
    [type: string]: IntegrationFormFeatureValues;
  };

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useUpdateIntegrationSettingsMutation}
      onComplete={onComplete}
    >
      <IntegrationField
        name="domain"
        type={IntegrationFieldType.TEXT}
        label="Domain Name"
        required={true}
        css={{ width: '100%' }}
        disabled={true}
      />
      <IntegrationFeature
        type="CUSTOMER_SERVICE_OPT_IN"
        label="Support Confirmation"
        subtext="Turn on Support Confirmation to allow customers to escalate their text message conversation to your customer support team."
        css={{ width: '100%' }}
        fields={[
          IntegrationField.Config({
            name: 'serviceVerificationKeyword',
            type: IntegrationFieldType.TEXT,
            label: 'Support Confirmation Keyword',
            subtext:
              'Choose a keyword your customers can use to confirm they need assistance. Your default autoresponder must include this keyword.',
            placeholder: 'Support',
            required: true,
          }),
          IntegrationField.Config({
            name: 'serviceBeginMessage',
            type: IntegrationFieldType.MULTILINE,
            label: 'Acknowledgement Message',
            subtext:
              'Your customers will receive this message after replying with the Support Confirmation keyword.',
            placeholder:
              'Your request has been forwarded to our support team. We will get back to you as soon as possible during our business hours.',
            required: true,
          }),
          IntegrationField.Config({
            name: 'serviceEndMessage',
            type: IntegrationFieldType.MULTILINE,
            label: 'Resolution Message ',
            subtext:
              'Your customers will receive this message once the support ticket has been resolved.',
            placeholder:
              'Your request has been resolved. If you need anything else, do not hesitate to reach out.',
            required: false,
          }),
        ]}
      />
      <IntegrationFeature
        type="SCRUB_MESSAGE_SIGNATURE"
        label="Remove agent signature"
        subtext="Turn on 'remove agent signature' to exclude signatures from your customer service agents' SMS replies."
        fields={[
          IntegrationField.Config({
            name: 'signature_delimiter',
            type: IntegrationFieldType.TEXT,
            label: 'Signature delimiter',
            subtext: "Enter the symbol that separates your agents' SMS reply from their signature.",
            placeholder: '--',
            required: true,
          }),
        ]}
      />
      <SubmitButton text="Save changes" />
    </IntegrationForm>
  );
};
