import React, { FC } from 'react';

import { API } from '@attentive/acore-utils';
import { useQuery } from '@attentive/data/react-query';
import { Banner, Box, LoadingIndicator, Stack } from '@attentive/picnic';

import { useConfigureIntegrationMutation } from '../../api';
import { getErrorMessage } from '../../api/utils';
import { GoogleContentApiSetupData, IntegrationFieldType } from '../../types';
import { IntegrationField } from '../IntegrationField';
import { DisconnectButton } from '../SetupConnectAndConfigure/DisconnectButton';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

const PU_MAX_INPUT_WIDTH = '600px';
const BASE_URL = '/integrations/google-content-api';

async function apiGet<T>(path: string) {
  const response = await API.get<T>(path);
  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return response.body!;
}

const fetchGoogleContentApiSetupData = async () => {
  return await apiGet<GoogleContentApiSetupData>(`${BASE_URL}/setup`);
};

const FieldEnabler: React.FC<{ arr: unknown; children: React.ReactNode }> = (props: {
  arr: unknown;
  children: React.ReactNode;
}) => {
  const isArray = Array.isArray(props.arr) && props.arr.length > 0;
  return (
    <Box
      css={{
        opacity: isArray ? '1' : '0.5',
        pointerEvents: isArray ? 'auto' : 'none',
        width: '100%',
        maxWidth: PU_MAX_INPUT_WIDTH,
      }}
    >
      {props.children}
    </Box>
  );
};

const MerchantIdSelect: React.FC = () => {
  const { data, isFetching, isLoading, isError } = useQuery(
    ['merchantIds'],
    fetchGoogleContentApiSetupData
  );
  const merchantIds = data?.merchantIds || [];

  if (isFetching || isLoading) {
    return <LoadingIndicator />;
  }

  if (isError) {
    return (
      <Banner css={{ width: '100%' }} variant="error">
        Failed to fetch your Google merchant ID's. Please disconnect and reconnect the integration,
        or try again later
      </Banner>
    );
  }

  return (
    <FieldEnabler arr={merchantIds}>
      <IntegrationField
        type={IntegrationFieldType.SELECT}
        required={true}
        options={merchantIds.map((merchantId) => {
          return { name: `${merchantId}`, value: `${merchantId}` };
        })}
        name="merchantId"
        label="Merchant ID"
      />
    </FieldEnabler>
  );
};

interface SetupGoogleContentApiSetupFormProps {
  onComplete: () => void;
}

export const SetupGoogleContentApiSetupForm: FC<SetupGoogleContentApiSetupFormProps> = ({
  onComplete,
}) => {
  const initialValues = {
    merchantId: '',
  };

  const validationConfig = {
    fields: {
      merchantId: { required: true },
    },
  };

  return (
    <IntegrationForm
      loadingText="Configuring"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useConfigureIntegrationMutation}
      onComplete={onComplete}
    >
      <MerchantIdSelect />
      <Stack direction="horizontal" spacing="$space6">
        <SubmitButton text="Submit" />
        <DisconnectButton />
      </Stack>
    </IntegrationForm>
  );
};
