import React from 'react';

import adobeCampaignsLogo from '../assets/vendor_logos/adobecampaign.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

export const vendorAdobe: Vendor = {
  logo: adobeCampaignsLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_ADOBE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'username',
            type: IntegrationFieldType.TEXT,
            label: 'Username',
            required: true,
          }),
          IntegrationField.Config({
            name: 'password',
            type: IntegrationFieldType.PASSWORD,
            label: 'Password',
            required: true,
          }),
          IntegrationField.Config({
            name: 'tableName',
            type: IntegrationFieldType.TEXT,
            label: 'UQL Table Name',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_ADOBE}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'username',
            type: IntegrationFieldType.TEXT,
            label: 'Username',
            required: true,
          }),
          IntegrationField.Config({
            name: 'password',
            type: IntegrationFieldType.PASSWORD,
            label: 'Password',
            required: true,
          }),
          IntegrationField.Config({
            name: 'tableName',
            type: IntegrationFieldType.TEXT,
            label: 'UQL Table Name',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            label: 'Forward phones',
            subtext: 'Forward any phone numbers collected by Attentive to Adobe',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Adobe',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
        ]}
      />
    );
  },
};
