import React from 'react';

import smileioLogo from '../assets/vendor_logos/vendor_smileio.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

const connectFields = [
  IntegrationField.Config({
    name: 'apiKey',
    type: IntegrationFieldType.PASSWORD,
    label: 'Secret Key',
    placeholder: 'int_0abcdefghijk',
    required: true,
    dataIntegrationsId: 'Smile.io secret key',
  }),
];

export const vendorSmileio: Vendor = {
  logo: smileioLogo,
  category: VendorCategory.LOYALTY,
  companyWebsite: 'https://smile.io/',
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext: 'Send messages based on loyalty events.',
  jointValueProp:
    'Use the Smile.io and Attentive integration to send targeted messaging based on loyalty events and attributes to encourage repeat purchases and drive more referrals.',
  whatYoullNeedItems: ['An enterprise Smile.io account'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description:
        'Target subscribers by creating segments based on loyalty points, loyalty tier, referral URL, and member status',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/12259237666964',
      label: 'Smile.io integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_SMILE_IO}
        onComplete={onComplete}
        fields={connectFields}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_SMILE_IO}
        onComplete={onComplete}
        fields={connectFields}
        superUserEnabled={superUserEnabled}
        features={[
          IntegrationFeature.Config({
            type: 'FEATURE_SYNC_USER_PROFILES',
            label: 'Sync Subscriber Profiles',
            subtext: 'Download Smile.io user profile data',
            visibility: IntegrationVisibilityType.SUPER_USER,
            initiallyEnabled: true,
          }),
        ]}
      />
    );
  },
};
