import React from 'react';

import emarsysLogo from '../assets/vendor_logos/emarsys.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  IntegrationFieldType,
  IntegrationVisibilityType,
  Vendor,
  VendorCategory,
  VendorKey,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const emarsys: Vendor = {
  logo: emarsysLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://emarsys.com/',
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext:
    'Grow your email list through Attentive sign-up forms and sync contacts to Emarsys.',
  jointValueProp:
    "Grow your text marketing list and your email list at the same time using Attentive's best-in-class sign-up forms. Customers can opt into both email and text marketing in the same sign-up flow. Our integration ensures that emails will automatically sync to Emarsys so that you can start sending marketing emails immediately. You can also sync subscriber coupon codes to Emarsys. This integration will also help you keep your contact data and opt-in status up-to-date.",
  whatYoullNeedItems: ['An Emarsys account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        "Automatically sync subscriber's emails with Emarsys so that you can start sending marketing emails immediately.",
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360041419112',
      label: 'Emarsys integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.EMARSYS}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'username',
            type: IntegrationFieldType.TEXT,
            label: 'Username',
            required: true,
          }),
          IntegrationField.Config({
            name: 'keyId',
            type: IntegrationFieldType.TEXT,
            label: 'Key Id',
            placeholder: 'Optional: Default is already set to 3',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.EMARSYS}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'username',
            type: IntegrationFieldType.TEXT,
            label: 'Username',
            required: true,
          }),
          IntegrationField.Config({
            name: 'keyId',
            type: IntegrationFieldType.TEXT,
            label: 'Key Id',
            placeholder: 'Optional: Default is already set to 3',
            required: false,
          }),
          IntegrationField.Config({
            name: 'creativeFieldId',
            type: IntegrationFieldType.TEXT,
            label: 'Creative Field Id',
            placeholder:
              'Optional: If populated this field will be updated with the Attentive creative id',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Emarsys.',
          }),
          IntegrationFeature.Config({
            type: 'POST_WITH_PHONE',
            visibility: IntegrationVisibilityType.SUPER_USER,
            label: 'Forward phone',
            subtext:
              'Forward phone numbers to Emarsys. Please request approval from a Director, Client Strategy before enabling this setting.',
            fields: [
              IntegrationField.Config({
                name: 'smsOptInId',
                type: IntegrationFieldType.TEXT,
                label: 'SMS Opt-in ID',
                required: false,
              }),
            ],
          }),
        ]}
      />
    );
  },
};
