import React from 'react';

import intercomLogo from '../assets/vendor_logos/intercom.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOauthConnectAndConfigure } from '../components/SetupOauthConnectAndConfigure';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorIntercom: Vendor = {
  logo: intercomLogo,
  category: VendorCategory.CUSTOMER_EXPERIENCE,
  companyWebsite: 'https://www.intercom.com/',
  infoPagesEnabled: true,
  learnSubtext: 'Deliver great customer service and offer support via text messaging.',
  jointValueProp:
    'Ensure your customers have a great experience by supporting them via text messaging. Our integration with Intercom, a customer service platform, enables your customer service team to directly respond to text messages via text within the Intercom platform.',
  whatYoullNeedItems: ['An Intercom account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.CUSTOMER_SUPPORT,
      description:
        'All messages received from a customer through SMS are forwarded to your customer support team in Intercom.',
    },
  ],
  helpLinks: [
    {
      href: 'https://docs.attentive.com/pages/developer-guides/third-party-integrations/customer-service-platforms/intercom/',
      label: 'Intercom integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOauthConnectAndConfigure
        vendorKey={VendorKey.VENDOR_INTERCOM}
        onComplete={onComplete}
        connectInstructions="Click the Connect button to link your Intercom account to Attentive."
        configureFeatures={[
          IntegrationFeature.Config({
            type: 'CUSTOMER_SERVICE_OPT_IN',
            label: 'Support Confirmation',
            subtext:
              'Turn on Support Confirmation to allow customers to escalate their text message conversation to your customer support team.',
            initiallyEnabled: true,
            fields: [
              IntegrationField.Config({
                name: 'serviceVerificationKeyword',
                type: IntegrationFieldType.TEXT,
                label: 'Support Confirmation Keyword',
                subtext:
                  'Choose a keyword your customers can use to confirm they need assistance. Your default autoresponder must include this keyword.',
                placeholder: 'Support',
                required: true,
              }),
              IntegrationField.Config({
                name: 'serviceBeginMessage',
                type: IntegrationFieldType.MULTILINE,
                label: 'Acknowledgement Message',
                subtext:
                  'Your customers will receive this message after replying with the Support Confirmation keyword.',
                placeholder:
                  'Your request has been forwarded to our support team. We will get back to you as soon as possible during our business hours.',
                required: true,
              }),
              IntegrationField.Config({
                name: 'serviceEndMessage',
                type: IntegrationFieldType.MULTILINE,
                label: 'Resolution Message',
                subtext:
                  'Your customers will receive this message once the support ticket has been resolved.',
                placeholder:
                  'Your request has been resolved. If you need anything else, do not hesitate to reach out.',
                required: false,
              }),
            ],
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_INTERCOM}
        onComplete={onComplete}
        fields={[]}
        features={[
          IntegrationFeature.Config({
            type: 'CUSTOMER_SERVICE_OPT_IN',
            label: 'Support Confirmation',
            subtext:
              'Turn on Support Confirmation to allow customers to escalate their text message conversation to your customer support team.',
            fields: [
              IntegrationField.Config({
                name: 'serviceVerificationKeyword',
                type: IntegrationFieldType.TEXT,
                label: 'Support Confirmation Keyword',
                subtext:
                  'Choose a keyword your customers can use to confirm they need assistance. Your default autoresponder must include this keyword.',
                placeholder: 'Support',
                required: true,
              }),
              IntegrationField.Config({
                name: 'serviceBeginMessage',
                type: IntegrationFieldType.MULTILINE,
                label: 'Acknowledgement Message',
                subtext:
                  'Your customers will receive this message after replying with the Support Confirmation keyword.',
                placeholder:
                  'Your request has been forwarded to our support team. We will get back to you as soon as possible during our business hours.',
                required: true,
              }),
              IntegrationField.Config({
                name: 'serviceEndMessage',
                type: IntegrationFieldType.MULTILINE,
                label: 'Resolution Message',
                subtext:
                  'Your customers will receive this message once the support ticket has been resolved.',
                placeholder:
                  'Your request has been resolved. If you need anything else, do not hesitate to reach out.',
                required: false,
              }),
            ],
          }),
        ]}
      />
    );
  },
};
