import { useField } from 'formik';
import React from 'react';

import { API } from '@attentive/acore-utils';
import { useQuery } from '@attentive/data/react-query';

import { getErrorMessage } from '../../api/utils';
import { IntegrationFieldType } from '../../types';
import { IntegrationField } from '../IntegrationField';

const KLAVIYO_API_BASE = '/integrations/klaviyo';

type KlaviyoList = {
  id: string;
  name: string;
};

async function fetchLists() {
  // GMRU: GET /integrations/klaviyo/lists
  const response = await API.get<KlaviyoList[]>(`${KLAVIYO_API_BASE}/lists`);
  if (response.status >= 300) {
    throw new Error(getErrorMessage(response));
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return response.body!;
}

interface Props {
  label: string;
  subtext?: string | React.ReactNode;
}

export const ListField: React.FC<Props> = ({ label }) => {
  const FIELD_NAME = 'listId';
  const [, { value: listIdValue }, { setError: setErrorValue, setTouched: setTouchedValue }] =
    useField(FIELD_NAME);

  const { data: lists } = useQuery(['lists'], () => {
    return fetchLists().catch(() => {
      setTouchedValue(true);
      setErrorValue("Couldn't retrieve lists.");

      // If a value was previously saved, set that as an
      // option so that it shows up in the input even if
      // we fail to retrieve any audiences.
      if (listIdValue) {
        return [{ id: listIdValue, name: '' }];
      }
    });
  });

  return (
    <IntegrationField
      type={IntegrationFieldType.SELECT}
      name={FIELD_NAME}
      label={label}
      placeholder="Select list"
      required={true}
      dataIntegrationsId="Klaviyo List ID"
      css={{ width: '100%' }}
      options={(lists || []).map((opt) => {
        return { name: `${opt.name} (${opt.id})`, value: opt.id };
      })}
    />
  );
};
