import React, { FC } from 'react';

import { Text, Stack } from '@attentive/picnic';

import { ShopifyAppEmbedButton } from './ShopifyAppEmbedButton';

export const ConfigureStep: FC = () => {
  return (
    <Stack>
      <Text variant="body" color="subdued">
        To finish activating the integration, our Attentive tag must be activated on your merchant
        site. Please click the link below, which will direct you to your Shopify Theme Editor with
        our tag activated.
      </Text>
      <Text variant="body">
        <b>Click save in the top right corner</b> on the window that opens, which will complete the
        install.
      </Text>
      <ShopifyAppEmbedButton />
    </Stack>
  );
};
