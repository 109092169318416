import React from 'react';

import { Icon, IconButton, SearchableSelect } from '@attentive/picnic';

import { FormMappingsByIdAction } from './MapSegmentToFBAudience';
import { useSegmentAudienceOptions } from './SegmentAudienceOptionsProvider';
import { SegmentAudienceMapping } from './types';

export type RowValue = Partial<SegmentAudienceMapping>;

type MappingRowProps = RowValue & {
  id: string;
  onChange: (action: FormMappingsByIdAction) => void;
};

const MappingRow = ({
  id,
  onChange,
  attentive_segment: attentiveSegment,
  facebook_audience: facebookAudience,
}: MappingRowProps) => {
  const { segmentOptions, audienceOptions, getOptionById } = useSegmentAudienceOptions();
  const showDeleteButton = !!attentiveSegment && !!facebookAudience;

  const handleSegmentSelect = (value: string) => {
    const selectOption = getOptionById(value, segmentOptions);

    onChange({
      type: 'updateRow',
      id,
      value: {
        attentive_segment: selectOption
          ? {
              name: selectOption.name,
              id: selectOption.id,
            }
          : undefined,
      },
    });
  };

  const handleFbAudienceSelect = (value: string) => {
    const selectOption = getOptionById(value, audienceOptions);

    onChange({
      type: 'updateRow',
      id,
      value: {
        facebook_audience: selectOption
          ? {
              name: selectOption.name,
              id: selectOption.id,
            }
          : undefined,
      },
    });
  };

  return (
    <>
      <SearchableSelect
        value={attentiveSegment?.id}
        onChange={handleSegmentSelect}
        placeholder="Choose a segment"
      >
        {segmentOptions.map((opt) => (
          <SearchableSelect.Item
            key={opt.id}
            value={opt.id}
            disabled={opt.isSelected && opt.id !== attentiveSegment?.id}
          >
            {opt.name}
          </SearchableSelect.Item>
        ))}
      </SearchableSelect>
      <Icon name="ArrowRight" size="small" aria-hidden="true" />
      <SearchableSelect
        value={facebookAudience?.id}
        onChange={handleFbAudienceSelect}
        placeholder="Choose a custom audience"
      >
        {audienceOptions.map((opt) => (
          <SearchableSelect.Item
            key={opt.id}
            value={opt.id}
            disabled={opt.isSelected && opt.id !== facebookAudience?.id}
          >
            {opt.name}
          </SearchableSelect.Item>
        ))}
      </SearchableSelect>
      {showDeleteButton ? (
        <IconButton
          onClick={() => onChange({ type: 'removeRow', id })}
          variant="basic"
          size="medium"
          iconName="X"
          description="Remove this mapping"
        />
      ) : (
        <div />
      )}
    </>
  );
};

export default MappingRow;
