import React from 'react';

import yotpoLogo from '../assets/vendor_logos/yotpo.png';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { YotpoSettingsForm } from '../components/SettingsStandard/YotpoSettingsForm';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  DistributionStatus,
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
} from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorYotpo: Vendor = {
  logo: yotpoLogo,
  category: VendorCategory.LOYALTY,
  distributionStatus: DistributionStatus.BETA,
  infoPagesEnabled: true,
  errorReportingEnabled: false,
  learnSubtext: 'Send personalized messages using Yotpo Loyalty data.',
  whatYoullNeedItems: [
    'A Yotpo Gold Account.',
    'A GUID from your Yotpo Account.',
    'An Api Key from your Yotpo Account.',
    'A custom action campaign made specifically for the Attentive integration.',
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/360041419132-Swell-Yotpo',
      label: 'How to set up the Yotpo integration',
    },
  ],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.LIST_GROWTH,
      description: 'Send new Attentive sign-ups to Yotpo automatically.',
    },
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Increase engagement with your loyalty program by triggering text messages after key events, like a change of loyalty tier/status, reward redemption and birthday/anniversary.',
    },
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description:
        "Create dynamic segments based on a customer's unique loyalty data, such as: loyalty status, loyalty tier, and reward points.",
    },
  ],
  disconnectText:
    'If you disconnect this integration, you will no longer receive Attentive signups in your Yotpo account.',
  disconnectConfirmationText:
    'Are you sure that you want to disconnect your Yotpo integration? If you disconnect this integration, you will no longer receive Attentive signups in your Yotpo account.',
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_YOTPO}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'guid',
            type: IntegrationFieldType.TEXT,
            label: 'GUID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'actionName',
            type: IntegrationFieldType.TEXT,
            label: 'Action Name',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_YOTPO}
        onComplete={onComplete}
        form={({ onFormComplete }) => <YotpoSettingsForm onComplete={onFormComplete} />}
      />
    );
  },
};
