import React, { FC, ReactNode, ReactElement } from 'react';

import { NO_INTEGRATION_RESULTS } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationDetailsResponse } from '../../types';
import { IntegrationFeatureConfig } from '../IntegrationFeature';
import { IntegrationFieldConfig } from '../IntegrationField';

import { ErrorBanner } from './ErrorBanner';
import { LoadingScreen } from './LoadingScreen';
import { StandardConfigureForm } from './StandardConfigureForm';
import { StandardConnectForm } from './StandardConnectForm';
import { SuccessStep } from './SuccessStep';

interface ConnectFormParams {
  onConnectComplete: () => void;
}

interface ConfigureFormParams {
  onConfigureComplete: () => void;
}

export type SetupConnectAndConfigureInternalProps = {
  connectInstructions?: ReactNode;
  connectFields?: IntegrationFieldConfig[];
  connectForm?: (params: ConnectFormParams) => ReactElement;

  configureInstructions?: ReactNode;
  configureFields?: IntegrationFieldConfig[];
  configureFeatures?: IntegrationFeatureConfig[];
  configureForm?: (params: ConfigureFormParams) => ReactElement;

  onComplete?: (integrationDetails: IntegrationDetailsResponse) => void;
  superUserEnabled?: boolean;
  additionalAction?: React.ReactNode;
};

export const SetupConnectAndConfigureInternal: FC<SetupConnectAndConfigureInternalProps> = ({
  connectInstructions,
  connectFields,
  connectForm,
  configureInstructions,
  configureFields,
  configureForm,
  configureFeatures,
  onComplete,
  superUserEnabled,
  additionalAction,
}) => {
  if (!!connectForm && !!connectFields) {
    throw new Error(
      `👋 Attentive Engineer: You cannot provide both a connectForm function
      as well a connectFields array. Provide either A) a connectForm function
      that returns a custom connect form component or B) an array of
      IntegrationField.Config objects to render a standard connect form.`.replace(/\s+/g, ' ')
    );
  }

  if (!!configureForm && (!!configureFields || !!configureFeatures)) {
    throw new Error(
      `👋 Attentive Engineer: You cannot provide both a configureForm function
      as well a configureFields / configureFeatures arrays. Provide either A) a configureForm
      function that returns a custom configure form component or B) an array of
      IntegrationField.Config objects and/or and array of IntegrationFeature.Config objects
      to render a standard configure form.`.replace(/\s+/g, ' ')
    );
  }

  const hasConnectConfig = connectFields || connectForm;
  const hasConfigureConfig = configureFields || configureFeatures || configureForm;
  if (!hasConnectConfig || !hasConfigureConfig) {
    throw new Error(
      `👋 Attentive Engineer: You must provide configuration for both
      the connect step and the configure step. For the connect step,
      you can provide either A) connectFields or B) connectForm. For the
      configure step, you can provide either A) configureFields and/or
      configureFeatures; or B) configureForm`.replace(/\s+/g, ' ')
    );
  }

  const {
    isFetching,
    isFetchingError,
    isConnectStep,
    isConfigureStep,
    isSuccessStep,
    refetchIntegrationDetails,
  } = useIntegrationContext();

  const onConnectComplete = () => {
    refetchIntegrationDetails();
  };

  const onConfigureComplete = async () => {
    const { data } = await refetchIntegrationDetails();
    if (onComplete) {
      const onCompleteData = data === NO_INTEGRATION_RESULTS ? undefined : data;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      onComplete(onCompleteData!);
    }
  };

  return (
    <>
      {isFetching && <LoadingScreen />}
      {isFetchingError && <ErrorBanner text="Unable to load setup module." />}

      {isConnectStep &&
        (connectForm ? (
          connectForm({ onConnectComplete })
        ) : (
          <StandardConnectForm
            instructions={connectInstructions}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            fields={connectFields!}
            onComplete={onConnectComplete}
            additionalAction={additionalAction}
          />
        ))}

      {isConfigureStep &&
        (configureForm ? (
          configureForm({ onConfigureComplete })
        ) : (
          <StandardConfigureForm
            instructions={configureInstructions}
            fields={configureFields}
            features={configureFeatures}
            onComplete={onConfigureComplete}
            superUserEnabled={superUserEnabled}
          />
        ))}

      {isSuccessStep && <SuccessStep />}
    </>
  );
};
