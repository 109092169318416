import React, { PropsWithChildren } from 'react';

import { Card, Box, PicnicCss } from '@attentive/picnic';
const wrapperCss = {
  padding: '$space0',
};

const InstallationCardComponent: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return <Card css={wrapperCss}>{children}</Card>;
};

const headerCss: PicnicCss = {
  py: '$space2',
  px: '$space4',
  '@bp1': {
    py: '$space4',
    px: '$space8',
  },
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '$bgInformationalDefault',
  borderBottom: 'solid 1px $borderDefault',
};
type HeaderProps =
  | {
      leftSlot: React.ReactNode;
      rightSlot: React.ReactNode;
      children?: never;
    }
  | {
      leftSlot?: never;
      rightSlot?: never;
      children: React.ReactNode;
    };

const Header: React.FC<HeaderProps> = ({ children, leftSlot, rightSlot }) => {
  if (children) return <Box css={headerCss}>{children}</Box>;
  return (
    <Box css={headerCss}>
      <Box css={{ display: 'flex', alignItems: 'center' }}>{leftSlot}</Box>
      <Box css={{ ml: 'auto' }}>{rightSlot}</Box>
    </Box>
  );
};

const bodyCss = {
  padding: '$space4',
  '@bp1': {
    padding: '$space8',
  },
};

const Body: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  return <Box css={bodyCss}>{children}</Box>;
};

type ComponentType = typeof InstallationCardComponent;
interface CompositeComponent extends ComponentType {
  Header: React.FC<PropsWithChildren<HeaderProps>>;
  Body: React.FC<PropsWithChildren<{}>>;
}

const InstallationCard = InstallationCardComponent as CompositeComponent;
InstallationCard.Header = Header;
InstallationCard.Body = Body;

export { InstallationCard };
