import pick from 'lodash/pick';
import React from 'react';

import { Box } from '@attentive/picnic';

import { useUpdateIntegrationSettingsMutation } from '../../../..';
import { useIntegrationContext } from '../../../../hooks/useIntegrationContext';
import { IntegrationForm } from '../../../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../../../SetupConnectAndConfigure/SubmitButton';
import { SEGMENT_FEATURE_TYPE, AD_ACCOUNT_ID_KEY, AD_ACCOUNT_NAME_KEY } from '../constants';

import AdAccountSelector from './AdAccountSelector';

interface Props {
  onComplete: () => void;
  superUserEnabled: boolean;
}

export const AdAccountSelectionForm = ({ onComplete }: Props) => {
  const { integrationDetailValues } = useIntegrationContext();

  const validationConfig = {
    features: {
      [SEGMENT_FEATURE_TYPE]: {
        [AD_ACCOUNT_ID_KEY]: { required: true },
        [AD_ACCOUNT_NAME_KEY]: { required: true },
      },
    },
  };

  const initialValues = pick(integrationDetailValues || {}, [SEGMENT_FEATURE_TYPE]);

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useUpdateIntegrationSettingsMutation}
      onComplete={onComplete}
    >
      <Box css={{ paddingTop: '$space2' }}>
        <AdAccountSelector />
      </Box>
      <SubmitButton text="Save changes" />
    </IntegrationForm>
  );
};
