import React from 'react';

import woocommerceLogo from '../assets/vendor_logos/woocommerce.png';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { WooCommerceConnectForm } from '../components/SetupConnectAndConfigure/WooCommerceConnectForm';
import { SetupOauthConnectAndConfigure } from '../components/SetupOauthConnectAndConfigure';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorWoocommerce: Vendor = {
  logo: woocommerceLogo,
  category: VendorCategory.ECOMMERCE,
  companyWebsite: 'https://woocommerce.com/',
  infoPagesEnabled: true,
  errorReportingEnabled: true,
  learnSubtext: 'Sync product and customer data to automate and personalize your SMS marketing.',
  jointValueProp:
    'Our WooCommerce integration allows you to sync your entire product catalog to Attentive so that you can send high-performing journeys such as back in stock, low inventory, and price drop. It also lets you segment your customers and branch journeys based on your customers’ purchasing and browsing activity.',
  whatYoullNeedItems: ['A WooCommerce account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Send high-performing journeys, including Back in Stock, Low Inventory, and Price Drop. Branch journeys based on product attributes or inventory – for example only send a message if the product is in stock.',
    },
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description:
        'Segment customers based on purchasing and browsing activity using product data such as name, category, tag, price, and other attributes.',
    },
  ],
  helpLinks: [
    {
      href: 'https://help.attentivemobile.com/hc/en-us/articles/8296440330004-WooCommerce/',
      label: 'WooCommerce integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOauthConnectAndConfigure
        oAuthKey={VendorKey.VENDOR_WOOCOMMERCE}
        connectInstructions="Click the Connect button to link your WooCommerce account to Attentive."
        vendorKey={VendorKey.VENDOR_WOOCOMMERCE}
        onComplete={onComplete}
        connectForm={({ oAuthState }) => <WooCommerceConnectForm oAuthState={oAuthState} />}
        configureFields={[]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_WOOCOMMERCE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'baseStoreUrl',
            type: IntegrationFieldType.TEXT,
            label: 'Base Store URL',
            required: true,
            disabled: true,
          }),
        ]}
      />
    );
  },
};
