import React, { useEffect } from 'react';

import { Box, Button, Text } from '@attentive/picnic';

import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import useSetMilestone, { MilestoneKey } from '../../hooks/useSetMilestone';

type SuccessStepProps = {
  milestonesCompleted?: MilestoneKey[];
};

export const SuccessStep = ({ milestonesCompleted }: SuccessStepProps) => {
  const { vendorDetails, integrationDetails } = useIntegrationContext();
  const setMilestonesComplete = useSetMilestone();

  useEffect(() => {
    if (milestonesCompleted && milestonesCompleted.length) {
      setMilestonesComplete(milestonesCompleted);
    }
  }, [milestonesCompleted, setMilestonesComplete]);

  return (
    <Box css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      <Text>Your {vendorDetails?.displayName} integration was set up successfully.</Text>
      <Button
        as="a"
        variant="basic"
        css={{ flexShrink: 0, ml: '$space3' }}
        href={`/integrations/${integrationDetails?.companyVendorIntegrationId}/manage/settings`}
      >
        Manage app settings
      </Button>
    </Box>
  );
};
