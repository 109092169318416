import React, { FC, ReactNode, ReactElement } from 'react';

import { Heading, Stack, Text, Icon } from '@attentive/picnic';

import { NO_INTEGRATION_RESULTS, useConfigureIntegrationMutation } from '../../api';
import { IntegrationFormError } from '../../api/integrations';
import {
  IntegrationContextProvider,
  IntegrationContextBag,
} from '../../hooks/useIntegrationContext';
import { IntegrationDetailsResponse, VendorKey } from '../../types';
import { InstallationCard } from '../InstallationCard';
import { IntegrationFieldConfig } from '../IntegrationField';
import { ErrorBanner } from '../SetupConnectAndConfigure/ErrorBanner';
import { LoadingScreen } from '../SetupConnectAndConfigure/LoadingScreen';
import { StandardConnectForm } from '../SetupConnectAndConfigure/StandardConnectForm';
import { SuccessStep } from '../SetupConnectAndConfigure/SuccessStep';
import { SetupLogo } from '../SetupLogo';
import { TimeEstimation } from '../TimeEstimation';

interface FormParams {
  onComplete: () => void;
}

interface Props {
  vendorKey: VendorKey;

  instructions?: ReactNode;
  fields?: IntegrationFieldConfig[];
  form?: (params: FormParams) => ReactElement;

  onComplete?: (integrationDetails: IntegrationDetailsResponse) => void;
}

export const SetupOneStepConnect: FC<Props> = ({
  vendorKey,
  instructions,
  fields,
  form,
  onComplete,
}) => {
  if (!!form && !!fields) {
    throw new Error(
      `👋 Attentive Engineer: You cannot provide both a form function
      as well a fields array. Either provide a form function
      that returns a custom form component or provide an array of
      IntegrationField.Config objects to render a standard form.`.replace(/\s+/g, ' ')
    );
  }

  const {
    mutateAsync: configureMutateAsync,
    isError: configureIsError,
    error: configureError,
    isLoading: configureIsLoading,
  } = useConfigureIntegrationMutation();

  const configureBaseError =
    configureIsError &&
    ((configureError as IntegrationFormError).baseError || (configureError as Error).message);

  return (
    <IntegrationContextProvider vendorKey={vendorKey}>
      {({
        vendorConfig,
        vendorDetails,
        isFetching,
        isFetchingError,
        isConnectStep,
        isSuccessStep,
        refetchIntegrationDetails,
      }: IntegrationContextBag) => {
        const onConnectComplete = async () => {
          await configureMutateAsync({
            vendor: vendorKey,
            payload: {
              fields: {},
              features: {},
            },
          });

          const { data } = await refetchIntegrationDetails();
          if (onComplete) {
            const onCompleteData = data === NO_INTEGRATION_RESULTS ? undefined : data;
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            onComplete(onCompleteData!);
          }
        };

        return (
          <InstallationCard>
            <InstallationCard.Header
              leftSlot={
                <Stack spacing="$space2" direction="horizontal" css={{ alignItems: 'center' }}>
                  <SetupLogo src={vendorConfig.logo} />
                  <Heading variant="sm">Connect {vendorDetails?.displayName}</Heading>
                </Stack>
              }
              rightSlot={
                isSuccessStep ? (
                  <Stack
                    direction="horizontal"
                    spacing="$space1"
                    css={{ alignItems: 'center', color: '$green700' }}
                  >
                    <Icon name="CircleCheckmark" />
                    <Text variant="caption">Completed</Text>
                  </Stack>
                ) : (
                  <TimeEstimation>2 mins</TimeEstimation>
                )
              }
            />
            <InstallationCard.Body>
              {isFetching && <LoadingScreen />}
              {isFetchingError && <ErrorBanner text="Unable to load setup module." />}

              {configureIsLoading && <LoadingScreen text="Connecting" />}
              {configureBaseError && <ErrorBanner text={configureBaseError} />}

              {isConnectStep &&
                !configureIsLoading &&
                (form ? (
                  form({ onComplete: onConnectComplete })
                ) : (
                  <StandardConnectForm
                    instructions={instructions}
                    fields={fields || []}
                    onComplete={onConnectComplete}
                  />
                ))}

              {isSuccessStep && <SuccessStep />}
            </InstallationCard.Body>
          </InstallationCard>
        );
      }}
    </IntegrationContextProvider>
  );
};
