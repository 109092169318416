import React from 'react';

import salesforceMarketingCloudLogo from '../assets/vendor_logos/salesforce-marketing-cloud.png';
import { SettingsStandard } from '../components/SettingsStandard';
import { SalesforceSettingsForm } from '../components/SettingsStandard/SalesforceSettingsForm';
import { SetupSalesforce } from '../components/SetupSalesforce';
import { VendorCategory, Vendor, VendorKey, CreativeSpecificDataKeyNames } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const salesforce: Vendor = {
  logo: salesforceMarketingCloudLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  companyWebsite: 'https://www.salesforce.com/products/marketing-cloud/overview/',
  errorReportingEnabled: true,
  infoPagesEnabled: true,
  learnSubtext: 'Sync contact data from Attentive to Salesforce Marketing Cloud.',
  jointValueProp:
    "Drive revenue and list growth by connecting Attentive to Salesforce Marketing Cloud. Use Attentive's high-performing sign-up forms to grow your text marketing list and your email list at the same time.  Our integration ensures that emails will automatically sync to Salesforce Marketing Cloud so that you can start sending marketing emails immediately.",
  whatYoullNeedItems: ['A Salesforce Marketing Cloud account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.SUBSCRIBERS,
      description:
        'Forward email addresses and phone numbers that are captured by Attentive to your SFMC account.',
    },
  ],
  helpLinks: [
    {
      href: 'https://docs.attentive.com/pages/developer-guides/third-party-integrations/email-service-providers/salesforce-api-event-postback/#salesforce-marketing-cloud-postback',
      label: 'Salesforce Marketing Cloud integration guide',
    },
  ],
  setupComponent(onComplete) {
    return <SetupSalesforce onComplete={onComplete} />;
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.SALESFORCE}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        form={({ onFormComplete }) => (
          <SalesforceSettingsForm onComplete={onFormComplete} superUserEnabled={superUserEnabled} />
        )}
      />
    );
  },
  creativeSpecificSources: { type: 'compact-value', key: CreativeSpecificDataKeyNames.VALUE },
};
