import { Response } from '@attentive/acore-utils';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorMessage(response: Response<any>): string {
  const { body, bodyText } = response;
  if (body && body.message) {
    if (body.error) {
      return `${body.error}: ${body.message}`;
    }
    return body.message;
  }
  return bodyText;
}
