import pick from 'lodash/pick';
import React, { FC } from 'react';

import { Button } from '@attentive/picnic';

import { useUpdateIntegrationSettingsMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFormFeatureValues, IntegrationFieldType } from '../../types';
import { IntegrationFeature } from '../IntegrationFeature';
import { IntegrationField } from '../IntegrationField';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

interface Props {
  onComplete: () => void;
}

export const SalesforceServiceCloudSettingsForm: FC<Props> = ({ onComplete }) => {
  const { integrationDetailValues } = useIntegrationContext();

  // pick out only the fields/features that this form actually shows from
  // the current state of what's in the backend. This ensures we only submit
  // the values that the form actually shows inputs for.
  const initialValues = pick(integrationDetailValues || {}, [
    'POSTBACK_EMAILS',
    'FORWARD_MESSAGE_CUSTOMER_SERVICE',
    'CUSTOMER_SERVICE_OPT_IN',
  ]) as { [type: string]: IntegrationFormFeatureValues };

  const validationConfig = {
    features: {
      CUSTOMER_SERVICE_OPT_IN: {
        chatRestUrl: { required: true },
        deploymentId: { required: true },
        buttonId: { required: true },
        serviceVerificationKeyword: { required: true },
        serviceBeginMessage: { required: true },
      },
    },
  };

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useUpdateIntegrationSettingsMutation}
      onComplete={onComplete}
    >
      <IntegrationFeature
        type="POSTBACK_EMAILS"
        label="Contact Sync"
        subtext="Forward any emails collected by Attentive to Salesforce."
      />

      <IntegrationFeature
        type="CUSTOMER_SERVICE_OPT_IN"
        label="Enable Customer Support via SMS"
        subtext="This feature requires Salesforce Digital Engagement add-on. Please consult the help guide or reach out to your CSM to finish set up."
        fields={[
          IntegrationField.Config({
            name: 'chatRestUrl',
            type: IntegrationFieldType.TEXT,
            subtext: 'URL that lets you access data from your organization’s Live Agent sessions',
            required: true,
            label: 'Chat API Endpoint',
          }),
          IntegrationField.Config({
            name: 'deploymentId',
            type: IntegrationFieldType.TEXT,
            subtext: 'The ID of the deployment that the chat will originate from',
            required: true,
            label: 'Deployment Id',
          }),
          IntegrationField.Config({
            name: 'buttonId',
            type: IntegrationFieldType.TEXT,
            subtext: 'The ID of the button that the chat will originate from',
            required: true,
            label: 'Button Id',
          }),
          IntegrationField.Config({
            name: 'serviceVerificationKeyword',
            type: IntegrationFieldType.TEXT,
            subtext:
              'Choose a keyword your customers can use to confirm they need assistance. Your default autoresponder must include this keyword.',
            required: true,
            label: 'Support Confirmation Keyword',
            placeholder: 'Support',
          }),
          IntegrationField.Config({
            name: 'serviceBeginMessage',
            type: IntegrationFieldType.MULTILINE,
            subtext:
              'Your customers will receive this message after replying with the Support Confirmation keyword.',
            required: true,
            label: 'Acknowledgement Message',
            placeholder:
              'Your request has been forwarded to our support team. We will get back to you as soon as possible during our business hours.',
          }),
          IntegrationField.Config({
            name: 'serviceEndMessage',
            type: IntegrationFieldType.MULTILINE,
            subtext:
              'Your customers will receive this message once the support ticket has been resolved.',
            required: false,
            label: 'Resolution Message',
            placeholder:
              'Your request has been resolved. If you need anything else, do not hesitate to reach out.',
          }),
        ]}
      />

      <Button as="a" variant="basic" href="/tactical/salesforce-service-cloud">
        Configure Integration
      </Button>

      <SubmitButton text="Save changes" />
    </IntegrationForm>
  );
};
