import React, { FC } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';

import { VendorKey, IntegrationFieldType, IntegrationDetailsResponse } from '../../types';
import { IntegrationFeature } from '../IntegrationFeature';
import { IntegrationField } from '../IntegrationField';
import { SetupConnectAndConfigure } from '../SetupConnectAndConfigure';
import { SetupOauthConnectAndConfigure } from '../SetupOauthConnectAndConfigure';

const createConfigureFeatures = () => {
  return [
    IntegrationFeature.Config({
      type: 'CUSTOMER_SERVICE_OPT_IN',
      label: 'Support Confirmation',
      subtext:
        'Turn on Support Confirmation to allow customers to escalate their text message conversation to your customer support team.',
      initiallyEnabled: true,
      fields: [
        IntegrationField.Config({
          name: 'serviceVerificationKeyword',
          type: IntegrationFieldType.TEXT,
          label: 'Support Confirmation Keyword',
          subtext:
            'Choose a keyword your customers can use to confirm they need assistance. Your default autoresponder must include this keyword.',
          placeholder: 'Service',
          required: true,
        }),
        IntegrationField.Config({
          name: 'serviceBeginMessage',
          type: IntegrationFieldType.MULTILINE,
          label: 'Acknowledgment Message',
          subtext:
            'Your customers will receive this message after replying with the Support Confirmation keyword.',
          placeholder:
            "Your request has been forwarded to our support team. We'll get back to you as soon as possible during our business hours.",
          required: true,
        }),
        IntegrationField.Config({
          name: 'serviceEndMessage',
          type: IntegrationFieldType.MULTILINE,
          label: 'Resolution Message',
          subtext:
            'Your customers will receive this message once the support ticket has been resolved.',
          placeholder:
            "Your request has been resolved. If you need anything else, don't hesitate to reach out.",
          required: false,
        }),
      ],
    }),
    IntegrationFeature.Config({
      type: 'SCRUB_MESSAGE_SIGNATURE',
      label: 'Remove agent signature',
      subtext:
        "Turn on 'remove agent signature' to exclude signatures from your customer service agents' SMS replies.",
      fields: [
        IntegrationField.Config({
          name: 'signature_delimiter',
          type: IntegrationFieldType.TEXT,
          label: 'Signature delimiter',
          subtext: "Enter the symbol that separates your agents' SMS reply from their signature.",
          placeholder: '--',
          required: true,
        }),
      ],
    }),
  ];
};

interface Props {
  onComplete?: (integrationDetails: IntegrationDetailsResponse) => void;
}

export const ConnectForm: FC<Props> = ({ onComplete: onComplete }) => {
  const isZendeskOAuthEnabled = useCompanyFeatureFlag('ENABLE_ZENDESK_OAUTH');

  if (isZendeskOAuthEnabled) {
    return (
      <SetupOauthConnectAndConfigure
        connectInstructions="Click the Connect button below and login to Zendesk with an ADMIN Zendesk user."
        vendorKey={VendorKey.ZENDESK}
        onComplete={onComplete}
        connectFields={[
          IntegrationField.Config({
            name: 'domain',
            type: IntegrationFieldType.TEXT,
            label: 'Domain Name',
            required: true,
          }),
        ]}
        configureFeatures={createConfigureFeatures()}
      />
    );
  }
  return (
    <SetupConnectAndConfigure
      vendorKey={VendorKey.ZENDESK}
      onComplete={onComplete}
      connectFields={[
        IntegrationField.Config({
          name: 'user',
          type: IntegrationFieldType.TEXT,
          label: 'User Email',
          placeholder: 'user@domain.com',
          required: true,
        }),
        IntegrationField.Config({
          name: 'token',
          type: IntegrationFieldType.PASSWORD,
          label: 'API Token',
          required: true,
        }),
        IntegrationField.Config({
          name: 'domain',
          type: IntegrationFieldType.TEXT,
          label: 'Domain Name',
          required: true,
        }),
      ]}
      configureFeatures={createConfigureFeatures()}
    />
  );
};
