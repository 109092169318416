import isEmpty from 'lodash/isEmpty';
import React, { FC, ReactNode } from 'react';

import { Stack, Box } from '@attentive/picnic';

import { useConfigureIntegrationMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFormFeatureValues, VendorKey } from '../../types';
import { buildValidationConfig } from '../../utils';
import { IntegrationFeatureConfig } from '../IntegrationFeature';
import { IntegrationFieldConfig } from '../IntegrationField';

import { DisconnectButton } from './DisconnectButton';
import { IntegrationForm } from './IntegrationForm';
import { SubmitButton } from './SubmitButton';

interface Props {
  instructions?: ReactNode;
  fields?: IntegrationFieldConfig[];
  features?: IntegrationFeatureConfig[];
  onComplete: () => void;
  superUserEnabled?: boolean;
}

export const StandardConfigureForm: FC<Props> = ({
  instructions,
  fields = [],
  features = [],
  onComplete,
  superUserEnabled,
}) => {
  const { vendorKey, integrationDetailValues } = useIntegrationContext();
  if (isEmpty(fields) && isEmpty(features) && vendorKey !== VendorKey.VENDOR_BLOTOUT_WHITELABEL) {
    throw new Error(
      `👋 Attentive Engineer: You must provide either a fields array or a features array
      or both to render the standard configure form.`.replace(/\s+/g, ' ')
    );
  }

  const filterFeatureForSuperUser = features.filter(({ componentProps: { visibility } }) => {
    return (visibility === 'SuperUser' && superUserEnabled) || visibility !== 'SuperUser';
  });

  const integrationsToReusePreviousIntegrationFeatureData: [VendorKey] = [VendorKey.ZENDESK];
  const shouldUsePreviousIntegrationFeatureData: boolean =
    integrationsToReusePreviousIntegrationFeatureData.includes(vendorKey);

  const getFeatureFieldValue = (
    feature: IntegrationFeatureConfig,
    field: IntegrationFieldConfig
  ) => {
    const defaultValue = '';

    if (!shouldUsePreviousIntegrationFeatureData) {
      return defaultValue;
    }

    if (!integrationDetailValues || !integrationDetailValues[feature.componentProps.type]) {
      return defaultValue;
    }

    const existingFeature: IntegrationFormFeatureValues = integrationDetailValues[
      feature.componentProps.type
    ] as IntegrationFormFeatureValues;

    if (!existingFeature[field.componentProps.name]) {
      return defaultValue;
    }

    return existingFeature[field.componentProps.name];
  };

  const getFeatureEnabledValue = (feature: IntegrationFeatureConfig) => {
    const defaultValue = !!feature.componentProps.initiallyEnabled;

    if (!shouldUsePreviousIntegrationFeatureData) {
      return defaultValue;
    }

    if (!integrationDetailValues || !integrationDetailValues[feature.componentProps.type]) {
      return defaultValue;
    }

    const existingFeatureValue = integrationDetailValues[
      feature.componentProps.type
    ] as IntegrationFormFeatureValues;

    if (!existingFeatureValue.enabled) {
      return defaultValue;
    }

    return existingFeatureValue.enabled;
  };

  const initialValues = Object.fromEntries([
    ...fields.map((field) => [field.componentProps.name, '']),
    ...filterFeatureForSuperUser.map((feature) => [
      feature.componentProps.type,
      {
        enabled: getFeatureEnabledValue(feature),
        ...Object.fromEntries(
          (feature.componentProps.fields || []).map((field) => [
            field.componentProps.name,
            getFeatureFieldValue(feature, field),
          ])
        ),
      },
    ]),
  ]);

  const validationConfig = buildValidationConfig({ fields, features: filterFeatureForSuperUser });

  return (
    <IntegrationForm
      loadingText="Configuring"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useConfigureIntegrationMutation}
      onComplete={onComplete}
    >
      {instructions && <Box>{instructions}</Box>}
      {fields.map((field) => field())}
      {filterFeatureForSuperUser.map((feature) => feature())}

      <Stack direction="horizontal" spacing="$space6">
        <SubmitButton text="Save and continue" dataIntegrationsId="Submit Integration Button" />
        <DisconnectButton />
      </Stack>
    </IntegrationForm>
  );
};
