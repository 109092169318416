import React from 'react';

import { API, Config, ToastType, useToast } from '@attentive/acore-utils';
import { useMutation } from '@attentive/data/react-query';
import { Stack, Button, Text, Heading } from '@attentive/picnic';

import { IntegrationGenerateAllTokensResponse } from '../../types';

import { GenerateTokenDialog } from './GenerateTokenDialog';
import { GenerateTokenValue } from './GenerateTokenValue';

type GenerateTokenProps = {
  heading: string;
  description: string;
  buttonText: string;
  generateTokenVendorSlug: string;
};

class TacticalAPI extends API {
  public static getAPIUrl() {
    return Config.get('tacticalApiUrl');
  }
}

type IntegrationGenerateRes = {
  tokens: IntegrationGenerateAllTokensResponse;
};

async function fetchGeneratedValues(vendorSlug: string) {
  // GMRU: POST /v1/integration/token/generate/${vendorSlug}/all
  const res = await TacticalAPI.post<IntegrationGenerateRes>(
    `/v1/integration/token/generate/${vendorSlug}/all`
  );
  return res.body?.tokens;
}

export const GenerateToken: React.FC<GenerateTokenProps> = ({
  heading,
  description,
  buttonText,
  generateTokenVendorSlug,
}) => {
  const initIntegrationRes: IntegrationGenerateAllTokensResponse = {};

  const [createToast] = useToast();
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [allTokens, setAllTokens] =
    React.useState<IntegrationGenerateAllTokensResponse>(initIntegrationRes);

  const { mutateAsync: getGeneratedValuesAsync } = useMutation(fetchGeneratedValues, {
    onSuccess: (data?: IntegrationGenerateAllTokensResponse) => {
      if (data) {
        setAllTokens(data);
      }
    },
    onError: () => {
      setShowDialog(false);
      createToast({
        type: ToastType.Error,
        title: 'Something went wrong.',
        text: 'Error requesting token for this integration',
      });
    },
  });

  const openDialog = () => {
    if (generateTokenVendorSlug) {
      getGeneratedValuesAsync(generateTokenVendorSlug);
      setShowDialog(true);
    } else {
      setShowDialog(false);
      createToast({
        type: ToastType.Error,
        title: 'Something went wrong.',
        text: 'Error creating request for this feature',
      });
    }
  };

  const tokenKeys = Object.keys(allTokens);

  return (
    <Stack>
      <Heading variant="md" color="subdued">
        {heading}
      </Heading>
      <Text>{description}</Text>

      <GenerateTokenDialog
        data-testid="token-dialog"
        showDialog={showDialog}
        handleClose={() => setShowDialog(false)}
        heading={heading}
        tokens={allTokens}
      />
      {tokenKeys.length ? (
        tokenKeys.map((key) => (
          <GenerateTokenValue token={allTokens[key].end_value} subtitle={key} key={key} />
        ))
      ) : (
        <Button onClick={openDialog}>{buttonText}</Button>
      )}
    </Stack>
  );
};
