import pick from 'lodash/pick';
import React, { FC } from 'react';

import { useCompanyFeatureFlag } from '@attentive/acore-utils';
import { Box } from '@attentive/picnic';

import { useUpdateIntegrationSettingsMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFormFeatureValues, IntegrationFieldType } from '../../types';
import { IntegrationFeature } from '../IntegrationFeature';
import { IntegrationField } from '../IntegrationField';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';
import { WebhookCallbackUrl } from '../WebhookCallbackUrl';

interface Props {
  onComplete: () => void;
}

export const AftershipSettingsForm: FC<Props> = ({ onComplete }) => {
  const newAftershipSetupEnabled = useCompanyFeatureFlag('ENABLE_NEW_AFTERSHIP_SETUP');

  const { integrationDetailValues } = useIntegrationContext();

  const validationConfig = {
    fields: {
      apiKey: { required: true },
      trackingPage: { required: true },
      webhookSecret: { required: true },
    },
  };

  // pick out only the fields/features that this form actually shows from
  // the current state of what's in the backend. This ensures we only submit
  // the values that the form actually shows inputs for.
  const initialValues = pick(integrationDetailValues || {}, [
    'apiKey',
    'trackingPage',
    'webhookSecret',
    'FEATURE_CREATE_TRANSACTIONAL_SUBSCRIPTIONS',
  ]) as { [type: string]: IntegrationFormFeatureValues };

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useUpdateIntegrationSettingsMutation}
      onComplete={onComplete}
    >
      {newAftershipSetupEnabled && (
        <Box css={{ width: '100%' }}>
          <WebhookCallbackUrl
            path="/v1/inbound-webhooks/aftership"
            subtext="Paste this into Aftership to get your Webhook Secret"
            useCentralizedInboundWebhookUrl={false}
          />
        </Box>
      )}

      <IntegrationField
        name="apiKey"
        type={IntegrationFieldType.PASSWORD}
        label="API Key"
        required={true}
        css={{ width: '100%' }}
      />
      <IntegrationField
        name="trackingPage"
        type={IntegrationFieldType.TEXT}
        label="Tracking Page"
        required={true}
        css={{ width: '100%' }}
      />
      <IntegrationField
        name="webhookSecret"
        type={IntegrationFieldType.TEXT}
        label="Webhook Secret"
        required={true}
        css={{ width: '100%' }}
      />

      <IntegrationFeature
        type="FEATURE_CREATE_TRANSACTIONAL_SUBSCRIPTIONS"
        label="Create Transactional Subscriptions"
        subtext="Create or opt in transactional subscriptions for incoming Aftership events"
        featureFlag="ENABLE_NEW_AFTERSHIP_SETUP"
        css={{ width: '100%' }}
      />

      <SubmitButton text="Save changes" />
    </IntegrationForm>
  );
};
