import React from 'react';

import maropostLogo from '../assets/vendor_logos/maropost.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

export const maropost: Vendor = {
  logo: maropostLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  categories: [
    { name: VendorCategory.EMAIL_SERVICE_PROVIDER, primary: true },
    { name: VendorCategory.ANALYTICS, primary: false },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.MAROPOST}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'accountId',
            type: IntegrationFieldType.TEXT,
            label: 'Account ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'authToken',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Token',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listId',
            type: IntegrationFieldType.TEXT,
            label: 'List ID',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.MAROPOST}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'accountId',
            type: IntegrationFieldType.TEXT,
            label: 'Account ID',
            required: true,
          }),
          IntegrationField.Config({
            name: 'authToken',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Token',
            required: true,
          }),
          IntegrationField.Config({
            name: 'listId',
            type: IntegrationFieldType.TEXT,
            label: 'List ID',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Maropost',
          }),
        ]}
      />
    );
  },
};
