import React from 'react';

import googleTagManagerLogo from '../assets/vendor_logos/googletagmanager.png';
import { GoogleTagManagerSettingsForm } from '../components/SettingsStandard/GoogleTagManagerSettingsForm';
import { SetupGoogleTagManager } from '../components/SetupGoogleTagManager';
import { SetupGoogleTagManagerInternals } from '../components/SetupGoogleTagManager/SetupGoogleTagManager';
import { SetupLogo } from '../components/SetupLogo';
import { IntegrationContextProvider } from '../hooks/useIntegrationContext';
import { VendorCategory, Vendor, VendorKey } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorGoogleTagManager: Vendor = {
  logo: googleTagManagerLogo,
  category: VendorCategory.OTHER,
  infoPagesEnabled: true,
  setupComponent(onComplete) {
    return (
      <SetupGoogleTagManager
        logo={<SetupLogo src={googleTagManagerLogo} />}
        heading="Connect Google Tag Manager"
        onComplete={onComplete}
      />
    );
  },
  setupComponentInternals(onComplete, extraText, _, oAuthState) {
    return (
      <IntegrationContextProvider vendorKey={VendorKey.VENDOR_GOOGLE_TAG_MANAGER}>
        <SetupGoogleTagManagerInternals
          onComplete={onComplete}
          extraText={extraText}
          oAuthState={oAuthState}
        />
      </IntegrationContextProvider>
    );
  },
  settingsComponent() {
    return <GoogleTagManagerSettingsForm />;
  },
  companyWebsite: 'https://tagmanager.google.com/',
  learnSubtext:
    'Google Tag Manager provides a seamless way to add the Attentive tag to your website.',
  jointValueProp:
    'Google Tag Manager provides a seamless way to add the Attentive tag to your website. With the click of a button, start capturing customer behavioral events including product views, add to cart, and purchases.',
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Seamlessly add the Attentive tag to your website through the Google tag manager to trigger journeys based on product view, add to cart, and purchase events.',
    },
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description:
        'Seamlessly add the Attentive tag to your website through the Google tag manager to create customer segments based on their product view, add to cart, and purchase events.',
    },
  ],
  helpLinks: [
    {
      href: 'https://support.google.com/tagmanager/answer/6103696?hl=en#zippy=%2Cweb-pages',
      label: 'Setup and install Tag Manager',
    },
    {
      href: 'https://docs.attentive.com/pages/developer-guides/tag-management/google-tag-manager/google-tag-manager/',
      label: 'Google Tag Manager integration guide',
    },
  ],
  whatYoullNeedItems: ['A Google Tag Manager account.'],
  disconnectText:
    'In order to prevent unexpected changes to your Google Tag Manager account, we will not remove the Attentive Tag from your Google Tag Manager account if you disconnect this integration.',
  disconnectConfirmationText:
    'Are you sure? You may need to manually remove the Attentive Tag from your Google Tag Manager account.',
};
