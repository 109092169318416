import React from 'react';

import postpilotLogo from '../assets/vendor_logos/postpilot.png';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { PostPilotSettingsForm } from '../components/SettingsStandard/PostPilotSettingsForm';
import { SetupConnectAndConfigure } from '../components/SetupConnectAndConfigure';
import { PostPilotConfigureForm } from '../components/SetupConnectAndConfigure/PostPilotConfigureForm';
import { SetupConnectAndConfigureInternal } from '../components/SetupConnectAndConfigure/SetupConnectAndConfigureInternal';
import { IntegrationContextProvider } from '../hooks';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

const connectFields = [
  IntegrationField.Config({
    name: 'apiKey',
    type: IntegrationFieldType.PASSWORD,
    label: 'API Key',
    placeholder: 'Enter API Key',
    required: true,
    dataIntegrationsId: 'PostPilot API Key',
  }),
];

export const vendorPostPilot: Vendor = {
  logo: postpilotLogo,
  category: VendorCategory.CUSTOMER_DATA_PLATFORM,

  setupComponent(onComplete, superUserEnabled) {
    return (
      <SetupConnectAndConfigure
        vendorKey={VendorKey.VENDOR_POST_PILOT}
        onComplete={onComplete}
        connectFields={connectFields}
        configureForm={({ onConfigureComplete }) => (
          <PostPilotConfigureForm
            onComplete={() => {
              onConfigureComplete();
            }}
            superUserEnabled={superUserEnabled || false}
          />
        )}
      />
    );
  },
  setupComponentInternals(onComplete, additionalAction, superUserEnabled) {
    return (
      <IntegrationContextProvider vendorKey={VendorKey.VENDOR_POST_PILOT}>
        <SetupConnectAndConfigureInternal
          onComplete={onComplete}
          connectFields={connectFields}
          configureForm={({ onConfigureComplete }) => (
            <PostPilotConfigureForm
              onComplete={() => {
                onConfigureComplete();
              }}
              superUserEnabled={superUserEnabled || false}
            />
          )}
          additionalAction={additionalAction}
        />
      </IntegrationContextProvider>
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_POST_PILOT}
        onComplete={onComplete}
        form={({ onFormComplete }) => (
          <PostPilotSettingsForm
            onComplete={onFormComplete}
            superUserEnabled={superUserEnabled || false}
          />
        )}
      />
    );
  },
};
