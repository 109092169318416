import { AttentiveFeatures, ATTENTIVE_FEATURE_TYPES } from '../types';

export const ATTENTIVE_FEATURES: AttentiveFeatures = {
  [ATTENTIVE_FEATURE_TYPES.JOURNEYS]: {
    type: ATTENTIVE_FEATURE_TYPES.JOURNEYS,
    displayName: 'Journeys',
    iconName: 'Workflow',
    buttonText: 'Go to journeys',
    buttonHref: '/journeys',
    hasButton: true,
  },
  [ATTENTIVE_FEATURE_TYPES.SEGMENTS]: {
    type: ATTENTIVE_FEATURE_TYPES.SEGMENTS,
    displayName: 'Segments',
    iconName: 'Segments',
    buttonText: 'Go to segments',
    buttonHref: '/segments',
    hasButton: true,
  },
  [ATTENTIVE_FEATURE_TYPES.OFFERS]: {
    type: ATTENTIVE_FEATURE_TYPES.OFFERS,
    displayName: 'Offers',
    iconName: 'Coupon',
    buttonText: 'Go to offers',
    buttonHref: '/offers',
    hasButton: true,
  },
  [ATTENTIVE_FEATURE_TYPES.SUBSCRIBERS]: {
    type: ATTENTIVE_FEATURE_TYPES.SUBSCRIBERS,
    displayName: 'Subscribers',
    iconName: 'PersonStacked',
    buttonText: 'Go to subscribers',
    buttonHref: '/subscribers',
    hasButton: true,
  },
  [ATTENTIVE_FEATURE_TYPES.LIST_GROWTH]: {
    type: ATTENTIVE_FEATURE_TYPES.LIST_GROWTH,
    displayName: 'List Growth',
    iconName: 'BarChart',
    hasButton: false,
  },
  [ATTENTIVE_FEATURE_TYPES.CUSTOMER_SUPPORT]: {
    type: ATTENTIVE_FEATURE_TYPES.CUSTOMER_SUPPORT,
    displayName: 'SMS Support',
    iconName: 'Headset',
    hasButton: false,
  },
  [ATTENTIVE_FEATURE_TYPES.PRIVACY]: {
    type: ATTENTIVE_FEATURE_TYPES.PRIVACY,
    displayName: 'Privacy',
    iconName: 'Lock',
    hasButton: false,
  },
};
