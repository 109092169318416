import React from 'react';

import segmentAnalyticsLogo from '../assets/vendor_logos/segmentanalyticslogo.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOauthConnectAndConfigure } from '../components/SetupOauthConnectAndConfigure';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

const createIngestFeature = () =>
  IntegrationFeature.Config({
    type: 'FEATURE_INGEST_USER_DATA',
    label: 'Ingest User Profiles and Events',
    subtext: 'Ingest User Profiles and Events',
  });

const createPostBackFeature = () =>
  IntegrationFeature.Config({
    type: 'FEATURE_POSTBACK_USER_DATA',
    label: 'Send Attentive data to Segment',
    subtext: 'Send Attentive data to Segment',
    initiallyEnabled: true,
    fields: [
      IntegrationField.Config({
        name: 'sourceWriteKey',
        type: IntegrationFieldType.PASSWORD,
        label: 'Source Write Key',
        required: true,
      }),
      IntegrationField.Config({
        name: 'userIdBackup',
        type: IntegrationFieldType.SELECT,
        label: 'User ID',
        subtext: 'What should Attentive use as the user ID for these events?',
        required: false,
        options: [
          {
            name: 'Hashed Uppercase User Email (sha256)',
            value: 'sha256UpperEmail',
          },
          {
            name: 'Saved User ID from Segment',
            value: 'noUserId',
          },
        ],
        featureFlag: 'ENABLE_SEGMENT_USERID_SELECT_FEATURE_FIELD',
      }),
    ],
  });

export const vendorSegment: Vendor = {
  logo: segmentAnalyticsLogo,
  category: VendorCategory.CUSTOMER_DATA_PLATFORM,
  categories: [
    { name: VendorCategory.CUSTOMER_DATA_PLATFORM, primary: true },
    { name: VendorCategory.ANALYTICS, primary: false },
  ],
  companyWebsite: 'https://segment.com/',
  infoPagesEnabled: true,
  helpLinks: [
    {
      href: 'https://docs.attentive.com/pages/developer-guides/third-party-integrations/customer-data-platforms/segment/',
      label: 'Segment integration guide',
    },
  ],
  learnSubtext:
    'Sync customer and event data from Segment to Attentive to send highly personalized messages.',
  jointValueProp:
    'Integrate with Segment to make it easy to sync customer and event data from Segment to Attentive so that you can send highly personalized and timely messages. Attentive users can leverage Segment subscriber attributes to build segments to send targeted SMS messages and event data to trigger messages based on user actions. This integration also sends Attentive subscriber data to Segment, such as SMS opt-in, SMS opt-out, and message sends to ensure Segment is up-to-date.',
  whatYoullNeedItems: ['A Segment account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.LIST_GROWTH,
      description: 'Send new Attentive sign-ups as well as opt-outs to Segment automatically.',
    },
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Trigger text messages based on events available in Segment, like a user logging into your mobile site, clicking on an email, purchasing a product, and many more.',
    },
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description:
        'Create dynamic segments based on customer attributes from Segment like user preferences, location, or Personas.',
    },
  ],
  setupComponent() {
    return (
      <SetupOauthConnectAndConfigure
        oAuthKey={VendorKey.VENDOR_SEGMENT}
        connectInstructions="Click the Connect button to link your Segment account to Attentive."
        vendorKey={VendorKey.VENDOR_SEGMENT}
        configureFeatures={[createPostBackFeature()]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_SEGMENT}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'affiliate_token',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            subtext:
              'This API Key can be used to install an Attentive Destination in the Segment UI.',
            required: true,
            disabled: true,
          }),
        ]}
        features={[createIngestFeature(), createPostBackFeature()]}
      />
    );
  },
};
