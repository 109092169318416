import { useField } from 'formik';
import uniqueId from 'lodash/uniqueId';
import React, { useReducer, useEffect, useMemo, useState } from 'react';

import { Box, Button, FormField, Heading, Icon, StandardDialog, styled } from '@attentive/picnic';

import CreateCustomAudienceDialog from './CreateCustomAudienceDialog';
import MappingRow, { RowValue } from './MappingRow';
import SegmentAudienceOptionsProvider from './SegmentAudienceOptionsProvider';
import { SegmentAudienceMapping } from './types';

import { MapSegmentToFBAudienceFragment_facebookAdsExternalVendorData$key } from './__generated__/MapSegmentToFBAudienceFragment_facebookAdsExternalVendorData.graphql';

const MapGrid = styled('section', {
  display: 'grid',
  gap: '$space4',
  gridTemplateColumns: '1fr $size5 1fr $size12',
  alignItems: 'center',
  paddingTop: '$space4',
});

const SEGMENT_GRID_COL = '1/3';
const AUDIENCE_GRID_COL = '3/5';

export type FormMappingsById = Record<string, RowValue>;

const createNewFormMapping = (
  prefix: string | number = '',
  value?: SegmentAudienceMapping
): FormMappingsById => ({
  [uniqueId(`${prefix}_`)]: value || {
    attentive_segment: undefined,
    facebook_audience: undefined,
  },
});

export type FormMappingsByIdAction =
  | { type: 'removeRow'; id: string }
  | { type: 'updateRow'; id: string; value: RowValue }
  | { type: 'addNewRow' };

function formMapReducer(state: FormMappingsById, action: FormMappingsByIdAction) {
  switch (action.type) {
    case 'removeRow':
      delete state[action.id];
      if (!Object.keys(state).length) {
        return createNewFormMapping(1);
      }
      return { ...state };
    case 'addNewRow':
      return {
        ...state,
        ...createNewFormMapping(Object.keys(state).length + 1),
      };
    case 'updateRow':
      return {
        ...state,
        [action.id]: {
          ...(state[action.id] || {}),
          ...action.value,
        },
      };
    default:
      return state;
  }
}

type MapSegmentToFBAudienceProps = {
  fieldKey: string;
  queryRef: MapSegmentToFBAudienceFragment_facebookAdsExternalVendorData$key;
};

const parseFieldValueIntoFormMappingsById = (map: string | undefined): FormMappingsById => {
  if (!map) return createNewFormMapping(1);

  const mapJson = JSON.parse(map) as SegmentAudienceMapping[];

  if (!mapJson.length) {
    return createNewFormMapping(1);
  }

  return mapJson.reduce(
    (newMap, rowValue, idx) => ({
      ...newMap,
      ...createNewFormMapping(idx, rowValue),
    }),
    {} as FormMappingsById
  );
};

const MapSegmentToFBAudience = ({ fieldKey, queryRef }: MapSegmentToFBAudienceProps) => {
  const [, { initialValue, error }, { setValue }] = useField<string | undefined>(fieldKey);
  const [formMap, dispatchEvent] = useReducer(
    formMapReducer,
    parseFieldValueIntoFormMappingsById(initialValue)
  );
  const isNewButtonDisabled = useMemo(
    () =>
      Object.values(formMap).some(
        (mapping) => !mapping.attentive_segment && !mapping.facebook_audience
      ),
    [formMap]
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Update the form value with only valid Mappings
  useEffect(() => {
    const formValue = Object.values(formMap).filter(
      (mapping) => !!mapping.attentive_segment && !!mapping.facebook_audience
    ) as SegmentAudienceMapping[];

    setValue(JSON.stringify(formValue));
  }, [formMap]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <SegmentAudienceOptionsProvider formMap={formMap} queryRef={queryRef}>
      <MapGrid>
        <Heading variant="sm" css={{ gridColumn: SEGMENT_GRID_COL }}>
          Attentive segment
        </Heading>
        <Heading variant="sm" css={{ gridColumn: AUDIENCE_GRID_COL }}>
          Facebook custom audience
        </Heading>
        {Object.keys(formMap).map((rowId) => (
          <MappingRow key={rowId} id={rowId} {...formMap[rowId]} onChange={dispatchEvent} />
        ))}
        <Box css={{ gridColumn: AUDIENCE_GRID_COL }}>
          <StandardDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
            <StandardDialog.Trigger>
              <Button
                size="small"
                variant="subdued"
                css={{ fontWeight: '$regular', minHeight: 'min-content' }}
              >
                Create a new custom audience
              </Button>
            </StandardDialog.Trigger>
            <CreateCustomAudienceDialog setDialogOpen={setIsDialogOpen} />
          </StandardDialog>
        </Box>
        <Box>
          <Button
            variant="basic"
            onClick={() => dispatchEvent({ type: 'addNewRow' })}
            css={{ gap: '$space2' }}
            disabled={isNewButtonDisabled}
          >
            <Icon name="PlusSign" size="extraSmall" aria-hidden="true" />
            <span>Create mapping</span>
          </Button>
        </Box>
      </MapGrid>
      {error && (
        <FormField.ErrorText css={{ paddingTop: '$space4' }}>
          A valid mapping is required
        </FormField.ErrorText>
      )}
    </SegmentAudienceOptionsProvider>
  );
};

export default MapSegmentToFBAudience;
