import { useField } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLazyLoadQuery } from 'react-relay';

import { useCurrentCompanyId } from '@attentive/acore-utils';
import { FacebookAdAccount } from '@attentive/data/types';
import { Box, Heading, Select, Text } from '@attentive/picnic';

import { FormField } from '../../../FormField';
import { SEGMENT_FEATURE_TYPE, AD_ACCOUNT_ID_FIELD, AD_ACCOUNT_NAME_FIELD } from '../constants';

import TermsOfServiceError from './TermsOfServiceError';

import AdAccountSelectorQuery, {
  AdAccountSelectorQuery as AdAccountSelectorQueryType,
} from './__generated__/AdAccountSelectorQuery.graphql';

const AdAccountSelector = () => {
  const companyId = useCurrentCompanyId();
  const [{ onBlur, value }, { touched, error }, { setValue: setAccountId }] = useField<
    string | undefined
  >(AD_ACCOUNT_ID_FIELD);
  const [{ value: isFeatureEnabled }, , { setValue: setParentFeatureEnabled }] = useField<
    boolean | undefined
  >(`${SEGMENT_FEATURE_TYPE}.enabled`);
  const [, , { setValue: setAccountName }] = useField<string | undefined>(AD_ACCOUNT_NAME_FIELD);
  const queryData = useLazyLoadQuery<AdAccountSelectorQueryType>(AdAccountSelectorQuery, {
    companyId,
  });
  const adAccounts =
    (queryData?.company?.companyVendorIntegrations &&
      queryData.company.companyVendorIntegrations[0].externalVendorData?.data.adAccounts) ||
    [];

  // The ad account is stored under a feature so the feature needs to be enabled at all times
  useEffect(() => {
    if (!isFeatureEnabled) setParentFeatureEnabled(true);
  }, [isFeatureEnabled, setParentFeatureEnabled]);

  const [
    selectedAdAccountWithTermsOfServiceDisabled,
    setSelectedAdAccountWithTermsOfServiceDisabled,
  ] = useState<FacebookAdAccount | undefined>(undefined);

  const handleSelectChange = (val: string) => {
    const selectedAdAccount = adAccounts.find((adAccount) => adAccount.id === val);
    if (selectedAdAccount) {
      if (!selectedAdAccount.tosAccepted) {
        setSelectedAdAccountWithTermsOfServiceDisabled(selectedAdAccount);
        setAccountId(undefined);
        setAccountName(undefined);
        return;
      }

      setSelectedAdAccountWithTermsOfServiceDisabled(undefined);
      setAccountId(selectedAdAccount.id);
      setAccountName(selectedAdAccount.name);
    }
  };

  return (
    <Box>
      <Heading as="h2" variant="md">
        Select Facebook ad account
      </Heading>
      <Text color="subdued" css={{ padding: '$space2 0 $space4' }}>
        Attentive will map your segments to any Facebook custom audience you choose. You can also
        create a new custom audience and utilize it here.
      </Text>

      {selectedAdAccountWithTermsOfServiceDisabled && (
        <TermsOfServiceError {...selectedAdAccountWithTermsOfServiceDisabled} />
      )}

      <FormField
        label="Select Facebook ad account"
        error={(touched && error) || undefined}
        required
      >
        <Select
          value={value}
          onChange={handleSelectChange}
          name={AD_ACCOUNT_ID_FIELD}
          aria-label="Select Facebook ad account"
          placeholder="Choose a Facebook ad account"
          css={{ maxWidth: '520px' }}
          onBlur={onBlur}
        >
          {adAccounts.map((adAccount) => (
            <Select.Item key={adAccount.id} value={adAccount.id}>
              {adAccount.name}
            </Select.Item>
          ))}
        </Select>
      </FormField>
    </Box>
  );
};

export default AdAccountSelector;
