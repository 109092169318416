import React from 'react';

import aftershipLogo from '../assets/vendor_logos/aftership.png';
import { SettingsStandard } from '../components/SettingsStandard';
import { AftershipSettingsForm } from '../components/SettingsStandard/AftershipSettingsForm';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { AftershipConnectForm } from '../components/SetupOneStepConnect/AftershipConnectForm';
import { VendorCategory, Vendor, VendorKey } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const aftership: Vendor = {
  logo: aftershipLogo,
  category: VendorCategory.SHIPPING_RETURNS,
  whatYoullNeedItems: ['An AfterShip Account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Users can configure Attentive journeys to communicate in real-time to customers where their order is in the shipping lifecycle. Use the {trackingLink} property to give customers a reference point and the {name} property to personalize messages based on AfterShip information.',
    },
  ],
  companyWebsite: 'https://aftership.com/',
  salesEmail: 'sales@aftership.com',
  infoPagesEnabled: true,
  learnSubtext:
    'Keep customers up-to-date on the status of their orders with automated SMS messages',
  jointValueProp:
    'Deliver a great post-purchase experience when you use Attentive with AfterShip. Keep your customers up-to-date on the status of their orders with automated SMS messages delivered to the same phone number they receive marketing messages. When you integrate with AfterShip, you can keep customers updated on their orders using eight different notification triggers - Info received, In-transit, Available for pickup, Out for delivery, Delivered, Delivery attempt Failure, Exception, and Expired. You can set up notification triggers directly within Attentive Journeys and customize messages.',
  helpLinks: [
    {
      href: 'https://docs.attentive.com/pages/developer-guides/third-party-integrations/shipment-tracking-platforms/aftership/',
      label: 'Aftership integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.AFTERSHIP}
        onComplete={onComplete}
        form={({ onComplete: onFormComplete }) => (
          <AftershipConnectForm onComplete={onFormComplete} />
        )}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.AFTERSHIP}
        onComplete={onComplete}
        form={({ onFormComplete }) => <AftershipSettingsForm onComplete={onFormComplete} />}
      />
    );
  },
};
