export const listPath = () => `/integrations`;
export const learnPath = (externalVendorApiId: string | number) =>
  `/integrations/${externalVendorApiId}/learn`;
export const newPath = (externalVendorApiId: string | number) =>
  `/integrations/${externalVendorApiId}/new`;
export const managePath = (companyVendorIntegrationId: string | number) =>
  `/integrations/${companyVendorIntegrationId}/manage`;
export const manageTabPath = (companyVendorIntegrationId: string | number, tab: string) =>
  `/integrations/${companyVendorIntegrationId}/manage/${tab}`;
export const customerDataPath = (companyVendorIntegrationId: string | number) =>
  `/integrations/manage/${companyVendorIntegrationId}/customer-data`;
export const marketplaceAppPath = (marketplaceAppId: string | number, tab: string) =>
  `/integrations/marketplace/${marketplaceAppId}/listing/${tab}`;
export const marketplaceAppPreviewPath = (marketplaceAppId: string | number) =>
  `/integrations/marketplace/${marketplaceAppId}/preview`;
export const appEditPath = (appId: string | number, tab: string) =>
  `/integrations/app/manage/${appId}/tab/${tab}`;
