import pick from 'lodash/pick';
import React, { FC } from 'react';

import { IntegrationField } from '..';
import { IntegrationFieldType, useUpdateIntegrationSettingsMutation } from '../..';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFeature } from '../IntegrationFeature';
import { AudienceField } from '../Mailchimp';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

interface Props {
  onComplete: () => void;
  superUserEnabled: boolean;
}

export const MailchimpSettingsForm: FC<Props> = ({ onComplete, superUserEnabled }) => {
  const { integrationDetailValues } = useIntegrationContext();

  const validationConfig = {
    fields: {
      apiKey: { required: true },
      listId: { required: true },
      tags: { required: false },
    },
    features: {
      FEATURE_EMAIL_OPT_OUT_SYNC: {
        optOutListId: { required: true },
      },
    },
  };

  const initialValues = pick(integrationDetailValues || {}, [
    'apiKey',
    'listId',
    'tags',
    'POSTBACK_EMAILS',
    'FEATURE_EMAIL_OPT_OUT_SYNC',
  ]);

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useUpdateIntegrationSettingsMutation}
      onComplete={onComplete}
    >
      <IntegrationField
        name="apiKey"
        type={IntegrationFieldType.PASSWORD}
        label="API Key"
        placeholder="Enter API Key"
        required={true}
        disabled={true}
        css={{ width: '100%' }}
      />

      <AudienceField label="Audience" />

      <IntegrationField
        name="tags"
        type={IntegrationFieldType.TEXT}
        label="Tags"
        required={false}
        css={{ width: '100%' }}
      />

      <IntegrationFeature
        type="POSTBACK_EMAILS"
        label="Email Forwarding"
        subtext="Forward any emails collected by Attentive to MailChimp."
        css={{ width: '100%' }}
      />

      {superUserEnabled && (
        <IntegrationFeature
          type="FEATURE_EMAIL_OPT_OUT_SYNC"
          label="Email Opt Out Synchronization"
          subtext="Email Opt Out Synchronization"
          css={{ width: '100%' }}
          fields={[
            IntegrationField.Config({
              name: 'optOutListId',
              label: 'Opt Out List Id',
              type: IntegrationFieldType.TEXT,
              subtext: 'Enter the list id that opt outs should be synced with.',
              required: true,
            }),
          ]}
        />
      )}
      <SubmitButton text="Save changes" />
    </IntegrationForm>
  );
};
