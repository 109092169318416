import { ATTENTIVE_FEATURES } from '../constants';
import { ATTENTIVE_FEATURE_TYPES, IntegrationDetailsResponse } from '../types';

export const getDisconnectText = (applicationName: string, features: ATTENTIVE_FEATURE_TYPES[]) => {
  if (features.length === 0) {
    return `Are you sure you want to disconnect ${applicationName}?`;
  }

  const featureNames = features.map((f) => ATTENTIVE_FEATURES[f].displayName);

  let featureStr = '';
  if (featureNames.length === 1) {
    featureStr = featureNames[0];
  } else if (featureNames.length === 2) {
    featureStr = featureNames.join(' and ');
  } else if (featureNames.length > 2) {
    featureStr = `${featureNames.slice(0, -1).join(', ')}, and ${
      featureNames[featureNames.length - 1]
    }`;
  }

  return `Are you sure that you want to disconnect ${applicationName}? If you disconnect this application, your ${applicationName} ${featureStr} will stop working.`;
};

export const isCXIntegration = (integrationDetails: IntegrationDetailsResponse): boolean => {
  return integrationDetails.features.some((feature) => {
    return (
      feature.externalVendorFeatureType === 'FORWARD_MESSAGE_CUSTOMER_SERVICE' && feature.enabled
    );
  });
};
