import React from 'react';

import brazeLogo from '../assets/vendor_logos/braze.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

export const braze: Vendor = {
  logo: brazeLogo,
  category: VendorCategory.EMAIL_SERVICE_PROVIDER,
  errorReportingEnabled: true,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.BRAZE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'region',
            type: IntegrationFieldType.TEXT,
            label: 'Region (defaults to 3)',
            required: false,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.BRAZE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'apiKey',
            type: IntegrationFieldType.PASSWORD,
            label: 'API Key',
            required: true,
          }),
          IntegrationField.Config({
            name: 'region',
            type: IntegrationFieldType.TEXT,
            label: 'Region (defaults to 3)',
            required: false,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward any emails collected by Attentive to Braze',
          }),
        ]}
      />
    );
  },
};
