import { useFormikContext } from 'formik';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Link, Text } from '@attentive/picnic';

import { IntegrationFormValues, IntegrationFormFeatureValues } from '../../types';
import { customerDataPath } from '../../utils/routes';
import { IntegrationFeature, IntegrationFeatureProps } from '../IntegrationFeature';

type Props = IntegrationFeatureProps & {
  companyVendorIntegrationId?: number;
  children?: never;
};

export const BridgeIdentifierFeatureToggle: React.FC<Props> = ({
  companyVendorIntegrationId,
  ...featureProps
}) => {
  const { initialValues } = useFormikContext();
  const previouslyEnabled = (
    (initialValues as IntegrationFormValues).BRIDGE_IDENTIFIERS as IntegrationFormFeatureValues
  ).enabled;

  return (
    <IntegrationFeature {...featureProps}>
      {companyVendorIntegrationId && (
        <Box>
          {previouslyEnabled ? (
            <Link
              as={RouterLink}
              to={customerDataPath(companyVendorIntegrationId)}
              css={{ fontSize: '$fontSize2', fontWeight: 'bold' }}
            >
              Edit segmentation
            </Link>
          ) : (
            <Text color="subdued" variant="caption">
              Enable this option to edit segmentation
            </Text>
          )}
        </Box>
      )}
    </IntegrationFeature>
  );
};
