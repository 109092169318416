import React from 'react';

import loyaltylionLogo from '../assets/vendor_logos/loyaltylion.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

export const vendorLoyaltylion: Vendor = {
  logo: loyaltylionLogo,
  category: VendorCategory.LOYALTY,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_LOYALTYLION}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'token',
            type: IntegrationFieldType.PASSWORD,
            label: 'Token',
            required: true,
          }),
          IntegrationField.Config({
            name: 'secret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'loyaltyName',
            type: IntegrationFieldType.TEXT,
            label: 'Loyalty Name',
            required: true,
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_LOYALTYLION}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'token',
            type: IntegrationFieldType.PASSWORD,
            label: 'Token',
            required: true,
          }),
          IntegrationField.Config({
            name: 'secret',
            type: IntegrationFieldType.PASSWORD,
            label: 'Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'loyaltyName',
            type: IntegrationFieldType.TEXT,
            label: 'Loyalty Name',
            required: true,
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'POSTBACK_EMAILS',
            label: 'Forward emails',
            subtext: 'Forward emails to Loyalty Lion',
          }),
        ]}
      />
    );
  },
};
