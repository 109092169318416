import React, { FC } from 'react';

import { useUpdateIntegrationSettingsMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFormFeatureValues } from '../../types';
import { buildValidationConfig } from '../../utils';
import { IntegrationFeatureConfig } from '../IntegrationFeature';
import { IntegrationFieldConfig } from '../IntegrationField';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';

interface Props {
  fields: IntegrationFieldConfig[];
  features?: IntegrationFeatureConfig[];
  onComplete: () => void;
  superUserEnabled?: boolean;
}

export const StandardSettingsForm: FC<Props> = ({
  fields,
  features = [],
  onComplete,
  superUserEnabled,
}) => {
  const { integrationDetailValues } = useIntegrationContext();

  // we don't want to just blindly plug integrationDetailValues into the form's initialValues
  // prop. Instead we want to use the configured fields and features to define the structure
  // of the initialValues and set the values using the data in integrationDetailValues.
  // This is because integrationDetailValues may have extra data that's not part of the form
  // and we want to essentially ignore it.
  const filterFeatureForSuperUser = features.filter(({ componentProps: { visibility } }) => {
    return (visibility === 'SuperUser' && superUserEnabled) || visibility !== 'SuperUser';
  });
  const initialValues = Object.fromEntries([
    ...fields.map((field) => [
      field.componentProps.name,
      integrationDetailValues?.[field.componentProps.name] || '',
    ]),
    ...filterFeatureForSuperUser.map((feature) => {
      const existingFeature = integrationDetailValues?.[
        feature.componentProps.type
      ] as IntegrationFormFeatureValues;
      return [
        feature.componentProps.type,
        {
          enabled: existingFeature?.enabled || false,
          ...Object.fromEntries(
            (feature.componentProps.fields || []).map((field) => [
              field.componentProps.name,
              existingFeature?.[field.componentProps.name] || '',
            ])
          ),
        },
      ];
    }),
  ]);

  const validationConfig = buildValidationConfig({ fields, features: filterFeatureForSuperUser });

  return (
    <IntegrationForm
      loadingText="Saving"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useUpdateIntegrationSettingsMutation}
      onComplete={onComplete}
    >
      {fields.map((field) => field())}
      {filterFeatureForSuperUser.map((feature) => feature())}
      <SubmitButton text="Save changes" />
    </IntegrationForm>
  );
};
