import DOMPurify from 'dompurify';
import React, { FC } from 'react';

import { Box, Text } from '@attentive/picnic';

interface Props {
  content: string | undefined;
  css?: object;
}

export const HTMLContent: FC<Props> = ({ content, css }) => {
  const cleanContent = (description: string | undefined) => {
    if (!description) {
      return '';
    }
    // We need to add an exception for "target" so that we can link to the Shopify help center
    return DOMPurify.sanitize(description, { ADD_ATTR: ['target'] });
  };

  return (
    <Box css={{ a: { textDecoration: 'underline' }, 'a:hover': { color: '$textHover' } }}>
      <Text css={{ css }} dangerouslySetInnerHTML={{ __html: cleanContent(content) }} />
    </Box>
  );
};
