import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';

import { IntegrationFeature, IntegrationFeatureProps } from '../IntegrationFeature';

export const BridgeIdentifierSubfeature: React.FC<IntegrationFeatureProps> = (props) => {
  const enabledKey = `${props.type}.enabled`;
  const { setFieldValue, getFieldProps } = useFormikContext();
  const { value: areBridgeIdentifiersEnabled } = getFieldProps('BRIDGE_IDENTIFIERS.enabled');

  useEffect(() => {
    if (!areBridgeIdentifiersEnabled) {
      setFieldValue(enabledKey, false);
    }
  }, [setFieldValue, areBridgeIdentifiersEnabled, enabledKey]);

  const onSubfeatureChange = (val: boolean | 'indeterminate') => {
    if (val === true) {
      setFieldValue('BRIDGE_IDENTIFIERS.enabled', true);
    }
  };

  return <IntegrationFeature {...props} onChange={onSubfeatureChange} />;
};
