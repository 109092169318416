import React from 'react';

import magentoLogo from '../assets/vendor_logos/magento.png';
import { SettingsStandard } from '../components';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { MagentoRedirectForm } from '../components/SetupOneStepConnect/MagentoRedirectForm';
import { VendorCategory, Vendor, VendorKey } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const vendorMagento: Vendor = {
  logo: magentoLogo,
  category: VendorCategory.ECOMMERCE,
  whatYoullNeedItems: [
    'a Magento.com account',
    'an administrator account on your Magento Open Source or Adobe Commerce instance',
  ],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.JOURNEYS,
      description:
        'Send high-performing journeys, including Back in Stock, Low Inventory, and Price Drop. Branch journeys based on product attributes or inventory – for example only sending a message if the product is in stock',
    },
    {
      feature: ATTENTIVE_FEATURES.SEGMENTS,
      description:
        'Segment customers based on past purchasing and browsing activity using product data such as name, category, tag, price, and other attributes.',
    },
  ],
  companyWebsite: 'https://business.adobe.com/products/magento/magento-commerce.html',
  infoPagesEnabled: true,
  learnSubtext:
    'Keep customers up-to-date on the status of their orders with automated SMS messages',
  jointValueProp:
    'Our Magento Open Source & Adobe Commerce integration allows you to sync your entire product catalog to Attentive so that you can send high-performing journeys such as back in stock, low inventory, and price drop. It also lets you segment your customers and branch journeys based on your customers’ purchasing and browsing activity.',
  setupComponent(_onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_MAGENTO}
        onComplete={_onComplete}
        form={() => (
          <MagentoRedirectForm instructions="Install the Attentive extension to your Magento / Adobe Commerce instance to connect the integration." />
        )}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_MAGENTO}
        onComplete={onComplete}
        fields={[]}
        features={[
          IntegrationFeature.Config({
            type: 'SYNC_PRODUCTS',
            label: 'Sync Product Data',
            subtext:
              'Create targeted segments based on your Magento Open Source or Adobe Commerce instance product data.',
          }),
        ]}
      />
    );
  },
};
