import pick from 'lodash/pick';
import React, { FC } from 'react';

import { Box } from '@attentive/picnic';

import { useUpdateIntegrationSettingsMutation } from '../../api';
import { useIntegrationContext } from '../../hooks/useIntegrationContext';
import { IntegrationFormFeatureValues, IntegrationFieldType } from '../../types';
import { IntegrationField } from '../IntegrationField';
import { IntegrationForm } from '../SetupConnectAndConfigure/IntegrationForm';
import { SubmitButton } from '../SetupConnectAndConfigure/SubmitButton';
import { WebhookCallbackUrl } from '../WebhookCallbackUrl';

interface Props {
  onComplete: () => void;
}

export const YotpoSettingsForm: FC<Props> = ({ onComplete }) => {
  const { integrationDetailValues } = useIntegrationContext();

  const initialValues = pick(integrationDetailValues || {}, ['apiKey', 'guid', 'actionName']) as {
    [type: string]: IntegrationFormFeatureValues;
  };

  const validationConfig = {
    fields: {
      apiKey: { required: true },
      guid: { required: true },
      actionName: { required: true },
    },
  };

  return (
    <IntegrationForm
      loadingText="Configuring"
      initialValues={initialValues}
      validationConfig={validationConfig}
      mutation={useUpdateIntegrationSettingsMutation}
      onComplete={onComplete}
    >
      <Box css={{ width: '100%' }}>
        <WebhookCallbackUrl
          path="?vendor=VENDOR_YOTPO"
          subtext="Paste this into the Webhook URL field on the Yotpo Settings page"
          useCentralizedInboundWebhookUrl={true}
        />
      </Box>

      <IntegrationField
        name="apiKey"
        type={IntegrationFieldType.PASSWORD}
        label="API Key"
        required={true}
        css={{ width: '100%' }}
      />
      <IntegrationField
        name="guid"
        type={IntegrationFieldType.TEXT}
        label="GUID"
        required={true}
        css={{ width: '100%' }}
      />
      <IntegrationField
        name="actionName"
        type={IntegrationFieldType.TEXT}
        label="Action Name"
        required={true}
        css={{ width: '100%' }}
      />

      <SubmitButton text="Save and continue" />
    </IntegrationForm>
  );
};
