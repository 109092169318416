import React from 'react';

import segmentAnalyticsLogo from '../assets/vendor_logos/segmentanalyticslogo.png';
import { IntegrationFeature } from '../components/IntegrationFeature';
import { IntegrationField } from '../components/IntegrationField';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import {
  VendorCategory,
  Vendor,
  VendorKey,
  IntegrationFieldType,
  IntegrationVisibilityType,
} from '../types';

export const vendorWorldMarket: Vendor = {
  logo: segmentAnalyticsLogo,
  category: VendorCategory.CUSTOMER_DATA_PLATFORM,
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_WORLD_MARKET}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.TEXT,
            label: 'Client Secret',
            required: true,
          }),
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.TEXT,
            label: 'Client Id',
            required: true,
          }),
          IntegrationField.Config({
            name: 'scope',
            type: IntegrationFieldType.TEXT,
            label: 'Scope',
            required: true,
          }),
          IntegrationField.Config({
            name: 'wmrIdPropertyId',
            type: IntegrationFieldType.TEXT,
            label: 'WMR ID PropertyId',
            required: false,
          }),
          IntegrationField.Config({
            name: 'wmrCustomerIdPropertyId',
            type: IntegrationFieldType.TEXT,
            label: 'WMR Customer Property Id',
            required: false,
          }),
          IntegrationField.Config({
            name: 'environment',
            type: IntegrationFieldType.SELECT,
            label: 'environment',
            subtext: 'Select which World Market Environment to send data',
            required: false,
            options: [
              {
                name: 'development',
                value: 'development',
              },
              {
                name: 'production',
                value: 'production',
              },
            ],
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete, superUserEnabled) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_WORLD_MARKET}
        onComplete={onComplete}
        superUserEnabled={superUserEnabled}
        fields={[
          IntegrationField.Config({
            name: 'clientSecret',
            type: IntegrationFieldType.TEXT,
            label: 'Client Secret',
            required: false,
          }),
          IntegrationField.Config({
            name: 'clientId',
            type: IntegrationFieldType.TEXT,
            label: 'Client Id',
            required: true,
          }),
          IntegrationField.Config({
            name: 'scope',
            type: IntegrationFieldType.TEXT,
            label: 'Scope',
            required: true,
          }),
          IntegrationField.Config({
            name: 'wmrIdPropertyId',
            type: IntegrationFieldType.TEXT,
            label: 'WMR ID PropertyId',
            required: false,
          }),
          IntegrationField.Config({
            name: 'wmrCustomerIdPropertyId',
            type: IntegrationFieldType.TEXT,
            label: 'WMR Customer Property Id',
            required: false,
          }),
          IntegrationField.Config({
            name: 'environment',
            type: IntegrationFieldType.SELECT,
            label: 'environment',
            subtext: 'Select which World Market Environment to send data',
            required: false,
            options: [
              {
                name: 'development',
                value: 'development',
              },
              {
                name: 'production',
                value: 'production',
              },
            ],
          }),
        ]}
        features={[
          IntegrationFeature.Config({
            type: 'THIRD_PARTY_PROCESSOR_FORWARDER',
            label: 'Forward Subscriber opt ins and outs to World Market.',
            subtext: 'Turn on to forward Opt Ins and Opt Outs to World Market.',
            visibility: IntegrationVisibilityType.SUPER_USER,
          }),
        ]}
      />
    );
  },
};
